import React, { useEffect } from 'react';
import { Button, FormControl, Flex, Text, Grid, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AssignPermissionFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxController from 'components/Form/CheckboxController';
import isEmpty from 'lodash/isEmpty';
import { API_ROUTES, PermissionOption } from 'utils/constant';
import { axiosPost } from 'utils/api';

const AssignPermission = ({ userDetail, onClose, refetchData }) => {
  const toast = useToast();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(AssignPermissionFormValidate),
    defaultValues: {},
  });

  const onSubmit = async values => {
    const payloadPermission = Object.keys(values).filter(key => !!values[key]);
    try {
      let response;
      response = await axiosPost(API_ROUTES.AssignPermission, {
        permissions: payloadPermission,
        userId: userDetail?.id
      });
      if (response.data.code === 0) {
        toast({
          title: 'Cập nhật quyền truy cập thành công.',
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        refetchData?.();
        onClose?.();
      } else {
        toast({
          title: MessageApi?.[response.data.msg] || 'Cập nhật quyền truy cập thất bại.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Cập nhật quyền truy cập thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(userDetail)) {
        userDetail?.permissions?.forEach(item => {
        setValue(item, true);
      });
    }
  }, [userDetail]);
  return (
    <FormControl>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {PermissionOption?.map((item, index) => (
          <Flex justifyContent="start" alignItems={'start'} gap={2} key={index}>
            <CheckboxController label="" name={item?.value} control={control} isRequired styleContainer={{ width: 'fit-content' }} />
            <Text fontSize={'14px'}>{item?.label}</Text>
          </Flex>
        ))}
      </Grid>
      <Flex justifyContent={'end'} gap={5}>
        <Button onClick={onClose}>Đóng</Button>
        <Button colorScheme="blue" onClick={handleSubmit(onSubmit)}>
          Cập nhật
        </Button>
      </Flex>
    </FormControl>
  );
};

export default AssignPermission;
