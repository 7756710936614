import { Box, Button, Flex, FormControl, FormLabel, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { TablePagination } from '@trendmicro/react-paginations';
import moment from 'moment';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import AddWebSiteCategory from './components/AddWebSiteCategory';
import Pagination from 'components/Pagination/Pagination';
import { isEmpty } from 'lodash';
import InputSearch from 'components/InputSearch/InputSearch';
import DatePicker from 'components/DatePicker/DatePicker';

function Notification() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState(initialFilter);
  const [valueSearch, setValueSearch] = useState('');

  const [{ data, loading }, refetch] = useAxios({
    url: API_ROUTES.ListNoti,
    params: filter,
  });

  const handleSearch = () => {
    refetch({ params: { ...filter, searchKeyword: valueSearch, pageIndex: 0 } });
  };

  const onChangeFilter = event => {
    event.persist();
    setValueSearch(event?.target?.value);
  };

  const resetSearchKeywords = () => {
    setFilter(initialFilter);
    setValueSearch('');
    refetch();
  };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Flex alignItems="end">
              <FormControl rowGap="16px" mt="2px" display="flex" flexWrap="wrap" alignItems="end" gap={4}>
                <Flex flexDirection="row">
                  <Flex flexDirection="column" justifyContent={'flex-end'}>
                    <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                      Tìm kiếm theo tên nhà xuất bản, tên nhà quảng cáo
                    </Text>
                    <InputSearch
                      width="300px"
                      placeholder="Tìm kiếm theo tên nhà xuất bản, tên nhà quảng cáo"
                      value={valueSearch || ''}
                      name="searchKeyword"
                      mr="0"
                      onChange={onChangeFilter}
                      onClearSearch={() => {
                        setFilter({
                          ...filter,
                          searchKeyword: '',
                        });
                        setValueSearch('');
                      }}
                    />
                  </Flex>

                  <Flex flexWrap="wrap" alignItems={'end'} marginTop={4} gap={'20px'}>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '200px' }} maxW="200px">
                      <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                        Từ ngày
                      </FormLabel>
                      <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
                    </FormControl>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '200px' }} maxW="200px">
                      <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                        Đến ngày
                      </FormLabel>
                      <DatePicker
                        selectedDate={filter.endDate}
                        minDate={filter.startDate}
                        onChange={date => onChangeDate('endDate')(date)}
                      />
                    </FormControl>
                  </Flex>
                </Flex>

                <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
                  Đặt lại
                </Button>
                <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={handleSearch}>
                  Lọc
                </Button>
              </FormControl>
            </Flex>
          </CardHeader>
          <CardBody w="100%" overflowX="auto">
            {loading ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Loading />
              </Flex>
            ) : (
              <>
                <Box overflowX={isEmpty(data?.data) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Tên
                        </Th>
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Nội dung
                        </Th>
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Nhà quảng cáo
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Nhà xuất bản
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Ngày tạo
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Ngày cập nhật
                        </Th>
                        {/* <Th borderColor={borderColor} color="gray.400">
                          Thao tác
                        </Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.map(row => {
                        return (
                          <Row
                            row={row}
                            id={row._id}
                            title={row.title}
                            content={row.content}
                            is_read={row.is_read}
                            publisher={row.publisher}
                            advertiser={row.advertiser}
                            day={moment(row.createdAt).format('YYYY-MM-DD')}
                            updatedAt={moment(row.updatedAt).format('YYYY-MM-DD')}
                            refetch={refetch}
                          />
                        );
                      })}
                      {isEmpty(data?.data) && !loading && (
                        <Tr>
                          <Td colSpan="8">
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                              Không có dữ liệu
                            </Box>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Box>
                {!isEmpty(data?.data) && (
                  <Flex justifyContent="flex-end">
                    <Pagination
                      type="full"
                      page={data?.pagination?.page}
                      pageLength={data?.pagination?.pageSize}
                      totalRecords={data?.pagination?.count}
                      onPageChange={(page, pageLength) => {
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      }}
                      prevPageRenderer={() => <i className="fa fa-angle-left" />}
                      nextPageRenderer={() => <i className="fa fa-angle-right" />}
                    />
                  </Flex>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isOpen && <AddWebSiteCategory refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </>
  );
}

export default Notification;
