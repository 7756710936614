import capitalize from 'lodash/capitalize';

export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
  SUPERVISOR: 'supervisor',
  GUIDE: 'guide',
};

export const CountryCodeDefault = {
  GB: 'GB',
};

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

export const STATUS_CODE = {
  Success: 0,
};

export const STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECT: 'reject',
};

export const LOWERTEXT_REGEX = /[a-z]/;
export const UPPERTEXT_REGEX = /[A-Z]/;
export const NUMBER_REGEX = /[0-9]/;
export const SPECIAL_REGEX = /[!@#$%^&*(),.?":{}|<>]/;
export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[A-Za-z\d]{8,}$/;
export const PASSWORD_REGEX_V1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;

export const MAX_PHONE_NUMBER_DIGIT = 10;

export const defaultPassword = 'Affiliate2024@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const WAEBOX_API = process.env.REACT_APP_WAEBOX_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

export const API_ROUTES = {
  ListTrasactions:`${ROOT_API}/api/v1/transactions`,
  ListWithDraw:`${ROOT_API}/api/v1/transactions/request-withdraw`,
  Banks: `${ROOT_API}/api/v1/transactions/banks`,
  CreateTransaction: `${ROOT_API}/api/v1/transactions/create`,
  UpdateAdsWebsite: `${ROOT_API}/api/v1/websites/add-ad-unit`,
  UpdateStatusAds: `${ROOT_API}/api/v1/websites/ad-unit/approve`,
  AdsUnit: `${ROOT_API}/api/v1/ad-units`,
  ListWebSite: `${ROOT_API}/api/v1/websites`,
  UpdateStatusWebSite: `${ROOT_API}/api/v1/websites/approve`,
  DeleteWebsite: `${ROOT_API}/api/v1/websites/delete-website`,
  ListDirectLink: `${ROOT_API}/api/v1/direct-links`,
  UpdateStatusDirect: `${ROOT_API}/api/v1/direct-links/change-status`,
  DeleteCategory: `${ROOT_API}/api/v1/categories/delete`,
  UpdateCategory: `${ROOT_API}/api/v1/categories/update`,
  CreateCategory: `${ROOT_API}/api/v1/categories/create`,
  ListWebsiteCate: `${ROOT_API}/api/v1/categories`,
  Users: `${ROOT_API}/api/v1/user/get-all`,
  LoginPath: `${ROOT_API}/api/v1/user/login`,
  RegisterUser: `${ROOT_API}/api/v1/user/create`,
  UpdateUser: `${ROOT_API}/api/v1/user/update`,
  ChangePassUser: `${ROOT_API}/api/v1/user/change-pw`,
  ResetPassUser: `${ROOT_API}/api/v1/user/reset-password`,
  ExportTemplateUser: `${ROOT_API}/api/v1/user/export-template`,
  ImportUser: `${ROOT_API}/api/v1/user/import-manager`,
  ExportUser: `${ROOT_API}/api/v1/user/export`,
  DeleteUser: `${ROOT_API}/api/v1/user/delete`,
  BlockUser: `${ROOT_API}/api/v1/user/block`,
  CreateCustomer: `${ROOT_API}/api/v1/customers`,
  UpdateCustomer: `${ROOT_API}/api/v1/customers/change-information`,
  ExportTemplateCustomer: `${ROOT_API}/api/v1/customers/export-template`,
  ImportCustomer: `${ROOT_API}/api/v1/customers/import`,
  ExportCustomer: `${ROOT_API}/api/v1/customers/export`,
  DeleteCustomer: `${ROOT_API}/api/v1/customers/delete`,
  CustomerByChannel: channelId => `${ROOT_API}/api/v1/channel/${channelId}/customers`,
  SendMail: `${ROOT_API}/api/v1/send-mail`,
  Channel: `${ROOT_API}/api/v1/channels`,
  ChannelActive: `${ROOT_API}/api/v1/channels/active`,
  UpdateChannel: `${ROOT_API}/api/v1/channels/update`,
  DeleteChannel: `${ROOT_API}/api/v1/channels/delete`,
  ChangeStatusChannel: `${ROOT_API}/api/v1/channels/change-status`,
  UpdateCampaign: `${ROOT_API}/api/v1/campaigns/update`,
  DeleteCampaign: `${ROOT_API}/api/v1/campaigns/delete`,
  OverviewCampaign: `${ROOT_API}/api/v1/campaigns/overview`,
  TrackingSendEmails: `${WAEBOX_API}/api/v1/trackings`,
  CampaignDetail: campaignId => `${ROOT_API}/api/v1/campaigns/${campaignId}`,
  SMTPServer: `${ROOT_API}/api/v1/smtp-servers`,
  UpdateSMTPServer: `${ROOT_API}/api/v1/smtp-servers/update`,
  DeleteSMTPServer: `${ROOT_API}/api/v1/smtp-servers/delete`,
  ChangeStatusSMTPServer: `${ROOT_API}/api/v1/smtp-servers/change-status`,
  SMTPServerBackupEmail: `${ROOT_API}/api/v1/smtp-servers/backup-email`,
  SMTPServerUpdateBackupEmail: `${ROOT_API}/api/v1/smtp-servers/update-backup-email`,
  SMTPServerDeleteBackupEmail: `${ROOT_API}/api/v1/smtp-servers/delete-backup-email`,
  ExportTemplateSMTPServer: `${ROOT_API}/api/v1/smtp-servers/export-template`,
  ImportSMTPServer: `${ROOT_API}/api/v1/smtp-servers/import`,
  SendMailChannel: `${ROOT_API}/api/v1/send-mail-channel`,
  SendMailCampaign: `${ROOT_API}/api/v1/send-mail-campaign`,
  SendMailCustomer: `${ROOT_API}/api/v1/send-mail-customers`,
  EmailDaily: `${ROOT_API}/api/v1/email/email-daily`,
  EmailOpenedDaily: `${ROOT_API}/api/v1/email/email-opened-daily`,
  EmailSent: `${ROOT_API}/api/v1/email/sent-emails`,
  WaeboxEmailDaily: `${WAEBOX_API}/api/v1/email/email-daily`,
  WaeboxEmailOpenedDaily: `${WAEBOX_API}/api/v1/email/email-opened-daily`,
  WaeboxEmailSent: `${WAEBOX_API}/api/v1/email/sent-emails`,
  EmailTemplate: `${ROOT_API}/api/v1/templates`,
  UpdateEmailTemplate: `${ROOT_API}/api/v1/templates/update`,
  DeleteEmailTemplate: `${ROOT_API}/api/v1/templates/delete`,
  UploadImage: `${ROOT_API}/api/v1/posts/upload-image`,
  EmailCampaignFail: `${ROOT_API}/api/v1/campaign-mails`,
  ResendEmailCampaign: `${ROOT_API}/api/v1/re-send-mail-campaign`,
  GroupPermission: `${ROOT_API}/api/v1/group`,
  UpdateGroupPermission: `${ROOT_API}/api/v1/group/update`,
  DeleteGroupPermission: `${ROOT_API}/api/v1/group/delete`,
  GroupPermissionList: `${ROOT_API}/api/v1/group-list`,
  GroupForUser: `${ROOT_API}/api/v1/group-all`,
  Advertiser: `${ROOT_API}/api/v1/advertiser/get-all`,
  AssignManagerForAdvertiser: `${ROOT_API}/api/v1/advertiser/assign-user`,
  BlockAdvertiser: `${ROOT_API}/api/v1/advertiser/block`,
  Publisher: `${ROOT_API}/api/v1/publisher/get-all`,
  BlockPublisher: `${ROOT_API}/api/v1/publisher/block`,
  Campaign: `${ROOT_API}/api/v1/campaign-all`,
  UpdateCampaign: `${ROOT_API}/api/v1/campaign/approved`,
  GetWebAdsForCampaign: `${ROOT_API}/api/v1/get-list-websiteAds`,
  AssignWebAdsForCampaign: `${ROOT_API}/api/v1/assign-campaign`,
  AssignCampaignForWebAds: `${ROOT_API}/api/v1/assign-website-campaign`,
  GetCampaignForWebsite: id => `${ROOT_API}/api/v1/get-list-campaign/${id}`,
  Posts: `${ROOT_API}/api/v1/posts`,
  ChangeStatusPost: `${ROOT_API}/api/v1/posts/change-status`,
  DeletePost: `${ROOT_API}/api/v1/posts/delete`,
  UpdatePost: `${ROOT_API}/api/v1/posts/update`,
  Sites: `${ROOT_API}/api/v1/sites`,
  Ticket: `${ROOT_API}/api/v1/ticket`,
  TicketCategory: `${ROOT_API}/api/v1/ticket-categories`,
  TicketForManager: `${ROOT_API}/api/v1/ticket/get-all-for-user`,
  AssignTicket: `${ROOT_API}/api/v1/assign-ticket`,
  ChangeStatusTicket: id => `${ROOT_API}/api/v1/ticket/change-status/${id}`,
  ChangeStatusWithDraw:  `${ROOT_API}/api/v1/withdraw/change-status`,
  CloseTicket: id => `${ROOT_API}/api/v1/ticket/close/${id}`,
  SettingCommission: `${ROOT_API}/api/v1/settings`,
  Statistics: `${ROOT_API}/api/v1/statistics`,
  Countries: `${ROOT_API}/api/v1/countries`,
  CPMStatistics: `${ROOT_API}/api/v1/cpm-statistics`,
  CPCStatistics: `${ROOT_API}/api/v1/cpc-statistics`,
  ChangePassword: id => `${ROOT_API}/api/v1/publisher/change-password/${id}`,
  getListReferralById: (id, type) => `${ROOT_API}/api/v1/${type}/list-referral/${id}`,
  getCampaignGroups: `${ROOT_API}/api/v1/admin/campaign-groups`,
  createTicketCategory: `${ROOT_API}/api/v1/ticket-categories/create`,
  getTicketCategories: `${ROOT_API}/api/v1/ticket-categories`,
  updateTicketCategory: `${ROOT_API}/api/v1/ticket-categories/update`,
  deleteTicketCategory: `${ROOT_API}/api/v1/ticket-categories/delete`,
  deleteCampaignGroup: `${ROOT_API}/api/v1/advertiser/campaign-groups/delete-campaign-group`,
  updateCampaignGroup: `${ROOT_API}/api/v1/admin/campaign-groups/update`,
  updateInforPublisher: `${ROOT_API}/api/v1/admin/change-information-publisher`,
  updateInforAdv: `${ROOT_API}/api/v1/admin/change-information-adv`,
  ListNoti: `${ROOT_API}/api/v1/notifications`,
  AssignPermission: `${ROOT_API}/api/v1/user/assign-permission`,
};

export const Gender = {
  male: 'Nam',
  female: 'Nữ',
};

export const GenderOption = [
  {
    label: Gender.male,
    value: 'male',
  },
  {
    label: Gender.female,
    value: 'female',
  },
];

export const PortSMTP = {
  FSF: '465',
  FES: '587',
};

export const BrandMailKey = {
  Vipphim: 'VIPPHIM',
  Eday: '8DAY',
  Ebbb: '888B',
  Tqnews: '2QNEWS',
  Tqsport: '2QSPORT',
  Tqlive: '2QLIVE',
};

export const BrandMailOption = [
  {
    label: BrandMailKey.Vipphim,
    value: BrandMailKey.Vipphim,
  },
  {
    label: BrandMailKey.Eday,
    value: BrandMailKey.Eday,
  },
  {
    label: BrandMailKey.Ebbb,
    value: BrandMailKey.Ebbb,
  },
  {
    label: BrandMailKey.Tqnews,
    value: BrandMailKey.Tqnews,
  },
  {
    label: BrandMailKey.Tqsport,
    value: BrandMailKey.Tqsport,
  },
  {
    label: BrandMailKey.Tqlive,
    value: BrandMailKey.Tqlive,
  },
];

export const ModalType = {
  Add: 'add',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  Assign: 'assign',
  Block: 'block',
  ChangePassword: 'changePassword',
  RegisterTicket: 'registerTicket',
  CreateTicketCategory: 'createTicketCategory',
  UpdateTicketCategory: 'updateTicketCategory'
};

export const MailStatusKey = {
  All: 'all',
  Read: 'read',
  UnRead: 'unRead',
};

export const MailStatusOption = [
  {
    label: 'Tất cả',
    value: MailStatusKey.All,
  },
  {
    label: 'Đã đọc',
    value: MailStatusKey.Read,
  },
  {
    label: 'Chưa đọc',
    value: MailStatusKey.UnRead,
  },
];

export const FileExcelValid = ['xls', 'xlsx'];

export const RoleOption = [
  {
    label: 'Admin',
    value: Roles.ADMIN,
  },
  {
    label: 'Manager',
    value: Roles.MANAGER,
  },
  {
    label: 'Supervisor',
    value: Roles.SUPERVISOR,
  },
];

export const CreateDomain = {
  CampaignInfo: 'campaignInfo',
  MailInfo: 'mailInfo',
};

export const CreateCampaignStep = {
  FirstStep: CreateDomain.CampaignInfo,
  SecondStep: CreateDomain.MailInfo,
};

export const ClassificationKey = {
  Customers: 'customer',
  Group: 'group',
};

export const ClassificationOptions = [
  {
    label: 'Khách hàng',
    value: ClassificationKey.Customers,
  },
  {
    label: 'Nhóm khách hàng',
    value: ClassificationKey.Group,
  },
];

export const DomainEmail = {
  GiaiTri8Day: '@giaitri8day.top',
  DayAsia: '@8day.asia',
  DayWiki: '@8day.wiki',
};

export const DomainEmailOption = [
  {
    label: DomainEmail.GiaiTri8Day,
    value: DomainEmail.GiaiTri8Day,
  },
  {
    label: DomainEmail.DayAsia,
    value: DomainEmail.DayAsia,
  },
  {
    label: DomainEmail.DayWiki,
    value: DomainEmail.DayWiki,
  },
];

export const ErrorMessage = {
  THE_PASSWORD_IS_INCLUDE_UPPERCASE_NORMAL_NUMBER_SPECIAL_CHARACTER_MIN_8:
    'Mật khẩu bao gồm chữ hoa, chữ thường, chữ số, ký tự đặc biệt và tối thiểu 8 kí tự',
  THE_CONFIRM_PASSWORD_DOES_NOT_MATCH: 'Xác nhận mật khẩu không giống nhau',
};
export const PortSMTPOption = [
  {
    label: PortSMTP.FES,
    value: PortSMTP.FES,
  },
  {
    label: PortSMTP.FSF,
    value: PortSMTP.FSF,
  },
];
export const ListAdsUnit = [
  'POP_UNDER',
  'BANNER_300_250',
  'BANNER_320_50',
  'BANNER_300_600',
  'BANNER_160_600',
  'BANNER_970_90',
  'BANNER_640_320',
  'BANNER_320_100',
  'NATIVE_BANNER',
  'SOCIAL_BAR',
  'INTERSTITIAL',
];

export const ErrorForm = {
  Required: 'Trường này là bắt buộc',
  EmailInvalid: 'Email không đúng định dạng',
  MaximumPhoneLength: 'Số phone bao gồm 10 chữ số',
  MaximumUsernameLength: 'Mật khẩu từ 5 đến 30 ký tự',
  MaximumPasswordLength: 'Mật khẩu từ 8 đến 20 ký tự',
  PasswordInvalid: 'Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt',
  PasswordNotSame: 'Mật khẩu xác nhận phải giống với mật khẩu mới',
  MinMoney: amount => `Số tiền phải lớn hơn hoặc bằng ${amount}`,
  MaxMoney: amount => `Số tiền phải nhỏ hơn hoặc bằng ${amount}`,
  MaxLength: length => `Bạn được nhập tối đa ${length} ký tự`,
  Required: 'Trường này là bắt buộc',
  EmailInvalid: 'Email không đúng định dạng',
  MaximumPhoneLength: 'Số điện thoại gồm 10 chữ số',
  MaximumUsernameLength: 'Mật khẩu từ 5 đến 30 ký tự',
  MaximumPasswordLength: 'Mật khẩu từ 8 đến 20 ký tự',
  PasswordInvalid: 'Mật khẩu phải chứa chữ hoa, chữ thường, số và ký tự đặc biệt',
  PasswordNotSame: 'Mật khẩu xác nhận phải giống với mật khẩu mới',
  MinMoney: amount => `Số tiền phải lớn hơn hoặc bằng ${amount}`,
  MaxMoney: amount => `Số tiền phải nhỏ hơn hoặc bằng ${amount}`,
  MaxLength: length => `Bạn có thể nhập tối đa ${length} ký tự`,
};

export const MessageApi = {
  THE_PERMISSIONS_INVALID: 'Quyền này không hợp lệ',
  DIRECT_LINK_NOT_FOUND: 'Liên kết không tồn tại',
  CHANGE_STATUS_DIRECT_LINK_SUCCESS: 'Thay đổi trạng thái liên kết thành công',
  UPDATE_POST_DONE: 'Update Post done.',
  CREATE_POST_SUCCESS: 'Create Post Success.',
  CREATE_SITE_CONFIG_SUCCESS: 'Create Site Config Success.',
  CREATE_PAGE_SUCCESS: 'Create Page Success.',
  DELETE_PAGE_SUCCESS: 'Delete Page Success.',
  UPDATE_PAGE_SUCCESS: 'Update Page Success.',
  WRONG_USERNAME_OR_PASSWORD: 'Tên đăng nhập hoặc mật khẩu sai',
  THE_USERNAME_OR_EMAIL_EXISTED: 'Tài khoản này đã tồn tại',
  YOUR_INFORMATION_IS_WAITING_APPROVAL_OR_APPROVED: 'Thông tin của bạn đang chờ phê duyệt hoặc được phê duyệt',
  ['Wrong_Password.']: 'Mật khẩu hiện tại chưa đúng',
  THE_WEBSITE_AD_UNIT_IS_INVALID: 'Đơn vị quảng cáo web không hợp lệ',
  THE_WEBSITE_URL_IS_INVALID: 'Địa chỉ website không hợp lệ',
  WEBSITE_ALREADY_EXISTS: 'Trang web này đã tồn tại',
  DATA_EMPTY: 'Không có chiến dịch tương ứng. Sao chép đường dẫn thất bại.',
  THE_PHONE_EXISTED: 'Số điện thoại đã tồn tại',
  CAMPAIGN_GROUP_EXISTS: 'Tên nhóm chiến dịch đã tồn tại',
  CAMPAIGN_GROUP_IS_USING_CAN_NOT_DELETE: 'Không thể xóa nhóm chiến dịch đang sử dụng.',
  THE_WEBSITE_CATEGORY_IS_INVALID_OBJECT_ID: 'Id đối tượng danh mục website không hợp lệ',
  THE_WEBSITE_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id website là bắt buộc và là id đối tượng hợp lệ',
  THE_AD_UNIT_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id đơn vị quảng cáo là bắt buộc và là id đối tượng hợp lệ',
  THE_DIRECT_LINK_ID_IS_REQUIRE_AND_IS_VALID_OBJECT_ID: 'Id liên kết trực tiếp là bắt buộc và là id đối tượng hợp lệ',
  THE_STATUS_IS_INVALID: 'Trạng thái không hợp lệ',
  THE_WEBSITE_AD_UNIT_IS_INVALID: 'Đơn vị quảng cáo website không hợp lệ',
  THE_WEBSITE_AD_UNIT_IS_REQUIRE: 'Đơn vị quảng cáo website là bắt buộc',
  THE_WEBSITE_REMOVE_CAMPAIGN_WITH_IS_INVALID: 'Website xóa chiến dịch với là không hợp lệ',
  THE_WEBSITE_CATEGORY_IS_REQUIRE: 'Danh mục website là bắt buộc',
  THE_WEBSITE_URL_IS_INVALID: 'Url website không hợp lệ',
  THE_WEBSITE_URL_IS_REQUIRE: 'Url website là bắt buộc',
  THE_WEBSITE_NAME_IS_REQUIRE: 'Tên website là bắt buộc',
  THE_GROUP_IS_REQUIRED: 'Nhóm là bắt buộc',
  THE_COUNTRY_CODE_IS_REQUIRE: 'Mã quốc gia là bắt buộc',
  THE_VALUE_COUNTRY_CODE_NOT_EXISTS: 'Giá trị mã quốc gia không tồn tại',
  THE_VALUE_CONTACT_CHANNEL_NOT_EXISTS: 'Giá trị kênh liên hệ không tồn tại',
  THE_CONTACT_CHANNEL_IS_REQUIRE: 'Kênh liên hệ là bắt buộc',
  THE_CONTACT_USERNAME_IS_REQUIRE: 'Tên người dùng liên hệ là bắt buộc',
  THE_CATEGORY_IS_REQUIRE: 'Danh mục là bắt buộc',
  RATIO_IS_REQUIRE: 'Tỉ lệ là bắt buộc',
  AMOUNT_IS_REQUIRE: 'Số tiền là bắt buộc',
  THE_ADVERTISER_ID_IS_REQUIRE: 'Id quảng cáo là bắt buộc',
  THE_PUBLISHER_ID_IS_REQUIRE: 'Id nhà xuất bản là bắt buộc',
  CHANNEL_IS_REQUIRE: 'Kênh là bắt buộc',
  BANK_CODE_IS_REQUIRE: 'Mã ngân hàng là bắt buộc',
  THE_GENDER_IS_REQUIRE: 'Giới tính là bắt buộc',
  THE_GENDER_INVALID: 'Giới tính không hợp lệ',
  UPLOAD_IMAGE_ONLY: 'Chỉ được tải lên ảnh',
  THE_BIRTHDAY_IS_REQUIRE: 'Ngày sinh là bắt buộc',
  THE_TOKEN_IS_REQUIRE: 'Token là bắt buộc',
  THE_PHONE_IS_REQUIRE: 'Số điện thoại là bắt buộc',
  PHONE_NUMBER_INVALID_FORMAT: 'Định dạng số điện thoại không hợp lệ',
  THE_WALLET_CODE_IS_REQUIRE: 'Mã ví là bắt buộc',
  THE_WALLET_NETWORK_IS_REQUIRE: 'Mạng ví là bắt buộc',
  THE_BANK_NAME_IS_REQUIRE: 'Tên ngân hàng là bắt buộc',
  THE_BANK_ACCOUNT_NAME_IS_REQUIRE: 'Tên tài khoản ngân hàng là bắt buộc',
  THE_BANK_ACCOUNT_NUMBER_IS_REQUIRE: 'Số tài khoản ngân hàng là bắt buộc',
  THE_WALLET_NETWORK_IS_IN_VALID: 'Mạng ví không hợp lệ',
  THE_FULLNAME_IS_REQUIRE: 'Họ và tên là bắt buộc',
  FULLNAME_INVALID_FORMAT: 'Định dạng họ và tên không hợp lệ',
  THE_USER_ID_IS_REQUIRE: 'Id người dùng là bắt buộc',
  USERNAME_MIN_4_MAX_20: 'Tên đăng nhập từ 4 đến 20 ký tự',
  THE_USERNAME_IS_REQUIRE: 'Tên đăng nhập là bắt buộc',
  USERNAME_INVALID_FORMAT: 'Định dạng tên đăng nhập không hợp lệ',
  MISSING_FIELD_REQUIRE: 'Thiếu trường bắt buộc',
  USER_NOT_FOUND: 'Không tìm thấy người dùng',
  THE_USERNAME_OR_EMAIL_EXISTED: 'Tên đăng nhập hoặc email đã tồn tại',
  WRONG_USERNAME_OR_PASSWORD: 'Sai tên đăng nhập hoặc mật khẩu',
  THE_ROUND_ID_IS_REQUIRE: 'Id vòng đấu là bắt buộc',
  THE_PUBLISHERS_IS_REQUIRE: 'Nhà xuất bản là bắt buộc',
  THE_AWAY_ID_IS_REQUIRE: 'Id đội khách là bắt buộc',
  THE_HOME_ID_IS_REQUIRE: 'Id của đội nhà là bắt buộc',
  THE_HOME_NAME_IS_REQUIRE: 'Tên đội nhà là bắt buộc',
  THE_AWAY_NAME_IS_REQUIRE: 'Tên đội khách là bắt buộc',
  THE_LEAGUE_IS_REQUIRE: 'Giải đấu là bắt buộc',
  THE_TIME_IS_REQUIRE: 'Thời gian là bắt buộc và sau giờ hiện tại',
  THE_LEAGUE_NAME_IS_REQUIRE: 'Tên giải đấu là bắt buộc',
  THE_MATCH_ID_IS_REQUIRE: 'Id trận đấu là bắt buộc',
  THE_TIME_IS_AFTER_NOW: 'Thời gian là sau giờ hiện tại',
  CANNOT_DELETE_LIVE_OR_FINISH_MATCH: 'Không thể xoá trận đấu đang diễn ra hoặc đã kết thúc',
  MATCH_NOT_EXIST: 'Trận đấu không tồn tại',
  CANNOT_UPDATE_LIVE_MATCH: 'Không thể cập nhật trận đấu đang diễn ra',
  GOALS_IS_NUMBER: 'Bàn thắng là số',
  TITLE_IS_EXIST: 'Tiêu đề đã tồn tại',
  POST_TYPE_IS_EXIST: 'Loại bài đăng là bắt buộc',
  CATEGORY_IS_EXIST: 'Danh mục là bắt buộc',
  THUMBNAIL_IS_REQUIRE: 'Ảnh thu nhỏ là bắt buộc',
  VIDEO_HIGHLIGHT_IS_REQUIRE: 'Video highlight là bắt buộc',
  THE_POST_ID_IS_REQUIRE: 'ID bài đăng là bắt buộc',
  THE_TEAM_ID_IS_REQUIRE: 'ID đội là bắt buộc',
  THE_SEASON_ID_IS_REQUIRE: 'ID mùa giải là bắt buộc',
  THE_GROUP_ID_IS_REQUIRE: 'ID nhóm là bắt buộc',
  THE_SEASON_YEAR_IS_4_DIGIT: 'Năm mùa giải là 4 chữ số',
  THE_SEASON_NUMBER_TEAM_IS_2_DIGIT: 'Số đội trong mùa giải là 2 chữ số',
  THE_NUMBER_TEAM_FOR_KNOCKOUT_IS_2_DIGIT: 'Số đội cho vòng knockout là 2 chữ số và nhỏ hơn số đội và là 8, 16 hoặc 32',
  THE_POINTS_FOR_WIN_IS_1_DIGIT: 'Điểm dành cho chiến thắng là 1 chữ số và từ 1 đến 3',
  THE_POINTS_FOR_DRAW_IS_1_DIGIT: 'Điểm dành cho hòa là 1 chữ số và từ 1 đến 2',
  THE_SEASON_NUMBER_GROUP_IS_2_DIGIT: 'Số lượng nhóm trong mùa giải là 2 chữ số',
  THE_PAGE_ID_MUST_VALID_OBJECT_ID: 'ID trang phải là object ID hợp lệ',
  THE_TEAM_ID_MUST_VALID_OBJECT_ID: 'ID đội phải là object ID hợp lệ',
  THE_RANK_ID_MUST_VALID_OBJECT_ID: 'ID hạng lệ phải là object ID hợp lệ',
  THE_CONTENT_ID_MUST_VALID_OBJECT_ID: 'ID nội dung phải là object ID hợp lệ',
  THE_POST_ID_MUST_VALID_OBJECT_ID: 'ID bài đăng phải là object ID hợp lệ',
  THE_PAGE_ID_IS_REQUIRE: 'ID trang là bắt buộc',
  THE_PAGE_ID_IS_INVALID: 'ID trang không hợp lệ',
  THE_LAYOUT_IS_INVALID: 'Layout không hợp lệ',
  THE_LAYOUT_IS_REQUIRE: 'Layout là bắt buộc',
  THE_CONTENT_IS_REQUIRE: 'Nội dung là bắt buộc',
  THE_CONTENT_ID_IS_REQUIRE: 'ID nội dung là bắt buộc',
  THE_THUMBNAIL_IS_REQUIRE: 'Ảnh thu nhỏ là bắt buộc',
  THE_RANK_ID_IS_REQUIRE: 'ID hạng lệ là bắt buộc',
  THE_SEASON_ID_MUST_VALID_OBJECT_ID: 'ID mùa giải phải là object ID hợp lệ',
  THE_LEAGUE_ID_MUST_VALID_OBJECT_ID: 'ID giải đấu phải là object ID hợp lệ',
  THE_GROUP_ID_MUST_VALID_OBJECT_ID: 'ID nhóm phải là object ID hợp lệ',
  THE_CATEGORY_ID_IS_REQUIRE: 'ID danh mục là bắt buộc',
  THE_BODY_IS_REQUIRE: 'Nội dung là bắt buộc',
  THE_TITLE_IS_REQUIRE: 'Tiêu đề là bắt buộc',
  THE_DESCRIPTION_IS_REQUIRE: 'Mô tả là bắt buộc',
  THE_NUMBER_TEAM_FOR_KNOCKOUT_IS_REQUIRE: 'Số đội cho vòng knockout là bắt buộc',
  THE_NAME_IS_REQUIRE: 'Tên là bắt buộc',
  THE_YEAR_IS_REQUIRE: 'Năm là bắt buộc',
  THE_POINTS_FOR_WIN_IS_REQUIRE: 'Điểm cho chiến thắng là bắt buộc',
  THE_POINTS_FOR_DRAW_IS_REQUIRE: 'Điểm cho hòa là bắt buộc',
  THE_SEASON_NUMBER_TEAM_IS_REQUIRE: 'Số mùa của đội là bắt buộc',
  THE_SEASON_NUMBER_GROUP_IS_REQUIRE: 'Số mùa của nhóm là bắt buộc',
  THE_TYPE_IS_REQUIRE: 'Loại là bắt buộc',
  THE_LEAGUE_ID_IS_REQUIRE: 'ID của giải đấu là bắt buộc',
  THE_TEAM_IDS_IS_REQUIRE: 'ID của đội bóng là bắt buộc',
  THE_RANK_IDS_IS_REQUIRE: 'ID của xếp hạng là bắt buộc',
  LOGO_IS_REQUIRE: 'Logo là bắt buộc',
  THE_VIDEO_URL_REQUIRE: 'URL video là bắt buộc',
  POST_TYPE_IS_REQUIRE: 'Loại bài viết là bắt buộc',
  POST_TYPE_IS_INVALID: 'Loại bài viết không hợp lệ',
  INVALID_TOKEN: 'Token không hợp lệ',
  SEASON_ON_GOING_OR_END: 'Không thể cập nhật mùa giải đang diễn ra hoặc đã kết thúc',
  LEAGUE_ONLY_UPCOMING_SEASON: 'Chỉ có một mùa giải sắp tới cho giải đấu',
  THE_STATUS_SEASON_NOT_MATCH: 'Trạng thái mùa giải không phù hợp',
  THE_SEASON_TYPE_NOT_MATCH: 'Loại mùa giải không phù hợp',
  THE_CATEGORY_ID_MUST_VALID_OBJECT_ID: 'ID danh mục phải là đối tượng hợp lệ',
  THE_EMAIL_INVALID_FORMAT: 'Định dạng email không hợp lệ',
  THE_PASSWORD_IS_INVALID_FORMAT: 'Định dạng mật khẩu không hợp lệ',
  THE_PASSWORD_CONFIRM_DO_NOT_MATCH: 'Mật khẩu xác nhận không khớp',
  THE_PASSWORD_IS_REQUIRE: 'Mật khẩu là bắt buộc',
  THE_OTP_IS_REQUIRE: 'OTP là bắt buộc',
  THE_NEW_PASSWORD_IS_REQUIRE: 'Mật khẩu mới là bắt buộc',
  THE_PASSWORD_CONFIRM_IS_REQUIRE: 'Xác nhận mật khẩu là bắt buộc',
  THE_EMAIL_IS_REQUIRE: 'Email là bắt buộc',
  SEND_EMAIL_OTP_CODE_PLS_CHECK_EMAIL: 'Gửi mã OTP qua email, vui lòng kiểm tra email',
  SYSTEM_UNSTABLE: 'Hệ thống không ổn định, vui lòng thử lại',
  WRONG_PASSWORD: 'Sai mật khẩu',
  WRONG_OTP: 'Sai OTP',
  CHANGE_PASSWORD_SUCCESS: 'Thay đổi mật khẩu thành công',
  GOAL_IS_WRONG: 'Mục tiêu không đúng',
  STATUS_MATCH_IS_REQUIRE: 'Trạng thái phải khớp',
  STATUS_MATCH_IS_ONE_OF: 'Trạng thái phải là một trong những',
  THE_WEBSITEADS_IS_REQUIRE: 'Quảng cáo trên trang web là bắt buộc',
  ALL_LIVE_MATCHES_UPDATE: 'Tất cả trận đấu đang diễn ra đã được cập nhật',
  UPDATE_LINK_LIVE: 'Cập nhật liên kết trực tiếp',
  VIDEO_DEFAULT_UPDATE_LINK: 'Cập nhật liên kết mặc định của video',
  VIDEO_DEFAULT_UPDATE_THUMBNAIL: 'Cập nhật hình thu nhỏ mặc định của video',
  TRACKING_CPM_SUCCESSFULLY: 'Theo dõi CPM thành công',
  DAILY_BUDGET_LESS_THAN_OR_EQUAL_DAILY_BUDGET_CAMPAIGN: 'Ngân sách hàng ngày nhỏ hơn hoặc bằng ngân sách hàng ngày của chiến dịch',
  PUBLISHER_NOT_FOUND: 'Nhà xuất bản không tìm thấy',
  THE_VALUE_BANNER_SIZE_NOT_EXISTS: 'Kích thước biểu ngữ không tồn tại',
  CAN_NOT_CLICK_AGAIGN: 'Không thể nhấp chuột lại',
  DOMAIN_FAILED: 'Miền không thành công',
  ADVERTISER_NOT_FOUND: 'Nhà quảng cáo không tìm thấy',
  WEBSITE_NOT_FOUND: 'Trang web không tìm thấy',
  TRACKING_CLICK_FAILED: 'Theo dõi nhấp chuột thất bại',
  TRACKING_CLICK_SUCCESSFULLY: 'Theo dõi nhấp chuột thành công',
  OS_NOT_MATCH: 'Hệ điều hành không khớp',
  DEVICE_NOT_MATCH: 'Thiết bị không khớp',
  VERSION_NOT_MATCH: 'Phiên bản không khớp',
  AD_UNIT_NOT_FOUND: 'Đơn vị quảng cáo không tìm thấy',
  CITY_NOT_MATCH: 'Thành phố không khớp',
  COUNTRY_NOT_MATCH: 'Quốc gia không khớp',
  CAMPAIGN_EXCEED_LIMIT: 'Chiến dịch vượt quá giới hạn',
  CPM_LESS_THAN_OR_EQUAL_DAILY_BUDGET: 'CPM nhỏ hơn hoặc bằng ngân sách hàng ngày',
  CPC_LESS_THAN_OR_EQUAL_DAILY_BUDGET: 'CPC nhỏ hơn hoặc bằng ngân sách hàng ngày',
  CAMPAIGN_ACTIVE_CAN_NOT_EDIT: 'Không thể chỉnh sửa chiến dịch đang hoạt động',
  ADVERTISER_NOT_ENOUGH_BALANCE: 'Nhà quảng cáo không đủ số dư',
  CAMPAIGN_NOT_ACTIVE: 'Chiến dịch không hoạt động',
  GET_LIST_CAMPAIGNS_SUCCESSFULLY: 'Nhận danh sách chiến dịch thành công',
  CAMPAIGN_WEBSITEADS_NOT_FOUND: 'Không tìm thấy chiến dịch quảng cáo trên trang web',
  ASSIGN_CAMPAIGN_FOR_WEBSITEADS_SUCCESSFULLY: 'Gán chiến dịch cho quảng cáo trên trang web thành công',
  DATA_SCHEDULE_DATE_INVALID: 'Ngày lên lịch dữ liệu không hợp lệ',
  THE_VALUE_STATUS_NOT_EXISTS: 'Trạng thái không tồn tại',
  THE_VALUE_STATUS_IS_REQUIRED: 'Trạng thái là bắt buộc',
  UPDATE_STATUS_CAMPAIGN_SUCCESSFULLY: 'Cập nhật trạng thái chiến dịch thành công',
  ADVERTISER_NOT_ENOUGH_BALANCE: 'Nhà quảng cáo không đủ số dư',
  NOT_PERMISSION_UPDATE_CAMPAIGN_GROUP: 'Không có quyền cập nhật nhóm chiến dịch',
  THE_VALUE_CPC_INVALID: 'Giá CPC không hợp lệ',
  THE_CPC_REQUIRE: 'Giá CPC là bắt buộc',
  THE_VALUE_COMPETITIVENESS_NOT_EXISTS: 'Giá trị đối thủ không tồn tại',
  THE_VALUE_OS_NAME_NOT_EXISTS: 'Tên hệ điều hành không tồn tại',
  CAMPAIGN_GROUP_NOT_FOUND: 'Nhóm chiến dịch không tìm thấy',
  CAMPAIGN_GROUP_EXISTS: 'Nhóm chiến dịch đã tồn tại',
  CAMPAIGN_EXISTS: 'Chiến dịch đã tồn tại',
  DAILY_BUDGET_INVALID: 'Ngân sách hàng ngày không hợp lệ',
  MINIMUMCTR_INVALID: 'Tỷ lệ nhấp chuột tối thiểu không hợp lệ',
  MAX_CONVERSION_COST_INVALID: 'Chi phí chuyển đổi tối đa không hợp lệ',
  CREATE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Tạo nhóm chiến dịch thành công',
  CREATE_CAMPAIGN_GROUP_FAILED: 'Tạo nhóm chiến dịch thất bại',
  GROUP_NAME_INVALID_FORMAT: 'Định dạng tên nhóm không hợp lệ',
  CAMPAIGN_NAME_INVALID_FORMAT: 'Định dạng tên chiến dịch không hợp lệ',
  GROUP_ID_IS_REQUIRE: 'ID nhóm là bắt buộc',
  GROUP_NAME_IS_REQUIRE: 'Tên nhóm là bắt buộc',
  CAMPAIGN_ID_IS_REQUIRE: 'ID chiến dịch là bắt buộc',
  THE_BUDGET_IS_REQUIRE: 'Ngân sách là bắt buộc',
  THE_VALUE_TYPE_NOT_EXISTS: 'Giá trị loại không tồn tại',
  MAX_CONVERSION_COST_REQUIRE: 'Chi phí chuyển đổi tối đa là bắt buộc',
  MINIMUMCTR_REQUIRE: 'Tỷ lệ nhấp chuột tối thiểu là bắt buộc',
  ADS_CONTENT_INVALID: 'Nội dung quảng cáo không hợp lệ',
  ADS_CONTENT_EMPTY_OR_INVALID: 'Nội dung quảng cáo trống hoặc không hợp lệ',
  CREATE_CAMPAIGN_SUCCESSFULLY: 'Tạo chiến dịch thành công',
  CREATE_CAMPAIGN_FAILED: 'Tạo chiến dịch thất bại',
  THE_TITLE_REQUIRE: 'Tiêu đề là bắt buộc',
  THE_MESSAGE_REQUIRE: 'Thông điệp là bắt buộc',
  THE_BANNER_IMAGE_REQUIRE: 'Hình ảnh biểu ngữ là bắt buộc',
  THE_BANNER_SIZE_REQUIRE: 'Kích thước biểu ngữ là bắt buộc',
  THE_ICON_REQUIRE: 'Biểu tượng là bắt buộc',
  THE_HEO_IMAGE_REQUIRE: 'Hình ảnh HEO là bắt buộc',
  UPLOAD_IMAGE_ONLY: 'Chỉ tải lên hình ảnh',
  UPDATE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Cập nhật nhóm chiến dịch thành công',
  UPDATE_CAMPAIGN_GROUP_FAILED: 'Cập nhật nhóm chiến dịch thất bại',
  CAMPAIGN_GROUP_IS_USING_CAN_NOT_DELETE: 'Nhóm chiến dịch đang được sử dụng, không thể xóa',
  DELETE_CAMPAIGN_GROUP_SUCCESSFULLY: 'Xóa nhóm chiến dịch thành công',
  DELETE_CAMPAIGN_GROUP_FAILED: 'Xóa nhóm chiến dịch thất bại',
  CAMPAIGN_NOT_FOUND: 'Không tìm thấy chiến dịch',
  WEBSITE_ADS_NOT_APPROVED: 'Quảng cáo trên trang web chưa được phê duyệt',
  WEBSITEADS_NOT_FOUND: 'Không tìm thấy quảng cáo trên trang web',
  CAMPAIGN_ID_IS_REQUIRE: 'ID chiến dịch là bắt buộc',
  DELETE_CAMPAIGN_FAILED: 'Xóa chiến dịch thất bại',
  DELETE_CAMPAIGN_SUCCESSFULLY: 'Xóa chiến dịch thành công',
  THE_CPM_REQUIRE: 'CPM là bắt buộc',
  UPDATE_CAMPAIGN_SUCCESSFULLY: 'Cập nhật chiến dịch thành công',
  UPDATE_CAMPAIGN_FAILED: 'Cập nhật chiến dịch thất bại',
  GET_DETAIL_CAMPAIGN_FAILED: 'Lấy chi tiết chiến dịch thất bại',
  GET_DETAIL_CAMPAIGN_SUCCESSFULLY: 'Lấy chi tiết chiến dịch thành công',
  GET_LIST_OS_NAME_SUCCESSFULLY: 'Lấy danh sách tên hệ điều hành thành công',
  GET_LIST_VERSION_SUCCESSFULLY: 'Lấy danh sách phiên bản thành công',
  GET_DETAIL_CAMPAIGN_GROUP_SUCCESSFULLY: 'Lấy chi tiết nhóm chiến dịch thành công',
  THE_USER_ID_IS_REQUIRE: 'ID người dùng là bắt buộc',
  UPDATE_STATUS_TICKET_SUCCESSFULLY: 'Cập nhật trạng thái vé thành công',
  UPDATE_TICKET_SUCCESSFULLY: 'Cập nhật vé thành công',
  CAN_NOT_UPDATE_TICKET: 'Không thể cập nhật vé',
  ASSIGN_TICKET_SUCCESSFULLY: 'Giao vé thành công',
  GET_DETAIL_TICKET_SUCCESS: 'Lấy chi tiết vé thành công',
  TICKET_NOT_FOUND: 'Không tìm thấy vé',
  THE_VALUE_TYPE_NOT_EXISTS: 'Giá trị loại không tồn tại',
  THE_SUBJECT_TICKET_IS_REQUIRE: 'Chủ đề vé là bắt buộc',
  THE_TYPE_TICKET_REQUIRE: 'Loại vé là bắt buộc',
  THE_CONTENT_TICKET_IS_REQUIRE: 'Nội dung vé là bắt buộc',
  CREATE_TICKET_SUCCESFULLY: 'Tạo vé thành công',
  CLOSE_TICKET_SUCCESFULLY: 'Đóng vé thành công',
  OPEN_TICKET_SUCCESFULLY: 'Mở vé thành công',
  THE_VALUE_STATUS_NOT_EXISTS: 'Giá trị trạng thái không tồn tại',
  THE_STATUS_TICKET_REQUIRE: 'Trạng thái vé là bắt buộc',
  CREATE_GROUP_SUCCESFULLY: 'Tạo nhóm thành công',
  CREATE_GROUP_FAILED: 'Tạo nhóm thất bại',
  GROUP_NOT_FOUND: 'Không tìm thấy nhóm',
  UPDATE_GROUP_SUCCESFULLY: 'Cập nhật nhóm thành công',
  CAN_NOT_DELETE_GROUP: 'Không thể xóa nhóm',
  DELETE_GROUP_SUCCESSFULLY: 'Xóa nhóm thành công',
  THE_VALUE_GROUP_NAME_NOT_EXISTS: 'Tên nhóm không tồn tại',
  WEBSITE_NOT_APPROVED: 'Trang web chưa được phê duyệt.',
  'Tên danh mục website chỉ gồm chữ cái và số': 'Tên danh mục website chỉ gồm chữ cái và số',
  'Tên danh mục website là bắt buộc': 'Tên danh mục website là bắt buộc',
};

export const ManagerStatus = {
  Normal: 'Normal',
  Temporary: 'Temporary',
  Permanently: 'Permanently',
};

export const ManagerStatusLabel = {
  [ManagerStatus?.Normal]: 'Bình thường',
  [ManagerStatus?.Temporary]: 'Khóa tạm thời',
  [ManagerStatus?.Permanently]: 'Khoá vĩnh viễn',
};

export const ManagerStatusOption = [
  { label: ManagerStatusLabel.Normal, value: ManagerStatus.Normal },
  { label: ManagerStatusLabel.Temporary, value: ManagerStatus.Temporary },
  { label: ManagerStatusLabel.Permanently, value: ManagerStatus.Permanently },
];

export const CampaignStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  REJECTED: 'REJECTED',
  DELETED: 'DELETED',
};

export const CampaignStatusOption = Object.keys(CampaignStatus).map(key => ({ label: key, value: CampaignStatus[key] }));

export const POST_LAYOUT = {
  BANNER_TOP: 'BANNER_TOP',
  BANNER_RIGHT: 'BANNER_RIGHT',
  BANNER_LEFT: 'BANNER_LEFT',
};

export const PostLayoutOption = Object.keys(POST_LAYOUT).map(key => ({ label: key, value: POST_LAYOUT[key] }));

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const TicketStatus = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  FINISHED: 'finished',
};

export const TYPE_TICKET = {
  GENERAL_QUESTION: 'GENERAL_QUESTION',
  MY_ACCOUNT: 'MY_ACCOUNT',
  PAYMENTS: 'PAYMENTS',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  VISA_MASTERCARD: 'VISA_MASTERCARD',
};

export const TypeTicket = Object.keys(TYPE_TICKET).map(key => ({
  label: convertEnumToLabelOption(key),
  value: TYPE_TICKET[key],
}));

export const TicketStatusOption = Object.keys(TicketStatus).map(key => ({
  label: convertEnumToLabelOption(key),
  value: TicketStatus[key],
}));

export const OS_NAME = {
  ALL: 'ALL',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
  MAC: 'MAC',
  WINDOWS: 'WINDOWS',
};

export const OsNameOption = Object.keys(OS_NAME).map(key => ({
  label: key,
  value: OS_NAME[key],
}));

export const CAMPAIGN_TYPE = {
  PUSH_ADS: 'PUSH_ADS',
  NATIVE_ADS: 'NATIVE_ADS',
  BANNER_ADS: 'BANNER_ADS',
  DIRECT_LINK: 'DIRECT_LINK',
  INTERSTITIAL: 'INTERSTITIAL',
  POP_UNDER: 'POP_UNDER'
};

export const CampaignTypeOption = Object.keys(CAMPAIGN_TYPE).map(key => ({
  label: key,
  value: CAMPAIGN_TYPE[key],
}));

export const DEVICES = {
  DESKTOP: 'DESKTOP',
  SMARTPHONE: 'SMARTPHONE',
  TABLET: 'TABLET',
};

export const DeviceOption = Object.keys(DEVICES).map(key => ({
  label: key,
  value: DEVICES[key],
}));

export const STATUS_TICKET = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  FINISHED: 'finished',
};

export const STATUS_WITHDRAW = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  SUCCESS: 'SUCCESS',
};

export const CHANGE_STATUS_WITHDRAW = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const StatusTicketOptions = Object.keys(STATUS_TICKET).map(key => ({
  label: capitalize(key),
  value: STATUS_TICKET[key],
}));

// export const StatusWithDrawOptions = Object.keys(STATUS_WITHDRAW).map(key => ({
//   label: capitalize(key),
//   value: STATUS_WITHDRAW[key],
// }));

export const ChangeStatusDrawOptions = [
  {label: 'Duyệt' , value:'APPROVED'},
  {label: 'Từ chối' , value:'REJECTED'},
]

export const StatusWithDrawOptions = [
  {label: 'Chờ duyệt' , value:'PENDING'},
  {label: 'Đã duyệt' , value:'APPROVED'},
  {label: 'Từ chối' , value:'REJECTED'},
  {label: 'Thành công' , value:'SUCCESS'},
]

export const PermissionValue = {
  ADVERTISER: 'Nhà quảng cáo',
  CAMPAIGN: 'Chiến dịch',
  DIRECTLINK: 'Liên kết',
  GROUP: 'Nhóm phân quyền',
  PUBLISHER: 'Nhà xuất bản',
  TICKET: 'Ticket',
  USER: 'Tài khoản quản trị',
  WEBSITE: 'Website',
  WEBSITEADS: 'Đơn vị quảng cáo',
  WEBSITECATEGORY: 'Danh mục Website',
};

export const TabFeatureManagement = {
  EditInformation: 'Chỉnh sửa thông tin',
  DetailInformation: 'Thông tin chi tiết',
  BLock: 'Khóa nhà quản lý',
  AssignPermission: 'Phân quyền',
}

export const TableCampaignGroups = {
  UpdateCampaignGroup: 'Cập nhật nhóm chiến dịch',
}

export const TabFeatureAdvertiser = {
  ChooseManagement: 'Chỉ định quản lý',
  LockManagement: 'Khóa nhà quảng cáo',
  AdvertiserInformation: 'Thông tin cá nhân',
  ChangePassword: 'Thay đổi mật khẩu',
  UpdateInformation: 'Cập nhật thông tin',
}

export const TabFeaturePublisher = {
  LockManagement: 'Khóa nhà xuất bản',
  PublisherInformation: 'Thông tin cá nhân',
  ChangePassword: 'Thay đổi mật khẩu',
  UpdateInformation: 'Cập nhật thông tin',
}

export const TabFeatureTicket = {
  ChangeStatus: 'Thay đổi trạng thái',
  ChooseManagement: 'Chọn người xử lý',
  TicketInformation: 'THông tin chi tiết'
}

export const TabWithDraw = {
  Detail: 'Chi tiết',
  ChangeStatus: 'Duyệt rút tiền',
}

export const TurnOffKey = {
  On: 'on',
  Off: 'off',
};

export const TurnOffOptions = [
  {
    label: 'Bật',
    value: TurnOffKey.On,
  },
  {
    label: 'Tắt',
    value: TurnOffKey.Off,
  },
];

const NOTIFICATION_TYPE = {
  CREATE_TICKET: "Tạo ticket",
  UPDATE_TICKET: 'Cập nhật ticket',
  CHANGE_PASSWORD: 'Thay đổi mật khẩu',
  UPDATE_INFORMATION: 'Cập nhật thông tin',
  CREATE_DIRECT_LINK: 'Tạo liên kết',
  UPDATE_DIRECT_LINK: 'Cập nhật liên kết',
  CREATE_WEBSITE: 'Tạo website',
  UPDATE_WEBSITE: 'Cập nhật website',
  DELETE_WEBSITE: 'Xóa website',
  CREATE_CAMPAIGN_GROUP: 'Tạo nhóm chiến dịch',
  UPDATE_CAMPAIGN_GROUP: 'Cập nhật nhóm chiến dịch',
  CREATE_CAMPAIGN: 'Tạo chiến dịch',
  UPDATE_CAMPAIGN: 'Cập nhật chiến dịch',
  DELETE_CAMPAIGN: 'Xóa chiến dịch',
}

export const PERMISSIONS = {
  VIEW_DASHBOARD: "Quyền xem thống kê",
  VIEW_LIST_WEBSITE: "Quyền xem danh sách website",
  DELETE_WEBSITE: "Quyền xóa website",
  VIEW_LIST_CATEGORY: "Quyền xem danh sách danh mục",
  CREATE_CATEGORY: "Quyền tạo mới danh mục",
  UPDATE_CATEGORY: "Quyền chỉnh sửa danh mục",
  DELETE_CATEGORY: "Quyền xóa danh mục",
  VIEW_LIST_DIRECT_LINK: "Quyền xem danh sách liên kết",
  CHANGE_STATUS_DIRECT_LINK: "Quyền xét duyệt liên kết",
  VIEW_LIST_ADS: "Quyền xem danh sách đơn vị quảng cáo",
  CHANGE_STATUS_ADS: "Quyền xét duyệt đơn vị quảng cáo",
  ASSIGN_CAMPAIGN_FOR_ADS: "Quyền chỉ định chiến dịch cho đơn vị quảng cáo",
  VIEW_LIST_ADMIN: "Quyền xem danh sách tài khoản quản trị",
  CREATE_ADMIN: "Quyền tạo mới tài khoản quản trị",
  UPDATE_INFORMATION_ADMIN:
    "Quyền chỉnh sửa thông tin cá nhân tài khoản quản trị",
  ASSIGN_PERMISSION_ADMIN: "Quyền phân quyền cho tài khoản quản trị",
  LOCK_ADMIN: "Quyền khóa tài khoản quản trị",
  VIEW_DETAIL_ADMIN: "Quyền xem chi tiết tài khoản quản trị",
  DELETE_ADMIN: "Quyền xóa tài khoản quản trị",
  CHANGE_PASSWORD_ADMIN: "Quyền đổi mật khẩu nhà quản trị",
  VIEW_LIST_ADVERTISER: "Quyền xem danh sách tài khoản nhà quảng cáo",
  VIEW_DETAIL_ADVERTISER: "Quyền xem thông tin chi tiết nhà quảng cáo",
  ASSIGN_MANAGER_FOR_ADVERTISER: "Quyền chỉ định quản lý cho nhà quảng cáo",
  UPDATE_INFORMATION_ADVERTISER: "Quyền cập nhật thông tin nhà quảng cáo",
  LOCK_ADVERTISER: "Quyền khóa nhà quảng cáo",
  CHANGE_PASSWORD_ADVERTISER: "Quyền đổi mật khẩu nhà quảng cáo",
  VIEW_LIST_REFERRAL_ADVERTISER: "Quyền xem danh sách hoa hồng nhà quảng cáo",
  VIEW_LIST_PUBLISHER: "Quyền xem danh sách tài khoản nhà xuất bản",
  VIEW_DETAIL_PUBLISHER: "Quyền xem thông tin chi tiết nhà xuất bản",
  UPDATE_INFORMATION_PUBLISHER: "Quyền cập nhật thông tin nhà xuất bản",
  LOCK_PUBLISHER: "Quyền khóa nhà xuất bản",
  CHANGE_PASSWORD_PUBLISHER: "Quyền đổi mật khẩu nhà xuất bản",
  VIEW_LIST_REFERRAL_PUBLISHER: "Quyền xem danh sách hoa hồng nhà xuất bản",
  VIEW_CPC_STASTISCTIC: "Quyền xem thống kê CPC",
  VIEW_CPM_STASTISCTIC: "Quyền xem thống kê CPM",
  VIEW_CPC_STASTISCTIC: "Quyền xem thống kê CPC",
  VIEW_LIST_CAMPAIGN_GROUP: "Quyền xem danh sách nhóm chiến dịch",
  VIEW_DETAIL_CAMPAIGN_GROUP: "Quyền xem chi tiết nhóm chiến dịch",
  UPDATE_CAMPAIGN_GROUP: "Quyền cập nhật thông tin nhóm chiến dịch",
  DELETE_CAMPAIGN_GROUP: "Quyền xóa nhóm chiến dịch",
  VIEW_LIST_CAMPAIGN: "Quyền xem danh sách chiến dịch",
  VIEW_DETAIL_CAMPAIGN: "Quyền xem chi tiết chiến dịch",
  CHANGE_STATUS_CAMPAIGN: "Quyền phê duyệt chiến dịch",
  DELETE_CAMPAIGN: "Quyền xóa chiến dịch",
  ASSIGN_WEBSITE_FOR_CAMPAIGN: "Quyền chỉ định website cho chiến dịch",
  VIEW_LIST_POST: "Quyền xem danh sách bài viết",
  VIEW_DETAIL_POST: "Quyền xem danh sách bài viết",
  CREATE_POST: "Quyền tạo mới bài viết",
  UPDATE_POST: "Quyền chỉnh sửa bài viết",
  DELETE_POST: "Quyền xóa bài viết",
  VIEW_LIST_TICKET_CATEGORY: "Quyền xem danh sách danh mục ticket",
  CREATE_TICKET_CATEGORY: "Quyền tạo danh mục ticket",
  UPDATE_TICKET_CATEGORY: "Quyền chỉnh sửa danh mục ticket",
  DELETE_TICKET_CATEGORY: "Quyền xóa danh mục ticket",
  VIEW_LIST_TICKET: "Quyền xem danh sách ticket",
  VIEW_DETAIL_TICKET: "Quyền xem chi tiết ticket",
  CHANGE_STATUS_TICKET: "Quyền phê duyệt ticket",
  ASSIGN_MANAGER_FOR_TICKET: "Quyền chỉ định người xử lý ticket",
  CLOSE_TICKET: "Quyền đóng ticket",
  UPDATE_CONTENT: "Quyền tạo nội dung trang chủ",
  UPDATE_SETTING_COMMISSION: "Quyền cài đặt hoa hồng mặc định",
  VIEW_LIST_NOTIFICATION: "Quyền xem danh sách thông báo thay đổi",
}

export const PermissionOption = Object.keys(PERMISSIONS).map(key => ({
  label: PERMISSIONS[key],
  value: key,
}));

export const TabFeatureProfile = {
  IdentityInfo: 'Thông tin liên lạc của tôi',
  ChangePassword: 'Thay đổi mật khẩu',
  Rechange: 'Nạp tiền',
  HistoryRechange: 'Lịch sử nạp tiền'
}


import LogoACB from 'assets/img/method-payment/ACB.png'
import LogoSAC from 'assets/img/method-payment/SAC.png'
import LogoBIDV from 'assets/img/method-payment/BIDV.png'
import LogoMSB from 'assets/img/method-payment/MSB.png'
import LogoVCB from 'assets/img/method-payment/VCB.png'
import LogoTCB from 'assets/img/method-payment/TCB.png'
import LogoMB from 'assets/img/method-payment/MB.png'
import LogoVP from 'assets/img/method-payment/VP.png'

export const BankStatus = {
    Available: '1',
    InMaintenance: '2',
};

export const PaymentGateway = {
    MomoQrCode: 'momo_qr',
    ZaloQrCode: 'zalo_qr',
    LoadCard: 'rcgcard_pc',
    OnlineBanking: 'upacp_pc',
    ViettelpayQr: 'viettel_qr',
    BankTransfer: 'bank_transfer',
    BankTransferQr: 'bank_qr',
    LoadCardZing: 'rcgcard_zing',
    VipQr: 'vip_qr',
    Bank: 'bank',
    UsdtQr: 'usdt_qr',
};

export const BankLogo = {
    ACB: LogoACB,
    SAC: LogoSAC,
    BIDV: LogoBIDV,
    MSB: LogoMSB,
    VCB: LogoVCB,
    TCB: LogoTCB,
    MB: LogoMB,
    VP: LogoVP,
  };

export const MethodsText = {
    momo_qr: 'Momo',
    zalo_qr: 'Zalo',
    rcgcard_pc: 'Thẻ cào',
    upacp_pc: 'Internet Banking',
    viettel_qr: 'Viettel Pay',
    bank_transfer: 'Chuyển nhanh 24/7',
    bank_qr: 'Quét mã ngân hàng',
    rcgcard_zing: 'Thẻ cào Zing',
    vip_qr: 'VIP QR',
    usdt_qr: 'USDT',
    bank: 'Ngân hàng',
};

export const BankMethod = [PaymentGateway.OnlineBanking, PaymentGateway.Bank, PaymentGateway.BankTransferQr, PaymentGateway.BankTransfer];
