import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { API_ROUTES, MessageApi } from "utils/constant";
import { axiosPost } from "utils/api";

const AddWebSiteCategory = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(""); 
  const toast = useToast();
  const createWebCategory = async () => {
    if (name === "") {
      setNameError("Vui lòng nhập tên!");
      return;
    }
    const categoryData = {
      name: name,
    };
    try {
      const response = await axiosPost(API_ROUTES.CreateCategory, categoryData);
      if (response.data.code === 0) {
        toast({
          title: `Tạo danh mục trang web thành công.`,
          status: "success",
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: `Tạo danh mục trang web thất bại.`,
          status: "error",
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title:
        MessageApi?.[error?.response?.data?.errors[0]?.msg] || error?.response?.data?.msg ||
          "Tạo danh mục trang web thất bại.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Thông tin danh mục</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl isInvalid={nameError !== ""}>
              <FormLabel>Tên</FormLabel>
              <Input
                type="text"
                placeholder="Nhập tên"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setNameError("");
                }}
              />
              <FormErrorMessage>{nameError}</FormErrorMessage>
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                createWebCategory();
              }}
            >
              Thêm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddWebSiteCategory;
