import { Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import { RiInformationFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { DeleteIcon } from '@chakra-ui/icons';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const TableBody = (props) => {
  const { campaignGroup, isLast, handelDetail, handelUpdate, deleteCampaignGroup } = props;
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { userInfo } = useUserState();

  return (
    <Tr>
      <Td minWidth={{ sm: '150px' }} pl="0px" maxWidth={"250px"} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.groupName}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.advertiser?.username}
          </Text>
        </Flex>
      </Td>
      {/* <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.advertiser?.email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.advertiser?.phone}
          </Text>
        </Flex>
      </Td> */}
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.totalCampaign || 0}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignGroup?.budget ? 'Không giới hạn' : campaignGroup?.dailyBudget}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(campaignGroup?.createdAt, 'YYYY-MM-DD')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_CAMPAIGN_GROUP'}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelDetail(campaignGroup);
            }}
          >
            <RiInformationFill size={20} />
          </IconButton>
        </AuthorizationComponentWrapper>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_CAMPAIGN_GROUP'}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdate(campaignGroup);
            }}
          >
            <FaEdit size={20} />
          </IconButton>
        </AuthorizationComponentWrapper>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_CAMPAIGN_GROUP'}>
          <IconButton
            p={2}
            textColor="red"
            bg="transparent"
            onClick={() => {
              deleteCampaignGroup(campaignGroup);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </AuthorizationComponentWrapper>
        </Flex>
      </Td>
    </Tr>
  );
};

export default TableBody;