import { Box, Image } from '@chakra-ui/react';
import NotiBell from 'assets/img/bell-header-mobile.png';
import { useEffect, useState } from "react";
import { socket } from "utils/socket";
const Notication = () => {
    const [noti, setNoti] = useState(false);


    const handleNotication = () => {
        setNoti(false);
    }
    useEffect(() => {
        socket.on('send_notification_to_admin', (value) => {
            setNoti(true);
        });

        return () => {
            socket.off('send_notification_to_admin');
        };
    }, []);
    return (
        <Box
            onClick={handleNotication}
            position="relative"
            cursor="pointer"
        >
            <Image
                src={NotiBell}
                alt="Bell"
                width="20px"
                height="20px"
                cursor="pointer"
            />
            {noti &&
                <Box
                    width="5px"
                    height="5px"
                    bg="#FF2E00"
                    rounded="full"
                    position="absolute"
                    top="0.5"
                    right="1"
                />
            }
        </Box>
    );
}

export default Notication;