import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import CheckboxController from 'components/Form/CheckboxController';
import InputController from 'components/Form/InputController';
import NumericInputController from 'components/Form/NumericInputController';
import RadioController from 'components/Form/RadioController';
import SelectController from 'components/Form/SelectController';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi, TurnOffKey, TurnOffOptions } from 'utils/constant';
import { CampaignGroupValidate } from 'utils/validation';

const FormRequestUpdate = ({ userDetail, refetch, onClose }) => {
  const params = useParams();
  const history = useHistory();
  const { id } = params || {};
  const toast = useToast();

  // const { isOpen: isChooseCampaignOpen, onOpen: onChooseCampaignOpen, onClose: onChooseCampaignClose } = useDisclosure();

  // const { data: campaignGroupDetail } = useGetCampaignGroupIdDetailQuery(id, {
  //   enabled: !!id,
  // });
  // const createCampaignGroupMutation = useCreateCampaignGroupMutation();
  // const updateCampaignGroupMutation = useUpdateCampaignGroupMutation();

  const {
    control,
    watch,
    handleSubmit,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampaignGroupValidate),
    defaultValues: {
      groupName: '',
      budget: false,
      minimumCTR: TurnOffKey.Off,
      maxConversionCost: TurnOffKey.Off,
      minCTR: '',
      minDelivered: '',
      maxCost: '',
      minConversions: '',
    },
  });
  const watchBudget = watch('budget');
  const watchCTR = watch('minimumCTR');
  const watchConversion = watch('maxConversionCost');

  useEffect(() => {
    if (!isEmpty(userDetail)) {
      reset({
        ...userDetail,
        groupName: userDetail.groupName,
        budget: userDetail.budget,
        maxConversionCost: userDetail?.maxConversionCost ? TurnOffKey.On : TurnOffKey.Off,
        minimumCTR: userDetail?.minimumCTR ? TurnOffKey.On : TurnOffKey.Off,
        minDelivered: userDetail?.minDelivered ?? '',
        minDelivered: userDetail?.dailyBudget ?? '',
      });
    }
  }, [userDetail]);

  const onSubmit = async values => {
    const dataSubmit = {
      ...values,
      minimumCTR: values?.minimumCTR === TurnOffKey.On ? true : false,
      maxConversionCost: values?.maxConversionCost === TurnOffKey.On ? true : false,
      groupID: userDetail?._id,
    };
    try {
      const response = await axiosPost(API_ROUTES.updateCampaignGroup, dataSubmit);
      if (response?.data?.code == 0) {
        toast({
          title: 'Cập nhật nhóm chiến dịch thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        refetch();
        onClose();
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Cập nhật nhóm chiến dịch thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <form>
        <Box bgColor="white" borderRadius="10px" mb={25}>
          <Box>
            <InputController
              control={control}
              name="groupName"
              label="Tên nhóm"
              isRequired
              styleContainer={{ pb: '4' }}
              styleLabel={{ fontWeight: '700' }}
            />
            <Box display="flex" alignItems="center" pb={4}>
              <Text fontWeight="700" minW={90}>
                Ngân sách
              </Text>
              <CheckboxController
                control={control}
                name="budget"
                content="Không giới hạn"
                onChange={e => {
                  if (e.target.checked) {
                    setValue('dailyBudget', '');
                  }
                  trigger();
                }}
              />
            </Box>
            <NumericInputController control={control} name="dailyBudget" label="Ngân sách tối đa 1 ngày" disabled={watchBudget} />
          </Box>
        </Box>
        <Box bgColor="white" borderRadius="10px" mb={25}>
          <Text fontSize="16px" fontWeight="700">
            CÀI ĐẶT TỰ ĐỘNG DỪNG CHIẾN DỊCH
          </Text>
          <Divider my={15} />
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Box mb="15px">
                <Text fontWeight={700}>CTR Tối thiểu</Text>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Box>
                <RadioController control={control} name="minimumCTR" options={TurnOffOptions} />
                {watchCTR === TurnOffKey.On && (
                  <>
                    <InputController control={control} name="minCTR" label="CTR Tối thiểu" styleContainer={{ py: '4' }} />
                    <InputController control={control} name="minDelivered" isRequired label="Phân phối tối thiểu" />
                  </>
                )}
              </Box>
            </GridItem>
          </Grid>

          <Divider my={25} />

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Box mb="15px">
                <Text fontWeight={700}>Chi phí tối đa cho 1 conversion</Text>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <Box>
                <RadioController control={control} name="maxConversionCost" options={TurnOffOptions} styleContainer={{ pb: '4' }} />
                {watchConversion === TurnOffKey.On && (
                  <>
                    <InputController
                      control={control}
                      name="maxCost"
                      label="Chi phí tối đa cho 1 conversion"
                      styleContainer={{ pb: '4' }}
                    />
                    <InputController control={control} name="minConversions" label="Số conversions tối thiểu" />
                  </>
                )}
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </form>
      <Button type="submit" p="0px" variant="solid" bg="blue.400" textColor="white" onClick={handleSubmit(onSubmit)}>
        <Flex
          align="center"
          w={{ sm: '100%', lg: '135px' }}
          borderRadius="8px"
          justifyContent="center"
          py="10px"
          boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
          cursor="pointer"
        >
          <Text fontSize="sm" fontWeight="bold">
            Xác nhận
          </Text>
        </Flex>
      </Button>
    </Box>
  );
};

export default FormRequestUpdate;
