import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { API_ROUTES, ManagerStatus, ManagerStatusLabel, ManagerStatusOption, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import DatePickerController from 'components/Form/DatePickerController';
import SelectController from 'components/Form/SelectController';
import { BlockManagerValidate } from 'utils/validation';

const defaultValues = {
  status: { label: ManagerStatusLabel.Normal, value: ManagerStatus.Normal },
};

const BlockAdvertiser = ({ advertiserDetail, onClose, refetchData }) => {
  const toast = useToast();
  const [{ loading }, blockManagerApi] = useAxios(
    {
      url: API_ROUTES.BlockAdvertiser,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(BlockManagerValidate),
    defaultValues,
  });
  const watchStatus = watch('status');

  useEffect(() => {
    if (!isEmpty(advertiserDetail)) {
      reset({
        status: ManagerStatusOption.find(item => item?.value === advertiserDetail?.status) || {
          label: ManagerStatusLabel.Normal,
          value: ManagerStatus.Normal,
        },
        ...(advertiserDetail?.dateUnlock && { date: moment(advertiserDetail.dateUnlock).toDate() }),
      });
    }
  }, [advertiserDetail]);

  const handleSuccess = () => {
    toast({
      title: 'Khóa nhà quảng cáo thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Khóa nhà quảng cáo thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    blockManagerApi({
      data: {
        advertiserID: advertiserDetail?._id,
        status: values?.status?.value,
        ...(values?.date && { date: moment(values?.date).startOf('day').toISOString() }),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box w={"100%"}>

      <form style={{ width: "100%" }}>
        <SelectController
          control={control}
          name="status"
          label="Trạng thái"
          isRequired
          options={ManagerStatusOption}
          styleContainer={{ pb: 4 }}
        />
        {watchStatus?.value === ManagerStatus.Temporary && (
          <DatePickerController
            isRequired
            control={control}
            name="date"
            label="Ngày khóa"
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
          />
        )}
      </form>

      <Flex justifyContent={"end"}>
        {/* <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Block);
              }}
            >
              Hủy
            </Button> */}
        <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default BlockAdvertiser;
