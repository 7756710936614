import {
  Text,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { ManagerStatusLabel } from 'utils/constant';

function Detail({ advertiserDetail, day }) {

  return (
    <Box>
      <Text pb="12px" fontSize="lg">
        <strong>Tài khoản:</strong> {advertiserDetail?.username}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Email:</strong> {advertiserDetail?.email}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Số điện thoại:</strong> {advertiserDetail?.phone}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Công ty:</strong> {advertiserDetail?.company}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Số dư:</strong> {advertiserDetail?.balance}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Trạng thái:</strong> {ManagerStatusLabel[advertiserDetail?.status]}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Ngày tạo:</strong> {moment(advertiserDetail?.createdAt).format('DD-MM-YYYY')}
      </Text>
    </Box>
  );
}
export default Detail;
