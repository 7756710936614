import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Box,
  Button,
} from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';

const MultiSelectMenu = props => {
  const { label, options, buttonProps, minWidth, isOpen, onClose, onOpen, onBlur, filterTickets, clearSelectedOption } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(filterTickets);
  }, [filterTickets]);

  const handleSelectOption = option => {
    const isExist = selectedOptions.filter(item => item.value === option.value)?.length > 0 ? true : false;
    if (!isExist) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(prev => prev.filter(item => item.value !== option.value));
    }
  };

  const renderValues = datas => {
    const strings = datas.map(item => item.label);
    return strings.toString();
  };

  const handleClosse = () => {
    onBlur(selectedOptions);
    onClose();
  };

  return (
    <Menu closeOnSelect={false} isOpen={isOpen} onClose={handleClosse} onOpen={onOpen}>
      <MenuButton
        type="button"
        width={'100%'}
        backgroundColor={'white'}
        color={'gray.600'}
        borderColor={'gray.300'}
        borderWidth={1}
        overflow={'hidden'}
        // p={2}
        // px={4}
        borderRadius="4px"
        _focus={{
          outline: 'none',
        }}
        {...buttonProps}
      >
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} position={'relative'}>
          <Box textAlign={'left'} px={'16px'} width={'90%'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
            {`${selectedOptions.length > 0 ? renderValues(selectedOptions) : `${label}`}`}
          </Box>
          <Box bg={'#EDF2F7'} px={'16px'} py={'10px'}>
            <IoIosArrowDown />
          </Box>
        </Box>
      </MenuButton>
      <MenuList minWidth={minWidth}>
        <MenuGroup title={undefined}>
          <MenuItem
            onClick={() => {
              setSelectedOptions([]);
              clearSelectedOption?.();
              onClose();
            }}
          >
            Xóa tất cả
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuOptionGroup>
          {options.map(option => {
            return (
              <MenuItem
                key={`multiselect-menu-${option.value}`}
                onClick={() => handleSelectOption(option)}
                type="button"
                value={option.value}
                isChecked={true}
                bg={`${!selectedOptions.find(selectedOption => selectedOption?.value === option?.value) ? 'transparent' : '#E8E8E8'}`}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default MultiSelectMenu;
