import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, CampaignStatusOption, CampaignTypeOption, DeviceOption, MessageApi, initialFilter } from 'utils/constant';
import Row from './components/Row';
import { ModalType } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
import UpdateStatusCampaignModal from './components/UpdateStatusCampaignModal';
import AssignWebAdsModal from './components/AssignWebAdsModal';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import DetailCampaign from './components/DetailCampaign';

const InitFilter = { ...initialFilter, status: '', device: '', country: '', type: '', searchKeyword: '' };

const Campaign = () => {
  const toast = useToast();
  const [filter, setFilter] = useState(InitFilter);
  // const [campaigns, setCampaign] = useState([]);
  const [campaignDetail, setCampaignDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenUpdateStatusModal, onOpen: onOpenUpdateStatusModal, onClose: onCloseUpdateStatusModal } = useDisclosure();
  const { isOpen: isAssignModal, onOpen: onOpenAssignModal, onClose: onCloseAssignModal } = useDisclosure();
  const { isOpen: isDetailModal, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onOpenUpdateStatusModal,
      [ModalType.Assign]: onOpenAssignModal,
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenUpdateStatusModal, onOpenAssignModal, onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.ChangeStatus]: onCloseUpdateStatusModal,
      [ModalType.Assign]: onCloseAssignModal,
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseUpdateStatusModal, onCloseAssignModal, onCloseDetailModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.Campaign,
      params: { ...InitFilter },
    }
    // { manual: true }
  );

  const [{ data: countries }] = useAxios({
    url: API_ROUTES.Countries,
  });

  // useEffect(() => {
  //   refetch({
  //     params: { ...filter },
  //   })
  //     .then(res => {
  //       setCampaign(res?.data?.data);
  //     })
  //     .catch(error => {
  //       toast({
  //         title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     });
  // }, []);

  const handelUpdateUser = (campaignDetail, modalType) => {
    setCampaignDetail(campaignDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setCampaignDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, pageIndex: 0 },
    })
      .then(res => {
        // setCampaign(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const onReset = () => {
    setFilter(InitFilter);
    refetch();
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onChangeFilter = event => {
    event.persist();
    const name = event?.target?.name;

    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: event?.target?.value,
    }));
  };

  // const onChangeDate = type => date => {
  //   setFilter(prev => ({
  //     ...prev,
  //     ...(type === 'date' && { date: new Date(formatDate(moment(date).add(6, 'days'))) }),
  //     [type]: date,
  //   }));
  // };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };
  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="end" flexWrap="wrap" gap={'12px'}>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Tìm kiếm
              </Text>
              <InputSearch
                value={filter?.searchKeyword}
                placeholder="Tìm kiếm tên chiến dịch, nhóm chiến dịch"
                onChange={onChangeSearch}
                name="searchKeyword"
                onClearSearch={onClearSearch}
              />
            </Flex>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Trạng thái
              </Text>
              <Select maxW="300px" placeholder="Chọn trạng thái" name="status" value={filter.status} onChange={onChangeFilter}>
                {CampaignStatusOption?.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Thiết bị
              </Text>
              <Select maxW="300px" placeholder="Chọn thiết bị" name="device" value={filter.device} onChange={onChangeFilter}>
                {DeviceOption?.map((device, index) => (
                  <option key={index} value={device.value}>
                    {device.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Quốc gia
              </Text>
              <Select maxW="300px" placeholder="Chọn quốc gia" name="country" value={filter.country} onChange={onChangeFilter}>
                {mappingOptionSelect(countries?.data, 'name', 'code')?.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Loại chiến dịch
              </Text>
              <Select maxW="300px" placeholder="Chọn loại" name="type" value={filter.type} onChange={onChangeFilter}>
                {CampaignTypeOption?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </Flex>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Từ ngày
                </FormLabel>
                <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Đến ngày
                </FormLabel>
                <DatePicker selectedDate={filter.endDate} minDate={filter.startDate} onChange={date => onChangeDate('endDate')(date)} />
              </FormControl>
              <Button w="100px" maxH="40px" fontSize="12px" onClick={onReset} alignSelf={'end'}>
                Đặt lại
              </Button>
              <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={onFilter} alignSelf={'end'}>
                Lọc
              </Button>
            <Flex alignItems="center" flexWrap="wrap" gap={'12px'}>
            </Flex>
          </Flex>
          <Flex pt="22px" alignItems="center" flexWrap="wrap" gap={'12px'}></Flex>
        </CardHeader>
        <CardBody>
          <Box overflowX={isEmpty(data?.data) && !loading ? 'unset' : 'scroll'} className="custom-scrollbar-track">
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    Tên chiến dịch
                  </Th>
                  {/* <Th borderColor={borderColor} color="gray.400">
                      Danh mục chiến dịch
                    </Th> */}
                  <Th borderColor={borderColor} color="gray.400">
                    Loại chiến dịch
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Nhóm chiến dịch
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Người tạo
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Chi phí 1 lần click
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Chi phí 1 lượt chuyển đổi
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Trạng thái
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Số dư
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Ngày tạo
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Thao tác
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data?.map((row, index, arr) => {
                  return (
                    <Row
                      key={row._id}
                      campaignDetail={row}
                      isLast={index === arr.length - 1 ? true : false}
                      handelUpdateUser={handelUpdateUser}
                    />
                  );
                })}
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="9">
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          {!isEmpty(data?.data) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={data?.pagination?.page}
                pageLength={data?.pagination?.pageSize}
                totalRecords={data?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  refetch({
                    params: { pageSize: pageLength, pageIndex: page - 1 },
                  }).then(res => {
                    // setCampaign(res?.data?.data);
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
      {isOpenUpdateStatusModal && (
        <UpdateStatusCampaignModal
          campaignDetail={campaignDetail}
          isOpen={isOpenUpdateStatusModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {isAssignModal && (
        <AssignWebAdsModal
          campaignDetail={campaignDetail}
          isOpen={isAssignModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {isDetailModal && <DetailCampaign campaignDetail={campaignDetail} isOpen={isDetailModal} onClose={handelCloseModal} />}
    </>
  );
};

export default Campaign;
