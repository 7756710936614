import {
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialog,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi } from 'utils/constant';

const UpdateSiteCategory = ({ isOpen, name, id, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [nameCategory, setNameCategory] = useState(name);
  const updateWebsiteCategory = async () => {
    const dataCategory = {
      name: nameCategory,
      categoryId: id,
    };
    try {
      const response = await axiosPost(API_ROUTES.UpdateCategory, dataCategory);
      if (response.data.code === 0) {
        toast({
          title: 'Chỉnh sửa danh mục trang web thành công.',
          status: 'success',
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: MessageApi?.[response.data.msg] || 'Chỉnh sửa danh mục trang web thất bại.',
          status: 'error',
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors[0]?.msg] || MessageApi?.[error?.response?.data?.msg] || 'Chỉnh sửa danh mục trang web thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Chỉnh sửa danh mục</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl>
              <FormLabel>Tên</FormLabel>
              <Input type="text" placeholder="Nhập tên" value={nameCategory} onChange={e => setNameCategory(e.target.value)} />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose}>Hủy</Button>
            <Button colorScheme="blue" ml={3} onClick={updateWebsiteCategory}>
              Chỉnh sửa
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateSiteCategory;
