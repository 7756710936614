import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import useAxios from 'axios-hooks';
import { API_ROUTES, MessageApi, ModalType, TicketStatusOption } from 'utils/constant';
import { AssignTicketValidate } from 'utils/validation';

const StatusTicketForm = ({ ticketDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading }, changeStatusApi] = useAxios(
    {
      url: API_ROUTES.ChangeStatusTicket(ticketDetail?._id),
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      status: undefined,
    },
  });

  useEffect(() => {
    reset({ status: TicketStatusOption.find(item => item.value === ticketDetail.status) });
  }, [ticketDetail]);

  const handleSuccess = () => {
    toast({
      title: 'Cập nhật trạng thái ticket thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetch?.();
    onClose();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Cập nhật trạng thái ticket thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    changeStatusApi({ data: { status: values.status?.value } })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box>
      <form>
        <SelectController control={control} name="status" label="Trạng thái" isRequired options={TicketStatusOption} />
      </form>
      <Flex mt={"16px"} justifyContent={"end"}>
      {/* <Button
          ref={cancelRef}
          onClick={() => onClose()}
        >
          Hủy
        </Button> */}
        <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
          Câp nhật
        </Button>
      </Flex>
       
    </Box>
  );
};

export default StatusTicketForm;
