import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { ModalType } from 'utils/constant';
import moment from 'moment';


const Detail = ({ isOpen, link, onClose }) => {
  const cancelRef = React.useRef();
  const handleCloseModal = () => onClose?.(ModalType.Detail);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxWidth={'lg'}>
          <AlertDialogHeader textTransform="uppercase">Thông tin liên kết</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={'16px'}>
            <Wrap w={'100%'}>
              <Grid w={'100%'} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w={'100%'}>
                  <Box>
                    <Text pb="12px" fontSize="lg">
                      <strong>Tên:</strong> {link?.name}
                    </Text>
                    <Text pb="12px" fontSize="lg">
                      <strong>Danh mục:</strong> {link?.category?.name}
                    </Text>
                    <Text pb="12px" fontSize="lg">
                      <strong>Người tạo:</strong> {link?.createdBy?.username}
                    </Text>
                    <Text pb="12px" fontSize="lg">
                      <strong>Trạng thái:</strong> {link?.status ? "Kích hoạt" : "Chưa kích hoạt"}
                    </Text>
                    <Text pb="12px" fontSize="lg">
                      <strong>Ngày tạo:</strong> {moment(link?.createdAt).format('DD-MM-YYYY')}
                    </Text>
                    <Text pb="12px" fontSize="lg">
                      <strong>Ngày cập nhật:</strong> {moment(link?.updatedAt).format('DD-MM-YYYY')}
                    </Text>
                  </Box>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Detail;
