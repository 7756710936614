import React, { useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Stack, Switch, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
// import Pagination from 'components/Pagination/Pagination';
// import TicketTable from './components/Table';
// import { isEmpty } from 'lodash';
import DatePicker from 'components/DatePicker/DatePicker';
import InputSearch from 'components/InputSearch/InputSearch';
import { API_ROUTES } from 'utils/constant';
import useAxios from 'axios-hooks';
import Pagination from 'components/Pagination/Pagination';
import TicketTable from 'views/Dashboard/Ticket/components/TicketTable';
import { isEmpty } from 'lodash';
import { formatDate } from 'utils/helpers';
import DepositeTable from './components/Table';
// import { useQueryGetMyHistoryTransactions } from 'services/transaction';


export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function Deposit() {
  const [filter, setFilter] = useState(initialFilter);
  // const [ticketType, setTicketType] = useState(null);
  // console.log(ticketType, 'ticketType')
  // const [switched, setSwitched] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [{ data }] = useAxios({
    url: API_ROUTES.ListTrasactions,
    params: filter,
  });
  const { data: historyData, pagination } = data || {};

  const onFilter = () => {
    setFilter({
      ...filter,
      start: filterDate.start ? formatDate(filterDate.start, 'YYYY-MM-DD') : undefined,
      end: filterDate.end ? formatDate(filterDate.end, 'YYYY-MM-DD') : undefined,
      // ...filterDate,
      searchKeyword,
    });
  };

  const onReset = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
    setFilterDate({ start: null, end: null });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };
  

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              {/* <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Ticket
                </Text>
              </Flex> */}
              <Flex justifyContent={'start'} alignItems={'end'} gap={'20px'}>
                  <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                    <FormLabel fontWeight={600} fontSize={14}>
                      Tìm kiếm
                    </FormLabel>
                    <InputSearch
                      width={'300px'}
                      placeholder="Tìm kiếm"
                      value={searchKeyword}
                      onChange={onChangeSearch}
                      onClearSearch={onClearSearch}
                    />
                  </FormControl>
                  {/* <FormControl minWidth={'230px'} maxW="250px">
                    <FormLabel fontWeight={600} fontSize={14}>
                      Trạng thái
                    </FormLabel>
                    <Select
                      isClearable
                      value={status}
                      onChange={e => {
                        setStatus(e);
                      }}
                      options={StatusWithDrawOptions}
                      placeholder="Chọn"
                    />
                  </FormControl> */}
                </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
                <Stack>
                  <Flex flexWrap="wrap" marginTop={4} gap={'20px'}>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel fontWeight={600} m="0">Từ ngày</FormLabel>
                      <DatePicker selectedDate={filterDate.start} onChange={date => onChangeDate('start')(date)} />
                    </FormControl>
                    <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                      <FormLabel fontWeight={600} m="0">Đến ngày</FormLabel>
                      <DatePicker
                        selectedDate={filterDate.end}
                        minDate={filterDate.start}
                        onChange={date => onChangeDate('end')(date)}
                      />
                    </FormControl>
                    <Button
                      backgroundColor="white"
                      color="blue.400"
                      border="1px"
                      _hover={{
                        text: 'white',
                      }}
                      maxH="60px"
                      mx="0px"
                      fontSize="14px"
                      alignSelf={'end'}
                      px={8}
                      onClick={onReset}
                    >
                      Đặt lại
                    </Button>
                    <Button variant="primary" minW={100} maxW={150} maxH="60px" fontSize="14px" onClick={onFilter} alignSelf={'end'}>
                      Lọc
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack overflow={'auto'}>
            <DepositeTable data={historyData || []} />
          </Stack>
          {!isEmpty(historyData) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    
    </Flex>
  );
}

export default Deposit;