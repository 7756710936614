import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { currencyFormat, formatDate } from 'utils/helpers';
import isEmpty from 'lodash/isEmpty';
import { ModalType, STATUS_WITHDRAW } from 'utils/constant';
import { BiCommentDetail } from 'react-icons/bi';


const WithDrawTable = ({ data ,handleChangeStatus}) => {
  const textColor = useColorModeValue('gray.500', 'white');
  const toast = useToast();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const handleRowClick = (item, type) => {
    handleChangeStatus(item,type)
  };
  const columns = useMemo(
    () => [
      columnHelper.accessor('order_no', {
        header: 'Mã giao dịch',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('username', {
        header: 'Tên người dùng',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('bank_code', {
        header: 'Ngân hàng',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('bank_number', {
        header: 'Số tài khoản NH',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('beneficiary_name', {
        header: 'Tên tài khoản NH',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('channel', {
        header: 'Kênh giao dịch',
        cell: info => (info.getValue() || '--'),
      }),
      columnHelper.accessor('amount', {
        header: 'Số tiền giao dịch',
        cell: info => currencyFormat(info.getValue()),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: (info) => {
          const status = info.getValue();
          let color = textColor; // default color
      
          if (status === STATUS_WITHDRAW.APPROVED || status===STATUS_WITHDRAW.SUCCESS) {
            color = 'green.500';
          } else if (status === STATUS_WITHDRAW.REJECTED) {
            color = 'red.500';
          }
      
          return (
            <Text fontSize="md" color={color} fontWeight="bold">
              {status === STATUS_WITHDRAW.APPROVED ? 'ĐÃ DUYỆT' : status === STATUS_WITHDRAW.REJECTED ? 'TỪ CHỐI' : status === STATUS_WITHDRAW.SUCCESS ? 'THÀNH CÔNG':'CHỜ DUYỆT'}
            </Text>
          );
        },
      }),
      columnHelper.accessor('updatedAt', {
        id: 'createdAt',
        header: 'Thời gian rút',
        cell: info => formatDate(info.getValue(), 'DD-MM-YYYY HH:mm A'),
        footer: info => info.column.id,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <IconButton
              p={2}
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.ChangeStatus);
              }}
            >
              <BiCommentDetail />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [data]
  );

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box overflowX={isEmpty(table.getRowModel().rows) ? 'unset' : 'auto'} className="custom-scrollbar-track">
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box
                      isTruncated
                      // cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                      // onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null} */}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={8}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                  Không có dữ liệu
                </Box>
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default WithDrawTable;
