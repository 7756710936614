import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Button,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { API_ROUTES, MessageApi, ModalType, TypeTicket } from 'utils/constant';
import isEmpty from 'lodash/isEmpty';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { TicketFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosPost } from 'utils/api';
import { mappingOptionSelect } from 'utils/mapping';

function RegisterTicketModal({ isOpen, onClose, categories, editTicketDetail, refetch }) {
  const cancelRef = React.useRef();
  const toast = useToast();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(TicketFormValidate),
    defaultValues: {
      subject: editTicketDetail?.subject || "",
      content: editTicketDetail?.content || "",
      type: categories?.find(item => item.value === editTicketDetail?.type?._id) || undefined,
    },
  });

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      type: values?.type?.value
    }
    try {
      let response;
      response = await axiosPost(API_ROUTES.TicketForManager, payload);
      if (response.data.code === 0) {
        toast({
          title: 'Tạo thông tin ticket thành công.',
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        onClose?.(ModalType.RegisterTicket);
        refetch();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Tạo thông tin ticket thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose?.(ModalType.RegisterTicket);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW="500px" maxH="600px">
        <AlertDialogHeader>Tạo Thông tin Ticket</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Loại"
              placeholder="Chọn"
              isRequired
              options={categories || []}
            />
            <InputController
              styleContainer={{ pt: "4" }}
              control={control}
              name="subject"
              label="Chủ đề"
              isRequired
            />
            <InputController
              type="textarea"
              styleContainer={{ pt: "4" }}
              control={control}
              name="content"
              label="Nội dung"
              isRequired
              rows={4}
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => onClose?.(ModalType.RegisterTicket)}>
            Hủy
          </Button>
          {isEmpty(editTicketDetail) &&
            <Button colorScheme="red" ml={3} onClick={handleSubmit(onSubmit)}>
              Tạo
            </Button>}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default RegisterTicketModal;
