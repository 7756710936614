import React, { useState } from 'react';
import { Box, Button, Flex, FormControl, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import isEmpty from 'lodash/isEmpty';
import InputSearch from 'components/InputSearch/InputSearch';
import Row from './components/Row';
import { TablePagination } from '@trendmicro/react-paginations';
import AddPage from './components/AddPage';
import Loading from 'components/Layout/Loading';

function CreateSocial() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(initialFilter);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [{ data, loading }, refetch] = useAxios({
    url: `${API_ROUTES.Sites}/pages`,
    params: filter,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageEdit, setPageEdit] = useState();

  const handleSearchKeywordChange = e => {
    setSearchKeyword(e.target.value);
  };

  const handleSearch = () => {
    setFilter({ searchKeyword, ...initialFilter });
  };

  const resetSearchKeywords = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const handleAddRecord = () => {
    onOpen();
  };

  const handleGetPage = page => {
    setPageEdit(page);
    onOpen();
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card pb="0px">
        <CardHeader p="6px 0px">
          <Text fontSize={24} fontWeight={600}>
            Tạo Social
          </Text>
          <FormControl mt="2px" display="flex" alignItems="center">
            <InputSearch value={searchKeyword} onClearSearch={resetSearchKeywords} onChange={handleSearchKeywordChange} />
            <Flex>
              <Button w="100px" variant="primary" maxH="40px" m="10px" fontSize="12px" fontWeight="bolder" onClick={handleSearch}>
                Tìm kiếm
              </Button>
              <Button w="100px" variant="primary" maxH="40px" m="10px" fontSize="12px" onClick={resetSearchKeywords}>
                Đặt lại
              </Button>
            </Flex>
          </FormControl>
          <Button w="100px" variant="primary" maxH="40px" my="10px" fontSize="12px" fontWeight="bolder" onClick={handleAddRecord}>
            Tạo mới
          </Button>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table variant="simple" color={textColor} mt={10}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pr="100px" borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tiêu đề
                    </Th>
                    <Th borderColor={borderColor} color="gray.400"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map(row => (
                    <Row
                      categoryData={row.categoryId}
                      key={row._id}
                      adUnit={row.adUnit}
                      creator={row.publisherId}
                      row={data?.data}
                      id={row?._id}
                      url={row.url}
                      name={row.name}
                      status={row.status}
                      title={row?.post?.title}
                      removeWebsites={row.removeWebsites}
                      postId={row?.post?._id}
                      refetch={refetch}
                      handleEditPage={handleGetPage}
                    />
                  ))}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="8">
                        <Box textAlign="center" height="200px" pt="24px">
                          No data
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              {!isEmpty(data?.data) && (
                <Flex justifyContent="flex-end">
                  <TablePagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      refetch({ params: { ...filter, pageSize: pageLength, pageIndex: page - 1 } });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              )}
            </>
          )}
        </CardBody>
      </Card>
      <AddPage isOpen={isOpen} onClose={onClose} refetch={refetch} pageEdit={pageEdit} setPageEdit={setPageEdit} />
    </Flex>
  );
}

export default CreateSocial;
