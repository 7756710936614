import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormLabel, Input as InputComponent, InputGroup, InputRightElement, Textarea } from '@chakra-ui/react';

const InputController = ({ type = 'text', name, label, control, styleContainer, styleBoxInput, isRequired, disabled, styleLabel, onChange, inputRightElement, placeholder, readOnly = false, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
        {label && <FormLabel {...styleLabel} minW="150px">{label}</FormLabel>}
        <Box {...styleBoxInput}>
          {
            type === 'textarea' ? (
              <Textarea
                {...field}
                {...props}
                type={type}
                className="form-control"
                disabled={disabled}
                onChange={e => {
                  onChange?.(e);
                  field.onChange(e);
                }}
              />
            ) : (
              <InputGroup outline={"none"}>
                <InputComponent
                  {...field}
                  {...props}
                  readOnly={readOnly}
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  onChange={e => {
                    onChange?.(e);
                    field.onChange(e);
                  }}

                />
                {inputRightElement && <InputRightElement>{inputRightElement}</InputRightElement>}
              </InputGroup>
            )
          }

          <FormErrorMessage>{error && error?.message}</FormErrorMessage>
        </Box>
      </FormControl>
    )}
  />
);

export default InputController;
