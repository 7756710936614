import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, MessageApi, initialFilter } from 'utils/constant';
// import CreateUserModal from './components/CreateUserModal';
// import CreateUserModal from './components/UserInformationModal';
import Row from './components/Row';
import { mappingUsers } from 'utils/mapping';
import { RoleOption } from 'utils/constant';
import { ModalType } from 'utils/constant';
import ResetPassUserModal from './components/ResetPasswordUserModal';
import InputSearch from 'components/InputSearch/InputSearch';
import BlockUserModal from './components/BlockUserModal';
import DetailInfoModal from './components/DetailModal';
import Loading from 'components/Layout/Loading';
import UserInformationModal from './components/UserInformationModal';
import CreateUser from './components/CreateUser';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const RoleOptionFilter = [
  {
    label: 'Tất cả',
    value: 'all',
  },
  ...RoleOption,
];

const User = () => {
  const toast = useToast();
  const [filter, setFilter] = useState({
    ...initialFilter,
    role: 'all',
  });
  const [users, setUsers] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();
  const { isOpen: isOpenBlockModal, onOpen: onOpenBlockModal, onClose: onCloseBlockModal } = useDisclosure();
  const { isOpen: isOpenResetPasswordModal, onOpen: onOpenResetPasswordModal, onClose: onCloseResetPasswordModal } = useDisclosure();
  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const { userInfo } = useUserState();
  
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenAddModal,
      [ModalType.Block]: onOpenBlockModal,
      [ModalType.ResetPassword]: onOpenResetPasswordModal,
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenAddModal, onOpenBlockModal, onOpenResetPasswordModal, onOpenDetailModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseAddModal,
      [ModalType.Block]: onCloseBlockModal,
      [ModalType.ResetPassword]: onCloseResetPasswordModal,
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseAddModal, onCloseBlockModal, onCloseResetPasswordModal, onCloseDetailModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.Users,
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    },
    { manual: true, useCache: false }
  );

  useEffect(() => {
    const params = {
      ...filter,
      role: filter?.role !== 'all' ? filter?.role : '',
    };
    handleRefetchData(params);
    // refetch({
    //   params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    // })
    //   .then(res => {
    //     setUsers(mappingUsers(res?.data?.data));
    //   })
    //   .catch(error => {
    //     toast({
    //       title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Lấy danh sách không thành công',
    //       status: 'error',
    //       duration: 9000,
    //       isClosable: true,
    //     });
    //   });
  }, []);

  const handelUpdateUser = (userDetail, modalType) => {
    setUserDetail(userDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setUserDetail(null);
  };

  const handleRefetchData = paramFilter => {
    refetch({
      params: { ...paramFilter, role: paramFilter?.role !== 'all' ? paramFilter?.role : '' },
    })
      .then(res => {
        setUsers(mappingUsers(res?.data?.data));
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = params => {
    handleRefetchData(params);
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="0px 0px 22px 0px">
          <Flex flexWrap="wrap" justifyContent={'space-between'} alignItems={"end"}>
            <Flex flexDirection={'row'} gap={4}>
              <Flex flexDirection={'column'}>
                <Text mb={1} fontSize="sm" fontWeight={600} color={textColor}>
                  Tìm kiếm theo từ khóa
                </Text>
                <InputSearch
                  width={'300px'}
                  placeholder="Tìm kiếm username, email"
                  value={filter?.searchKeyword}
                  onChange={onChangeSearch}
                  onClearSearch={onClearSearch}
                />
              </Flex>
              <Button alignSelf="end" variant="primary" fontSize="12px" onClick={() => onFilter(filter)}>
                Lọc
              </Button>
            </Flex>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_ADMIN'}>
              <Button variant="primary" fontSize="12px" onClick={onOpenAddModal}>
                Thêm
              </Button>
            </AuthorizationComponentWrapper>
          </Flex>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <Loading />
            </Flex>
          ) : (
            <>
              <Box width={'100%'} overflowX={isEmpty(users) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th pl="0px" borderColor={borderColor} color="gray.400">
                        Tài khoản
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Email
                      </Th>
                      <Th borderColor={borderColor} isTruncated color="gray.400">
                        Chức vụ
                      </Th>
                      <Th borderColor={borderColor} isTruncated color="gray.400">
                        Trạng thái
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Ngày tạo
                      </Th>
                      <Th borderColor={borderColor}>Thao tác</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {users?.map((row, index, arr) => {
                      return (
                        <Row
                          key={row.id}
                          user={row}
                          isLast={index === arr.length - 1 ? true : false}
                          handelUpdateUser={handelUpdateUser}
                          refetch={() => {
                            handleRefetchData();
                          }}
                        />
                      );
                    })}
                    {isEmpty(users) && !loading && (
                      <Tr>
                        <Td colSpan="4">
                          <Box textAlign="center" height="200px" pt="24px">
                            Không có dữ liệu
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Box>
              {!isEmpty(users) && (
                <Flex justifyContent={'flex-end'}>
                  <Pagination
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      refetch({
                        params: { role: filter?.role !== 'all' ? filter?.role : '', pageSize: pageLength, pageIndex: page - 1 },
                      }).then(res => {
                        setUsers(mappingUsers(res?.data?.data));
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      });
                    }}
                  />
                </Flex>
              )}
            </>
          )}
        </CardBody>
      </Card>

      {isDetailModalOpen && (
        <UserInformationModal
          userDetail={userDetail}
          isOpen={isDetailModalOpen}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )}
      {isOpenAddModal && <CreateUser isOpen={isOpenAddModal} onClose={handelCloseModal} refetchData={handleRefetchData} />}
      {/* {isOpenAddModal && (
        <CreateUserModal userDetail={userDetail} isOpen={isOpenAddModal} onClose={handelCloseModal} refetchData={handleRefetchData} />
      )}
      {isOpenBlockModal && (
        <BlockUserModal userDetail={userDetail} isOpen={isOpenBlockModal} onClose={handelCloseModal} refetchData={handleRefetchData} />
      )}
      <ResetPassUserModal
        userDetail={userDetail}
        isOpen={isOpenResetPasswordModal}
        onClose={handelCloseModal}
        refetchData={handleRefetchData}
      />
      {isDetailModalOpen && <DetailInfoModal infor={userDetail} isOpen={isDetailModalOpen} onClose={handelCloseModal} />} */}
    </>
  );
};

export default User;
