import { Th, Tr, useColorModeValue } from "@chakra-ui/react";

const Header = () => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Tr my=".8rem" pl="0px" color="gray.400">
      <Th pl="0px" borderColor={borderColor} color="gray.400" isTruncated>
        Tài khoản
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Email
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Thông tin liên lạc
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Kênh liên lạc
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Trạng thái
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Số dư
      </Th>
      <Th borderColor={borderColor} color="gray.400" isTruncated>
        Ngày tạo
      </Th>
    </Tr>
  )
};

export default Header;