import { Box, Flex, IconButton, Table, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { EditIcon } from '@chakra-ui/icons';
import { formatDate } from 'utils/helpers';
import isEmpty from 'lodash/isEmpty';
import { HiOutlineArchiveBoxArrowDown } from 'react-icons/hi2';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import { API_ROUTES, MessageApi, ModalType } from 'utils/constant';
import useAxios from 'axios-hooks';
import { BiCommentDetail } from 'react-icons/bi';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const TicketTable = ({ tickets, handelUpdateTicket, refetch }) => {
  const toast = useToast();
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const {userInfo} = useUserState();

  const [{ loading }, closeTicketApi] = useAxios(
    {
      method: 'post',
    },
    { manual: true }
  );

  const handleRowClick = (ticket, type) => {
    handelUpdateTicket(ticket, type);
  };

  const handleCloseTicket = async ticket => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn đóng ticket không ?');
    if (!confirmDelete) {
      return;
    }

    closeTicketApi({ data: undefined, url: API_ROUTES.CloseTicket(ticket?._id) })
      .then(() => {
        toast({
          status: 'success',
          title: 'Đóng ticket thành công.',
          duration: 9000,
        });
        refetch?.();
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Đóng ticket thất bại.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('user', {
        header: 'Người xử lý',
        cell: info => info.getValue()?.username || '--',
      }),
      columnHelper.accessor('advertiser', {
        header: 'Nhà quảng cáo',
        cell: info => info.getValue()?.username || '--',
      }),
      columnHelper.accessor('publisher', {
        header: 'Nhà xuất bản',
        cell: info => info.getValue()?.username || '--',
      }),
      columnHelper.accessor('type', {
        header: 'Loại ticket',
        cell: info => info.getValue()?.name || '--',
      }),
      columnHelper.accessor('subject', {
        header: () => 'Tiêu đề',
        cell: info => info.renderValue(),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => <Box textTransform="capitalize">{info.renderValue()}</Box>,
        footer: <span>{0}</span>,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Box minW="90px">{formatDate(info.getValue(), 'DD-MM-YYYY')}</Box>,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Ngày cập nhật',
        cell: info => <Box minW="90px">{formatDate(info.getValue(), 'DD-MM-YYYY')}</Box>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            {/* <IconButton
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.ChangeStatus);
              }}
            >
              <EditIcon cursor="pointer" boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.Assign);
              }}
            >
              <MdAssignmentTurnedIn size={20} />
            </IconButton> */}
            <IconButton
              p={2}
              bg="transparent"
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.Detail);
              }}
            >
              <BiCommentDetail />
            </IconButton>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CLOSE_TICKET'}>
              <IconButton
                bg="transparent"
                onClick={() => {
                  handleCloseTicket(info?.row?.original);
                }}
              >
                <HiOutlineArchiveBoxArrowDown size={20} />
              </IconButton>
            </AuthorizationComponentWrapper>

          </Flex>
        ),
      }),
    ],
    [tickets]
  );

  const table = useReactTable({
    data: tickets || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box overflowX={isEmpty(table.getRowModel().rows) ? 'unset' : 'auto'} className="custom-scrollbar-track">
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th key={header.id} w="120px">
                  {header.isPlaceholder ? null : (
                    <Box
                      isTruncated
                      // cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                      // onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {/* {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null} */}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={8}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                  Không có dữ liệu
                </Box>
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default TicketTable;