import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  FormControl,
  Td,
  Box,
  Select,
  FormLabel,
} from '@chakra-ui/react';
import InputSearch from 'components/InputSearch/InputSearch';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TablePagination } from '@trendmicro/react-paginations';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { isEmpty } from 'lodash';
import DatePicker from 'components/DatePicker/DatePicker';

const InitFilter = {
  ...initialFilter,
  searchKeyword: '',
  categoryId: '',
  status: '',
  createdId: '',
  searchPublisher: '',
  // startDate: new Date(),
  // endDate: new Date()
};

function DirectLink() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(InitFilter);
  // const [searchKeyword, setSearchKeyword] = useState('');
  // console.log(filter, 'filter');
  const [{ data, loading }, refetchData] = useAxios(
    {
      url: API_ROUTES.ListDirectLink,
      params: InitFilter,
    },
    {
      // manual: true,
    }
  );
  const [{ data: websiteCategory }] = useAxios(
    {
      url: API_ROUTES.ListWebsiteCate,
    },
    {
      useCache: false,
    }
  );
  // const [{ data: publishers }] = useAxios({
  //   url: API_ROUTES.Publisher,
  // });
  // console.log(publishers, 'publishers');

  useEffect(() => {
    refetchData({ params: { ...filter } });
  }, []);

  const onFilter = () => {
    refetchData({
      params: {
        ...filter,
        pageIndex: 0,
        // startDate: moment(filter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.000Z',
        // endDate: moment(filter.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
      },
    });
  };

  const resetSearchKeywords = () => {
    setFilter(InitFilter);
    refetchData()
  };

  const onChangeFilter = event => {
    event.persist();
    const name = event?.target?.name;

    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: event?.target?.value,
    }));
  };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text fontSize="xl" color={textColor} fontWeight="bold" mb={4}>
            Liên kết
          </Text> */}
          <FormControl mt="2px" display="flex" alignItems="center" flexWrap="wrap" gap={4}>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Tìm kiếm từ khóa
              </Text>
              <InputSearch
                width={{ base: 'full', sm: '300px' }} maxW="300px"
                placeholder="Tìm kiếm tên"
                value={filter.searchKeyword}
                name="searchKeyword"
                mr="0"
                onChange={onChangeFilter}
                onClearSearch={() => {
                  setFilter({
                    ...filter,
                    searchKeyword: '',
                  });
                }}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Danh mục
              </Text>
              <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn danh mục" name="categoryId" value={filter.categoryId} onChange={onChangeFilter}>
                {websiteCategory?.data?.map((cate, index) => (
                  <option key={index} value={cate?._id}>
                    {cate.name}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Tìm kiếm người tạo
              </Text>
              <InputSearch
                width="300px"
                placeholder="Tìm kiếm người tạo"
                name="searchPublisher"
                value={filter?.searchPublisher}
                mr="0"
                onChange={onChangeFilter}
                onClearSearch={() =>
                  setFilter({
                    ...filter,
                    searchPublisher: '',
                  })
                }
              />
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Trạng thái
              </Text>
              <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn trạng thái" name="status" value={filter.status} onChange={onChangeFilter}>
                <option value={true}>Kích hoạt</option>
                <option value={false}>Chưa kích hoạt</option>
              </Select>
            </Flex>
            <Flex flexWrap="wrap" alignItems={'end'} marginTop={4} gap={'20px'}>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Từ ngày
                </FormLabel>
                <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Đến ngày
                </FormLabel>
                <DatePicker selectedDate={filter.endDate} minDate={filter.startDate} onChange={date => onChangeDate('endDate')(date)} />
              </FormControl>
              <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
                Đặt lại
              </Button>
              <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={onFilter}>
                Lọc
              </Button>
            </Flex>
          </FormControl>
        </CardHeader>
        <CardBody w="100%" overflowX="auto">
          {loading ? (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <Loading />
            </Flex>
          ) : (
            <Box width={'100%'} overflowX={isEmpty(data?.data) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pr="100px" borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th pr="100px" borderColor={borderColor} color="gray.400">
                      Danh mục
                    </Th>
                    <Th pr="100px" borderColor={borderColor} color="gray.400">
                      Người tạo
                    </Th>
                    <Th pr="100px" borderColor={borderColor} color="gray.400">
                      Trạng thái
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Ngày tạo
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Ngày cập nhật
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map(row => {
                    return (
                      <Row
                        row={row}
                        id={row._id}
                        category={row.category}
                        name={row.name}
                        status={row.status}
                        createdBy={row.createdBy}
                        day={moment(row.createdAt).format('YYYY-MM-DD')}
                        updatedAt={moment(row.updatedAt).format('YYYY-MM-DD')}
                        refetch={refetchData}
                        link={row}
                      />
                    );
                  })}
                </Tbody>
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="7">
                      <Box textAlign="center" height="200px" pt="24px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Table>
              {!isEmpty(data?.data) && (
                <Flex justifyContent="flex-end">
                  <Pagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      const params = {
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      };
                      refetchData({
                        params: { ...params },
                      }).then(res => {
                        // setAdvertiser(res?.data?.data);
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      });
                      // setFilter(params);
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              )}
            </Box>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DirectLink;
