import { Box, Button, Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { TablePagination } from '@trendmicro/react-paginations';
import moment from 'moment';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import AddWebSiteCategory from './components/AddGroupPermissionModal';
import Pagination from 'components/Pagination/Pagination';
import { isEmpty } from 'lodash';
import AddGroupPermissionModal from './components/AddGroupPermissionModal';

function GroupPermission() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(initialFilter);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ data, loading }, refetch] = useAxios({
    url: API_ROUTES.GroupPermission,
    params: filter,
  });
  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="0px 0px 22px 0px">
            {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
              Group permission
              Nhóm phân quyền
            </Text> */}
            <Button variant="primary" maxH="40px" fontSize="12px" onClick={onOpen}>
              Thêm
            </Button>
          </CardHeader>
          <CardBody w="100%" overflowX="auto">
            {loading ? (
              <Loading />
            ) : (
              <Box width={"100%"} overflowX={isEmpty(data?.data) && !loading ? "unset" : "auto"} className='custom-scrollbar-track'>
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th borderColor={borderColor} color="gray.400">
                        <Text>Tên</Text>
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Hiển thị
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Hiển thị tất cả
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Tạo
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Cập nhật
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Xóa
                      </Th>
                      <Th>Thao tác</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data?.map((item, index) => {
                      return <Row key={index} item={item} isLast={index === data?.data.length - 1 ? true : false} refetch={refetch} />;
                    })}
                  </Tbody>
                </Table>
                {!isEmpty(data?.data) && (
                  <Flex justifyContent="flex-end">
                    <Pagination
                      type="full"
                      page={data?.pagination?.page}
                      pageLength={data?.pagination?.pageSize}
                      totalRecords={data?.pagination?.count}
                      onPageChange={(page, pageLength) => {
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      }}
                      prevPageRenderer={() => <i className="fa fa-angle-left" />}
                      nextPageRenderer={() => <i className="fa fa-angle-right" />}
                    />
                  </Flex>
                )}
              </Box>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isOpen && <AddGroupPermissionModal isOpen={isOpen} refetchData={refetch} onOpen={onOpen} onClose={onClose} />}
    </>
  );
}

export default GroupPermission;
