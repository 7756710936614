import {
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialog,
  AlertDialogFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from 'components/Form/InputController';
import { API_ROUTES } from 'utils/constant';
import { SettingCommissionValidate } from 'utils/validation';
import { axiosPost } from 'utils/api';

const SettingCommissionModal = ({ isOpen, onClose, pageEdit, refetch, setPageEdit }) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SettingCommissionValidate),
    defaultValues: {
      commissionRate: '',
      commissionPerClick: '',
    },
  });

  const showAlert = message => {
    toast({
      title: message,
      duration: 9000,
      isClosable: true,
    });
  };

  const handleApiCall = async (apiRoute, postData, successMessage) => {
    try {
      const response = await axiosPost(apiRoute, postData);
      if (response.data.code === 0) {
        showAlert(successMessage);
        refetch();
        onClose?.();
      }
    } catch (error) {
      showAlert(error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Operation failed!');
    }
  };

  const onSubmit = async values => {
    const payload = {
      ...values,
    };
    await handleApiCall(API_ROUTES.SettingCommission, payload, 'Thiết lập hoa hồng thành công');
  };

  useEffect(() => {
    reset({
      commissionRate: pageEdit?.commissionRate,
      commissionPerClick: pageEdit?.commissionPerClick,
    });
  }, [pageEdit]);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setPageEdit(null);
        onClose();
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent minWidth={'60%'} marginY={4}>
        <AlertDialogHeader>Cài đặt thiết lập hoa hồng</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody width={'100%'}>
          <form>
            <InputController
              type="number"
              styleContainer={{ pb: 4 }}
              control={control}
              name="commissionRate"
              label="Tỉ lệ hoa hồng"
              isRequired
            />
            <InputController
              type="number"
              styleContainer={{ pb: 4 }}
              control={control}
              name="commissionPerClick"
              label="Số tiền hoa hồng/lần nhấp"
              isRequired
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              setPageEdit(null);
              onClose();
            }}
          >
            Hủy
          </Button>
          <Button
            ml={3}
            ref={cancelRef}
            onClick={() => {
              reset({
                commissionRate: '',
                commissionPerClick: '',
              });
            }}
          >
            Đặt lại
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
            Lưu
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SettingCommissionModal;
