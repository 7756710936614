import { Box, Button, FormControl, FormLabel, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import useAxios from "axios-hooks";
import InputController from "components/Form/InputController";
import SelectController from "components/Form/SelectController";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { API_ROUTES, PermissionValue, Roles, defaultPassword } from "utils/constant";


const FormRequest = (props) => {
  const { userDetail } = props;
  const {
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const [groupPermissionOption, setGroupPermissionOption] = useState([
    {
      label: 'Quản trị viên cấp cao',
      value: Roles.ADMIN,
    },
    {
      label: 'Quản trị viên',
      value: Roles.GUIDE,
    }
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [{ data: groupPermission }] = useAxios({
    url: API_ROUTES.GroupForUser,
  });

  useEffect(() => {
    if (!isEmpty(userDetail)) {
      reset({
        ...userDetail,
        role: groupPermissionOption?.find(item => item?.value === userDetail?.role),
      });
    }
  }, [userDetail, groupPermissionOption]);

  // useEffect(() => {
  //   if (!isEmpty(groupPermission?.data)) {
  //     setGroupPermissionOption(
  //       groupPermission?.data?.map(item => ({ label: PermissionValue[item?.groupName?.toUpperCase()], value: item?._id }))
  //     );
  //   }
  // }, [groupPermission?.data]);

  return (
    <Box>
      <InputController control={control} name="username" label="Tài khoản" isRequired styleContainer={{ pb: '4' }} />
      <InputController control={control} name="email" label="Email" isRequired styleContainer={{ pb: '4' }} />

      <FormControl pb={4}>
        <FormLabel>Mật khẩu</FormLabel>
        <InputGroup>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={defaultPassword}
          // onChange={(event) => setPassword(event.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? 'Ẩn' : 'Hiện'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <SelectController
        isRequired
        control={control}
        name="role"
        placeholder="Chọn chức vụ"
        label="Chức vụ"
        options={groupPermissionOption}
      />
    </Box>
  )
}

export default FormRequest;