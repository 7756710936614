import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { ManagerStatusLabel } from 'utils/constant';

function Detail({ publisherDetail }) {
  
  return (
    <Box>
      <Text pb="12px" fontSize="lg">
        <strong>Tài khoản:</strong> {publisherDetail?.username}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Email:</strong> {publisherDetail?.email}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Số điện thoại:</strong> {publisherDetail?.phone}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Số dư:</strong> {publisherDetail?.balance}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Trạng thái:</strong> {ManagerStatusLabel[publisherDetail?.status]}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Ngày tạo:</strong> {moment(publisherDetail?.createdAt).format('DD-MM-YYYY')}
      </Text>
    </Box>
  );
}
export default Detail;
