import React, { useEffect, useState } from 'react';
import { checkLogin } from '../../utils/authentication';
import { Button, Flex, FormControl, FormLabel, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import Card from 'components/Card/Card.js';
import { useHistory } from 'react-router-dom';
import BoxCard from 'components/BoxCard/BoxCard';
import { IoIosPeople } from 'react-icons/io';
import { RiAdvertisementFill } from 'react-icons/ri';
import { MdViewCozy, MdCampaign, MdPublishedWithChanges } from 'react-icons/md';
import { AiOutlineTransaction } from 'react-icons/ai';
import DatePicker from 'components/DatePicker/DatePicker';
import { useUserState } from 'context/UserContext';
import useAxios from 'axios-hooks';
import { API_ROUTES } from 'utils/constant';
import moment from 'moment';

export default function Dashboard() {
  const isLoggedIn = checkLogin();
  const history = useHistory();
  const { userInfo } = useUserState();
  const [statisticFilter, setStatisticFilter] = useState({});
  const [filter, setFilter] = useState();

  const [{ data: statistic, refetch }] = useAxios({
    url: API_ROUTES.Statistics,
    params: {
      ...filter,
    },
  });

  const onReset = () => {
    setFilter();
    setStatisticFilter();
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...(statisticFilter?.start && { start: moment(statisticFilter?.start).startOf('day').toISOString() }),
      ...(statisticFilter?.end && { end: moment(statisticFilter?.end).endOf('day').toISOString() }),
    });
  };

  const onChangeDate = type => date => {
    setStatisticFilter(prev => ({
      ...prev,
      [type]: date,
    }));
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/signin');
    }
  }, [isLoggedIn, history]);

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }} gap={4}>
        <Card minH="125px" bgColor="white">
          <Heading fontWeight={500} fontSize={20} color="blue.500" mb={4}>
            {/* Overview */}
            Số liệu thống kê
          </Heading>
          <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'} mb={10}>
            <Stack>
              <Flex alignItems={'end'} gap={'14px'} flexWrap={'wrap'}>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} gap={2}>
                  <FormLabel fontWeight={600} m="0">
                    Chọn ngày bắt đầu
                  </FormLabel>
                  <DatePicker selectedDate={statisticFilter?.start} onChange={date => onChangeDate('start')(date)} />
                </FormControl>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} gap={2}>
                  <FormLabel fontWeight={600} m="0">
                    Chọn ngày kết thúc
                  </FormLabel>
                  <DatePicker
                    minDate={statisticFilter?.start}
                    selectedDate={statisticFilter?.end}
                    onChange={date => onChangeDate('end')(date)}
                  />
                </FormControl>
                <Button w="100px" fontSize="12px" onClick={onReset}>
                  Đặt lại
                </Button>
                <Button w="100px" variant="primary" fontSize="12px" onClick={handleSearch}>
                  Lọc
                </Button>
              </Flex>
              {/* {(statisticFilter?.end) && (
                <Text fontSize={14}>
                  Kết quả tìm kiếm từ ngày {formatDate(statisticFilter?.start)} đến ngày {formatDate(statisticFilter?.end)}
                </Text>
              )} */}
            </Stack>
          </Flex>
          <Flex direction="column">
            <Flex paddingX={8} paddingY={5} rounded={10} backgroundColor="blue.500" flexDirection="row" align="center" w="100%" mb="25px">
              <Heading fontWeight={500} fontSize={18} color="white">
                Chào mừng bạn đến với AFFILIATE! {userInfo?.username}
              </Heading>
            </Flex>
          </Flex>
          <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(250px, 1fr))" flexWrap={'wrap'} mb={4}>
            <BoxCard
              // title="Total number of Advertisers members"
              title="Tổng số thành viên của Nhà quảng cáo"
              content={
                <Flex alignItems={'end'} gap={4}>
                  <RiAdvertisementFill size={30} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberAdv}
                  </Text>
                </Flex>
              }
            />
            <BoxCard
              // title="Total number of Publishers members"
              title="Tổng số thành viên của Nhà xuất bản"
              content={
                <Flex alignItems={'center'} gap={4}>
                  <MdPublishedWithChanges size={28} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberPbl}
                  </Text>
                </Flex>
              }
            />
            <BoxCard
              // title="Total number of advertising campaigns"
              title="Tổng số chiến dịch quảng cáo"
              content={
                <Flex alignItems={'center'} gap={4}>
                  <MdCampaign size={28} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberCampaign}
                  </Text>
                </Flex>
              }
            />
            <BoxCard
              // title="Total conversions"
              title="Tổng số chuyển đổi"
              content={
                <Flex alignItems={'center'} gap={4}>
                  <AiOutlineTransaction size={28} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberCpc}
                  </Text>
                </Flex>
              }
            />
            <BoxCard
              // title="
              title="Tổng số lần hiển thị"
              content={
                <Flex alignItems={'center'} gap={4}>
                  <IoIosPeople size={28} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberCpm}
                  </Text>
                </Flex>
              }
            />
            <BoxCard
              // title="Total number of tickets and reports"
              title="Tổng số phiếu và báo cáo"
              content={
                <Flex alignItems={'center'} gap={4}>
                  <MdViewCozy size={28} />
                  <Text fontWeight={600} fontSize={18}>
                    {statistic?.data?.numberTickets}
                  </Text>
                </Flex>
              }
            />
          </SimpleGrid>
        </Card>
      </Flex>
    </>
  );
}
