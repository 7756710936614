import { Box, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import TableBody from "./TableBody";
import { useState } from "react";

const TableTicketCategory = (props) => {
  const { data, loading, refetch, handleOpentModal } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Table variant="simple" color={textColor}>
      <Thead>
        <Tr my=".8rem" pl="0px" color="gray.400">
          <Th pl="0px" borderColor={borderColor} color="gray.400">
            Tên danh mục
          </Th>
          <Th borderColor={borderColor} color="gray.400">
            Thời gian tạo
          </Th>
          <Th borderColor={borderColor} color="gray.400">
            Thao tác
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data?.map((row, index, arr) => {
          return (
            <TableBody
              key={row._id}
              ticketCategory={row}
              isLast={index === arr.length - 1 ? true : false}
              refetch={refetch}
              handleOpentModal={handleOpentModal}
            />
          );
        })}
        {isEmpty(data) && !loading && (
          <Tr>
            <Td colSpan="9">
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign="center" height="200px">
                Không có dữ liệu
              </Box>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
};

export default TableTicketCategory;