import { Td, Text, Tr, useColorModeValue, useToast, IconButton, Flex } from '@chakra-ui/react';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import React from 'react';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { axiosPost } from 'utils/api';

function Row(props) {
  const { name, isLast, title, id, refetch, handleEditPage, postId } = props;
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const toast = useToast();

  const deleteWebSiteCategory = async () => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa?');
    if (!confirmDelete) {
      return;
    }
    const pageId = {
      pageId: id,
    };
    try {
      const response = await axiosPost(API_ROUTES.Sites + '/delete-page', pageId);
      if (response.data.code === 0) {
        toast({
          title: 'Xóa danh mục trang thành công.',
          duration: 9000,
          isClosable: true,
        });
        refetch();
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Xóa danh mục trang thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {title}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex justifyContent={'flex-end'}>
            <IconButton
              p={2}
              bg="transparent"
              onClick={() =>
                handleEditPage({
                  id,
                  title,
                  name,
                  postId,
                })
              }
            >
              <CiEdit />
            </IconButton>
            <IconButton p={2} bg="transparent" onClick={deleteWebSiteCategory}>
              <MdDelete />
            </IconButton>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default Row;
