import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';

function WebsiteInfo({ isOpen, name, id, removeWebsites, day, updatedAt, isApprove, adUnit, onClose, url, creator, status }) {
  const cancelRef = React.useRef();
  return (
    <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
      <AlertDialogOverlay />
      <AlertDialogContent maxW="600px" maxH="600px">
        <AlertDialogHeader>Thông tin đơn vị quảng cáo</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text pb="12px" fontSize="lg">
            <strong>Đường dẫn:</strong> {url}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Tên:</strong> {name}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>ID trang:</strong> {id}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Người tạo:</strong> {creator?.username || 'N/A'}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Đơn vị quảng cáo:</strong> {adUnit?.join(', ')}
          </Text>
          <FormControl width="300px" display="flex" gap="10px">
            <Text fontSize="lg">
              <strong>Trạng thái:</strong>
            </Text>
            <Checkbox pb="14px" isChecked={isApprove}>
              {status}
            </Checkbox>
          </FormControl>
          <Text pb="12px" fontSize="lg">
            <strong>Ngày tạo:</strong> {moment(day).format('DD-MM-YYYY')}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Ngày cập nhật:</strong> {moment(updatedAt).format('DD-MM-YYYY')}
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter></AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default WebsiteInfo;
