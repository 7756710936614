import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Box,
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Td,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { ManagerStatusLabel, ModalType, PermissionValue } from 'utils/constant';
import PermissionDetail from './PermissionDetail';

function DetailInfoModal({ infor }) {

  return (
    <Box w={"100%"} p={"2"}>
      <Text pb="12px" fontSize="lg">
        <strong>Vai trò:</strong> {infor?.role === 'guide' && 'Quản lý'}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Tài khoản:</strong> {infor?.username}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Email:</strong> {infor?.email}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Trạng thái:</strong> {ManagerStatusLabel[infor?.status] || 'Bình thường'}
      </Text>
      <Flex pb="12px" flexDirection="column" fontSize="lg">
        <strong>Phân quyền:&nbsp;</strong>
        {!isEmpty(infor?.groups) && <PermissionDetail data={uniqBy(infor?.groups, 'groupName')} />}
      </Flex>
      <Text pb="12px" fontSize="lg">
        <strong>Ngày tạo:</strong> {moment(infor?.createdAt).format('DD-MM-YYYY')}
      </Text>
    </Box>
  );
}
export default DetailInfoModal;
