import { Td, Text, Tr, useColorModeValue, useToast, FormControl, Select, Flex, IconButton } from '@chakra-ui/react';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import React, { useState } from 'react';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi, ModalType } from 'utils/constant';

function Row(props) {
  const { adUnit, website, creator, day, isLast, refetch, status, id, adsUnitDetail, handelUpdateAdsUnit } = props;
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [valueStatus, setValueStatus] = useState(status);
  const { userInfo } = useUserState();

  const handleStatus = event => {
    const statusAdsunit = event.target.value;
    const confirmStatus = window.confirm(`Bạn có chắc chắn muốn thay đổi trạng thái thành ${statusAdsunit} ?`);
    if (!confirmStatus) {
      return;
    }
    setValueStatus(statusAdsunit);
    updateStatus(statusAdsunit);
  };

  const updateStatus = async statusAdsunit => {
    const websiteData = {
      adUnitId: id,
      status: statusAdsunit,
    };
    try {
      const response = await axiosPost(API_ROUTES.UpdateStatusAds, websiteData);
      if (response.data.code === 0) {
        toast({
          title: `Thay đổi trạng thái thành công`,
          duration: 9000,
          status: 'success',
        });
        refetch();
      } else {
        toast({
          title: `Thay đổi trạng thái thất bại`,
          status: 'error',
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || `Thay đổi trạng thái thất bại`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {adUnit}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {website}
          </Text>
        </Td>
        <Td pl="30px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {creator?.username}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <FormControl display="flex" columnGap="10px" pl="6px">
            <Select maxW="130px" placeholder={adsUnitDetail?.status} value={adsUnitDetail?.status || valueStatus} onChange={handleStatus}>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_STATUS_ADS'}>
                <option value="WAITING">WAITING</option>
                <option value="APPROVED">APPROVED</option>
                <option value="REJECTED">REJECTED</option>
            </AuthorizationComponentWrapper>
            </Select>
          </FormControl>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Flex>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ASSIGN_CAMPAIGN_FOR_ADS'}>
            <IconButton
              p={2}
              bg="transparent"
              onClick={() => {
                handelUpdateAdsUnit(adsUnitDetail, ModalType.Assign);
              }}
            >
              <MdAssignmentTurnedIn size={20} />
            </IconButton>
          </AuthorizationComponentWrapper>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}

export default Row;
