import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES, MessageApi, PermissionValue } from 'utils/constant';
import { defaultPassword } from 'utils/constant';
import { ModalType } from 'utils/constant';
import { ManagerValidate } from 'utils/validation';
import { mappingOptionSelect } from 'utils/mapping';
import FormRequest from './FormRequest';

const defaultValues = {
  username: '',
  email: '',
  password: defaultPassword,
};

const CreateUser = ({ isOpen, userDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ManagerValidate),
  });
  const [{ loading: createLoading }, createUserApi] = useAxios(
    {
      url: API_ROUTES.RegisterUser,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { manual: true }
  );


  const handleSuccess = () => {
    toast({
      title: 'Thêm quản lý thành công.',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Add);
  };

  const handleError = error => {
    toast({
      title:
        MessageApi?.[error?.response?.data?.errors?.[0]?.msg ||
        error?.response?.data?.msg] ||
        `Thêm người quản lý thất bại.`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    createUserApi({
      data: { ...values, password: defaultPassword, passwordConf: defaultPassword, groups: values?.groups?.map(item => item?.value) },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <AlertDialogOverlay />

            <AlertDialogContent>
              <AlertDialogHeader textTransform="uppercase">Thông tin quản lý</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <FormRequest />
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => {
                    onClose?.(ModalType.Add);
                  }}
                >
                  Hủy
                </Button>
                <Button colorScheme="blue" ml={3} isLoading={createLoading} type='submit'>
                  {'Tạo'}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </FormProvider>
        </form>
      </AlertDialog>
    </>
  );
};

export default CreateUser;