import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import { AssignCampaignForWebAdsValidate } from 'utils/validation';

const defaultValues = {
  websiteAds: undefined,
};

const AssignCampaignModal = ({ isOpen, adsUnitDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [campaignOption, setCampaignOption] = useState([]);

  const [, getCampaignApi] = useAxios(
    {
      url: API_ROUTES.GetCampaignForWebsite(adsUnitDetail?._id),
    },
    { manual: true }
  );

  const [{ loading }, assignCampaignApi] = useAxios(
    {
      url: API_ROUTES.AssignCampaignForWebAds,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignCampaignForWebAdsValidate),
    defaultValues,
  });

  useEffect(() => {
    if (!isEmpty(adsUnitDetail)) {
      getCampaignApi().then(res => {
        const { data } = res?.data || {};

        setCampaignOption(data?.map(item => ({ label: item?.campaignName, value: item?._id })));
      });
    }
  }, [adsUnitDetail]);

  useEffect(() => {
    if (!isEmpty(adsUnitDetail)) {
      reset({
        campaignIDs: adsUnitDetail?.campaigns?.map(item => campaignOption?.find(campaignItem => campaignItem?.value === item?.campaign)),
      });
    }
  }, [adsUnitDetail, campaignOption]);

  const handleSuccess = () => {
    toast({
      title: 'Chỉ định chiến dịch thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Assign);
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Chỉ định chiến dịch thất bại.',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    assignCampaignApi({
      data: {
        websiteAdsID: adsUnitDetail?._id,
        campaignIDs: values?.campaignIDs?.map(item => item?.value),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Assign);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Chỉ định chiến dịch</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <SelectController
                isMulti
                control={control}
                name="campaignIDs"
                label="Chiến dịch"
                isRequired
                options={campaignOption}
                styleContainer={{ pb: 4 }}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Assign);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
              Xác nhận
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AssignCampaignModal;
