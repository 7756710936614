import { Box, Button, Flex, FormControl, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { TablePagination } from '@trendmicro/react-paginations';
import moment from 'moment';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import AddWebSiteCategory from './components/AddWebSiteCategory';
import Pagination from 'components/Pagination/Pagination';
import { isEmpty } from 'lodash';
import InputSearch from 'components/InputSearch/InputSearch';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function WebSiteCategory() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState(initialFilter);
  const [valueSearch, setValueSearch] = useState('');
  const { userInfo } = useUserState();

  const [{ data, loading }, refetch] = useAxios({
    url: API_ROUTES.ListWebsiteCate,
    params: filter,
  });

  const handleSearch = () => {
    refetch({ params: { ...filter, searchKeyword: valueSearch } });
  };

  const onChangeFilter = event => {
    event.persist();
    setValueSearch(event?.target?.value);
  };

  const resetSearchKeywords = () => {
    setFilter(initialFilter);
    setValueSearch('');
    refetch();
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
              Danh mục
            </Text> */}
            <Flex alignItems="end">
              <FormControl rowGap="16px" mt="2px" display="flex" flexWrap="wrap" alignItems="end" gap={4}>
                <Flex flexDirection="column">
                  <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                    Tìm kiếm
                  </Text>
                  <InputSearch
                    width="300px"
                    placeholder="Tìm kiếm theo tên"
                    value={valueSearch || ''}
                    name="searchKeyword"
                    mr="0"
                    onChange={onChangeFilter}
                    onClearSearch={() => {
                      setFilter({
                        ...filter,
                        searchKeyword: '',
                      });
                      setValueSearch('');
                    }}
                  />
                </Flex>
                <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
                  Đặt lại
                </Button>
                <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={handleSearch}>
                  Lọc
                </Button>
              </FormControl>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_CATEGORY'}>
              <Button variant="primary" maxH="40px" mt="10px" fontSize="12px" onClick={onOpen}>
                Thêm
              </Button>
            </AuthorizationComponentWrapper>
            </Flex>
          </CardHeader>
          <CardBody w="100%" overflowX="auto">
            {loading ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Loading />
              </Flex>
            ) : (
              <>
                <Box overflowX={isEmpty(data?.data) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
                  <Table variant="simple" color={textColor}>
                    <Thead>
                      <Tr my=".8rem" pl="0px" color="gray.400">
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Tên
                        </Th>
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Số lượng website
                        </Th>
                        <Th pr="100px" borderColor={borderColor} color="gray.400">
                          Số lượng liên kết
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Ngày tạo
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Ngày cập nhật
                        </Th>
                        <Th borderColor={borderColor} color="gray.400">
                          Thao tác
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.data?.map(row => {
                        return (
                          <Row
                            row={row}
                            id={row._id}
                            name={row.name}
                            slug={row.slug}
                            countWebsite={row.countWebsite}
                            countDirectLink={row.countDirectLink}
                            day={moment(row.createdAt).format('YYYY-MM-DD')}
                            updatedAt={moment(row.updatedAt).format('YYYY-MM-DD')}
                            refetch={refetch}
                          />
                        );
                      })}
                      {isEmpty(data?.data) && !loading && (
                        <Tr>
                          <Td colSpan="4">
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                              Không có dữ liệu
                            </Box>
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </Box>
                {!isEmpty(data?.data) && (
                  <Flex justifyContent="flex-end">
                    <Pagination
                      type="full"
                      page={data?.pagination?.page}
                      pageLength={data?.pagination?.pageSize}
                      totalRecords={data?.pagination?.count}
                      onPageChange={(page, pageLength) => {
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      }}
                      prevPageRenderer={() => <i className="fa fa-angle-left" />}
                      nextPageRenderer={() => <i className="fa fa-angle-right" />}
                    />
                  </Flex>
                )}
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isOpen && <AddWebSiteCategory refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </>
  );
}

export default WebSiteCategory;
