import React, { useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import { TableCampaignGroups } from 'utils/constant';
import { ModalType } from 'utils/constant';

import { ImProfile } from "react-icons/im";

import VTcontent from 'components/VerticalTab/VerticalContent';
import FormRequestUpdate from './FormRequestUpdate';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TableCampaignGroups.UpdateCampaignGroup,
        name: TableCampaignGroups.UpdateCampaignGroup,
        iconMenu: <ImProfile />,
      }
    }
  ]
};

const FormModal = ({ isOpen, userDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const [activeTabId, setActiveTabId] = useState(TableCampaignGroups.UpdateCampaignGroup);

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const handleCloseModal = () => onClose?.();

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Cập nhật nhóm chiến dịch</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={"16px"} minHeight={"400px"}>
            <Wrap w={"100%"}>
              <Grid w={"100%"} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem
                  w={"100%"}
                // minHeight={"400px"}
                // overflowY={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "hidden" : ""}
                // overflowX={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "scroll" : ""}
                // className='custom-scrollbar-track'
                >
                   <FormRequestUpdate userDetail={userDetail} onClose={onClose} refetch={refetch} />
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormModal;
