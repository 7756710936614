import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { API_ROUTES } from 'utils/constant';
import { ModalType } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import { AssignWebAdsForCampaignValidate } from 'utils/validation';
import { formatDate } from 'utils/helpers';

const DetailModal = ({ isOpen, onClose, campaignGroupDetail }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [websiteAdsOption, setWebsiteAdsOption] = useState([]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Thông tin chi tiết</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text pb="12px" fontSize="lg">
              <strong>Tên nhóm:</strong> {campaignGroupDetail?.groupName}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Người tạo nhóm:</strong> {campaignGroupDetail?.advertiser?.username}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Email:</strong> {campaignGroupDetail?.advertiser?.email}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Số điện thoại:</strong> {campaignGroupDetail?.advertiser?.phone}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Tổng số chiến dịch:</strong> {campaignGroupDetail?.totalCampaign}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Ngân sách tối đa/1 ngày:</strong> {campaignGroupDetail?.budget ? 'Không giới hạn' : campaignGroupDetail?.dailyBudget}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Chi phí tối đa:</strong> {campaignGroupDetail?.maxCost || 0}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Chi phí CTR tối thiểu:</strong> {campaignGroupDetail?.minCTR || 0}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Ngày tạo:</strong> {formatDate(campaignGroupDetail?.createdAt) || '--'}
            </Text>
            <Text pb="12px" fontSize="lg">
              <strong>Ngày cập nhật:</strong> {formatDate(campaignGroupDetail?.updatedAt) || '--'}
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
              }}
            >
              Đóng
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DetailModal;
