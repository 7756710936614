import {
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialog,
  FormControl,
  AlertDialogFooter,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import EditorController from 'components/Form/EditorController';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES, ErrorForm, MessageApi, PostLayoutOption } from 'utils/constant';
import { axiosPost } from 'utils/api';

const CreatePost = ({ isOpen, onClose, refetch, postEdit, setPostEdit }) => {
  const cancelRef = React.useRef();
  const toast = useToast();

  const schema = yup.object().shape({
    bannerFile: yup.string().required(ErrorForm.Required),
    title: yup.string().required(ErrorForm.Required),
    layout: yup.object().nullable().required(ErrorForm.Required),
    type: yup.object().nullable().required(ErrorForm.Required),
    description: yup.string().required(ErrorForm.Required),
    content: yup.string().required(ErrorForm.Required),
  });
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      bannerFile: '',
      title: '',
      layout: undefined,
      type: undefined,
      description: '',
      content: '',
    },
  });

  const onSubmit = async values => {
    const payload = {
      ...values,
      layout: values.layout.value,
      type: values.type.value,
    };
    try {
      let response;
      if (!isEmpty(postEdit)) {
        response = await axiosPost(API_ROUTES.UpdatePost, {
          ...payload,
          postId: postEdit._id,
        });
      } else {
        response = await axiosPost(API_ROUTES.Posts, payload);
      }

      if (response.data.code === 0) {
        toast({
          title:`${!isEmpty(postEdit) ? 'Chỉnh sửa bài viết' : 'Tạo bài viết'} thành công.`,
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: MessageApi?.[response.data.msg] || `${!isEmpty(postEdit) ? 'Chỉnh sửa bài viết' : 'Tạo bài viết'} thất bại.`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || `${!isEmpty(postEdit) ? 'Chỉnh sửa bài viết' : 'Tạo bài viết'} thất bại.`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    setPostEdit(null);
  };

  useEffect(() => {
    reset({
      bannerFile: '',
      title: postEdit?.title || '',
      layout:
        {
          label: postEdit?.layout,
          value: postEdit?.layout,
        } || null,
      type:
        {
          label: postEdit?.type,
          value: postEdit?.type,
        } || null,
      description: postEdit?.description || '',
      content: postEdit?.content || '',
    });
  }, [postEdit]);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setPostEdit(null);
        onClose();
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent minWidth={'60%'} height={'90%'} marginY={4}>
        <AlertDialogHeader>{!isEmpty(postEdit) ? 'Chỉnh sửa bài viết' : 'Tạo bài viết'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody width={'100%'} overflowY={'scroll'}>
          <form>
            <Flex flexDirection={'column'} mb={4}>
              <InputController
                sx={{
                  '::file-selector-button': {
                    height: 10,
                    padding: 0,
                    mr: 4,
                    background: 'none',
                    border: 'none',
                    fontWeight: 'bold',
                  },
                }}
                type="file"
                control={control}
                name="bannerFile"
                label="Banner"
                isRequired
              />
            </Flex>
            <InputController styleContainer={{ pb: 4 }} control={control} name="title" label="Tiêu đề" isRequired />
            <SelectController
              control={control}
              name="layout"
              label="Bố cục"
              isRequired
              options={PostLayoutOption}
              styleContainer={{ pb: 4 }}
            />
            <SelectController
              control={control}
              name="type"
              label="Loại"
              isRequired
              options={[
                {
                  label: 'POST',
                  value: 'POST',
                },
              ]}
              styleContainer={{ pb: 4 }}
            />
            <InputController type="textarea" control={control} name="description" label="Mô tả" isRequired />
            <FormControl pt="4">
              <EditorController isRequired control={control} name="content" label="Nội dung" />
            </FormControl>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              setPostEdit(null);
              onClose();
            }}
          >
            Hủy
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
            Lưu
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreatePost;
