import * as yup from 'yup';
import {
  ErrorForm,
  LOWERTEXT_REGEX,
  ManagerStatus,
  NUMBER_REGEX,
  PASSWORD_REGEX,
  PASSWORD_REGEX_V1,
  PHONE_REGEX,
  PermissionOption,
  SPECIAL_REGEX,
  TurnOffKey,
  UPPERTEXT_REGEX,
} from './constant';

export const schemaTest = {
  isIncludedLetter: value => value?.match(/[a-z]/g),
  isIncludedCapitalLetter: value => value?.match(/[A-Z]/g),
  isIncludedNumber: value => value?.match(/[0-9]/g),
  isIncludedSymbol: value => value?.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/g),
  isValidPassword: value => {
    const passed = [];
    if (value.length >= 10) {
      passed.push('safeLength');
    }
    if (schemaTest.isIncludedLetter(value)) {
      passed.push('isIncludedLetter');
    }
    if (schemaTest.isIncludedCapitalLetter(value)) {
      passed.push('isIncludedCapitalLetter');
    }
    if (schemaTest.isIncludedNumber(value)) {
      passed.push('isIncludedNumber');
    }
    if (schemaTest.isIncludedSymbol(value)) {
      passed.push('isIncludedSymbol');
    }
    return {
      isValid: passed.length === 5,
      passed,
    };
  },
  isValidAlias: value => (!value ? true : value?.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g)),
  isValidEmail: value =>
    !value
      ? true
      : value?.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        ),
  isValidGoogleMapUrl: value => (!value ? true : value?.match(/^(https:\/\/)(goo\.gl|google\.com)\/maps\/([^\s\\]+)$/g)),
  isValidAliasName: value => value.match(/^(?!-)([A-Za-z0-9-](?!.*--)){0,62}[A-Za-z0-9]$/g),
  isValidDomain: value => value.match(/^(((?!-)[A-Za-z0-9-]{0,62}[A-Za-z0-9])\.)+((?!-)[A-Za-z0-9-]{1,62}[A-Za-z0-9])(\.)?$/g),
  isValidPassword: value => value.match(PASSWORD_REGEX),
};

export const GroupPermissionValidate = yup.object().shape({
  groupName: yup.object().nullable().required(ErrorForm.Required),
});

export const ManagerValidate = yup.object().shape({
  username: yup.string().nullable().required(ErrorForm.Required),
  role: yup.mixed().nullable().required(ErrorForm.Required),
  email: yup
    .string()
    .required(ErrorForm.Required)
    .test('isValidEmail', ErrorForm.EmailInvalid, value => {
      return schemaTest.isValidEmail(value);
    }),
});

export const UpdateInformationValidate = yup.object().shape({
  username: yup.string().nullable().required(ErrorForm.Required),
  balance: yup.string().matches(NUMBER_REGEX, 'Sai định dạng số dư').nullable().required(ErrorForm.Required),
  verify: yup.string().nullable().required(ErrorForm.Required),
  phone: yup.string().matches(PHONE_REGEX, 'Sai định dạng số điện thoại').nullable().required(ErrorForm.Required),
  email: yup
    .string()
    .required(ErrorForm.Required)
    .test('isValidEmail', ErrorForm.EmailInvalid, value => {
      return schemaTest.isValidEmail(value);
    }),
});

export const UpdateInformationAdvValidate = yup.object().shape({
  username: yup.string().nullable().required(ErrorForm.Required),
  verify: yup.string().nullable().required(ErrorForm.Required),
  phone: yup.string().matches(PHONE_REGEX, 'Sai định dạng số điện thoại').nullable().required(ErrorForm.Required),
  email: yup
    .string()
    .required(ErrorForm.Required)
    .test('isValidEmail', ErrorForm.EmailInvalid, value => {
      return schemaTest.isValidEmail(value);
    }),
});

export const BlockManagerValidate = yup.object().shape({
  status: yup.object().nullable().required(ErrorForm.Required),
  date: yup.string().when('status', {
    is: status => (status?.value === ManagerStatus.Temporary ? true : false),
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
});

export const AssignManagerValidate = yup.object().shape({
  userID: yup.object().nullable().required(ErrorForm.Required),
});

export const AssignWebAdsForCampaignValidate = yup.object().shape({
  websiteAds: yup.array().nullable().required(ErrorForm.Required),
});

export const AssignCampaignForWebAdsValidate = yup.object().shape({
  campaignIDs: yup.array().nullable().required(ErrorForm.Required),
});

export const CreateWebsiteValidate = yup.object().shape({
  logo: yup.string().required(ErrorForm.Required),
  name: yup.string().required(ErrorForm.Required),
  siteName: yup.string().nullable().required(ErrorForm.Required),
  videoAds: yup.string().nullable(),
  footer: yup.string().required(ErrorForm.Required),
  domain: yup.string().required(ErrorForm.Required),
});

export const AddPageValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
  postId: yup.object().required(ErrorForm.Required),
});

export const SettingCommissionValidate = yup.object().shape({
  commissionRate: yup.string().required(ErrorForm.Required),
  commissionPerClick: yup.string().required(ErrorForm.Required),
});

export const AssignTicketValidate = yup.object().shape({
  user: yup.object().required(ErrorForm.Required),
});

export const changePasswordFormValidate = () => {
  return yup.object().shape({
    newPassword: yup
      .string()
      .min(8, ErrorForm.MaximumPasswordLength)
      .max(20, ErrorForm.MaximumPasswordLength)
      .matches(PASSWORD_REGEX_V1, {
        message: ErrorForm.PasswordInvalid,
        excludeEmptyString: true,
      })
      .nullable()
      .required(ErrorForm.Required),
    passwordConf: yup
      .string()
      .min(8, ErrorForm.MaximumPasswordLength)
      .max(20, ErrorForm.MaximumPasswordLength)
      .oneOf([yup.ref('newPassword'), null], ErrorForm.PasswordNotSame)
      .matches(PASSWORD_REGEX_V1, {
        message: ErrorForm.PasswordInvalid,
        excludeEmptyString: true,
      })
      .nullable()
      .required(ErrorForm.Required),
  });
};

export const ChangePasswordFormValidateProfile = yup.object().shape({
  password: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(LOWERTEXT_REGEX, 'Mật khẩu phải chứa ít nhất một chữ thường')
    .matches(UPPERTEXT_REGEX, 'Mật khẩu phải chứa ít nhất một chữ hoa')
    .matches(NUMBER_REGEX, 'Mật khẩu phải chứa ít nhất một số')
    .matches(SPECIAL_REGEX, 'Mật khẩu phải chứa ít nhất một ký tự đặc biệt')
    .nullable()
    .required(ErrorForm.Required),
  newPassword: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .matches(LOWERTEXT_REGEX, 'Mật khẩu phải chứa ít nhất một chữ thường')
    .matches(UPPERTEXT_REGEX, 'Mật khẩu phải chứa ít nhất một chữ hoa')
    .matches(NUMBER_REGEX, 'Mật khẩu phải chứa ít nhất một số')
    .matches(SPECIAL_REGEX, 'Mật khẩu phải chứa ít nhất một ký tự đặc biệt')
    .nullable()
    .required(ErrorForm.Required),
  passwordConf: yup
    .string()
    .min(8, ErrorForm.MaximumPasswordLength)
    .max(20, ErrorForm.MaximumPasswordLength)
    .oneOf([yup.ref('newPassword'), null], ErrorForm.PasswordNotSame)
    .nullable()
    .required(ErrorForm.Required),
});

export const TicketFormValidate = yup.object().shape({
  subject: yup.string().required(ErrorForm.Required),
  content: yup.string().required(ErrorForm.Required),
  type: yup.object().nullable().required(ErrorForm.Required),
});

export const TicketCategoryFormValidate = yup.object().shape({
  name: yup.string().required(ErrorForm.Required),
});

export const CampaignGroupValidate = yup.object().shape({
  groupName: yup.string().nullable().required(ErrorForm.Required),
  dailyBudget: yup.string().when('budget', {
    is: false,
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
  minDelivered: yup.string().when('minimumCTR', {
    is: minimumCTR => (minimumCTR === TurnOffKey.On ? true : false),
    then: () => yup.string().nullable().required(ErrorForm.Required),
  }),
});

export const AssignPermissionFormValidate = yup.object().shape({
  ...PermissionOption.reduce((acc, field) => {
    acc[field?.value] = yup.boolean().nullable();
    return acc;
  }, {})
});

export const inputAmountMoneyValidate = amount => {
  if (!amount) return ErrorForm.Required;
  if (amount < 20000) return ErrorForm.MinMoney(20000);
  if (amount > 150000000) return ErrorForm.MaxMoney(150000000);
};