import React, { useMemo, useState } from 'react';
import { Select } from 'chakra-react-select';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import { Box, Button, Flex, FormControl, FormLabel, Stack, Switch, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';

import TicketTable from './components/TicketTable';
import { API_ROUTES, ModalType, Roles, StatusTicketOptions, TypeTicket } from 'utils/constant';
import TicketForm from './components/AssignTicketForm';
import { useUserState } from 'context/UserContext';
import StatusTicketForm from './components/StatusTicketForm';
import DetailInfoModal from './components/DetailInfoModal';
import RegisterTicketModal from './components/RegisterTicketModal';
import MultiSelectMenu from 'components/MultiSelect';
import InputSearch from 'components/InputSearch/InputSearch';
import { mappingOptionSelect } from 'utils/mapping';
import TicketInformation from './components/TicketInformation';

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
  searchKeyword: '',
};

function Ticket() {
  const { userInfo } = useUserState();
  const textColor = useColorModeValue('gray.700', 'white');
  const { isOpen: isAssignModalOpen, onOpen: onOpenAssignModal, onClose: onCloseAssignModal } = useDisclosure();
  const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const { isOpen: isRegisterTicketOpen, onOpen: onOpenRegisterTicketModal, onClose: onCloseRegisterTicketModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Assign]: onOpenAssignModal,
      [ModalType.ChangeStatus]: onOpenChangeStatusModal,
      [ModalType.Detail]: onOpenDetailModal,
      [ModalType.RegisterTicket]: onOpenRegisterTicketModal,
    }),
    [onOpenAssignModal, onOpenChangeStatusModal, onOpenDetailModal, onOpenRegisterTicketModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Assign]: onCloseAssignModal,
      [ModalType.ChangeStatus]: onCloseChangeStatusModal,
      [ModalType.Detail]: onCloseDetailModal,
      [ModalType.RegisterTicket]: onCloseRegisterTicketModal,
    }),
    [onCloseAssignModal, onCloseChangeStatusModal, onCloseRegisterTicketModal]
  );
  const [ticketEditing, setTicketEditing] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  // const [ticketType, setTicketType] = useState(null);
  // const [switched, setSwitched] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [filterTickets, setFilterTickets] = useState([]);
  const [isOpenMultiSelect, setIsOpenMultiSelect] = useState(false);
  const [valueSearch, setValueSearch] = useState('');

  const [{ data, loading }, refetch] = useAxios({
    url: userInfo?.role === Roles.GUIDE ? API_ROUTES.TicketForManager : API_ROUTES.Ticket,
    params: filter,
  });
  const [{ data: category }] = useAxios({
    url: API_ROUTES.TicketCategory,
  });

  const { data: tickets, pagination } = data || {};

  const onFilter = () => {
    setFilter({
      ...filter,
      type: filterTickets.map(item => item.value),
      status: ticketStatus?.value,
      searchKeyword: valueSearch,
    });
  };

  const handelUpdateTicket = (ticket, modalType) => {
    openModal?.[modalType]?.();
    setTicketEditing(ticket);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setTicketEditing(null);
  };

  const handleOpenRegisterTicketModal = () => {
    openModal?.[ModalType.RegisterTicket]?.();
  };

  const onReset = () => {
    setFilter(initialFilter);
    setValueSearch('');
    // setTicketType(null);
    setTicketStatus(null);
    setFilterTickets([]);
  };

  const handleBlurTicket = values => {
    setFilterTickets(values);
  };

  const onChangeSearch = event => {
    event.persist();
    setValueSearch(event?.target?.value);
  };

  const onClearSearch = () => {
    setValueSearch('');
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" pt="0" bg="#fff">
          <CardHeader p="12px 5px" pt="0" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'} width={'100%'}>
                <Flex justifyContent={'space-between'} gap={'30px'}>
                  {/* <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={handleOpenRegisterTicketModal}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button> */}
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'} width={'100%'}>
                  <Stack flexDirection={"row"} width={'100%'}>
                    <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }} width={'100%'}>
                      {/* <FormControl minWidth={'300px'}>
                        <FormLabel>Loại Ticket</FormLabel>
                        <Select
                          isClearable
                          onChange={e => {
                            setTicketType(e);
                          }}
                          value={ticketType}
                          options={TypeTicket}
                          placeholder="Chọn loại Ticket"
                        />
                      </FormControl> */}
                      <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                        <FormLabel fontWeight={600} fontSize={14}>Tìm kiếm</FormLabel>
                        <InputSearch
                          width={'300px'}
                          placeholder="Tìm kiếm tiêu đề"
                          value={valueSearch}
                          onChange={onChangeSearch}
                          onClearSearch={onClearSearch}
                        />
                      </FormControl>
                      <FormControl minWidth={{ base: '200px', sm: '250px' }} maxW="250px">
                        <FormLabel fontWeight={600} fontSize={14}>Loại Ticket</FormLabel>
                        <MultiSelectMenu
                          label="Chọn ticket"
                          options={mappingOptionSelect(category?.data) || []}
                          onBlur={handleBlurTicket}
                          minWidth={{ base: '200px', sm: '300px' }}
                          onClose={() => setIsOpenMultiSelect(false)}
                          onOpen={() => setIsOpenMultiSelect(true)}
                          isOpen={isOpenMultiSelect}
                          filterTickets={filterTickets}
                          clearSelectedOption={() => {
                            setFilterTickets([]);
                          }}
                        />
                      </FormControl>
                      <FormControl minWidth={'230px'} maxW="250px">
                        <FormLabel fontWeight={600} fontSize={14}>Trạng thái</FormLabel>
                        <Select
                          isClearable
                          value={ticketStatus}
                          onChange={e => {
                            setTicketStatus(e);
                          }}
                          options={StatusTicketOptions}
                          placeholder="Chọn"
                        ></Select>
                      </FormControl>
                      {/* <FormControl>
                        <FormLabel>Approved</FormLabel>
                        <Switch
                          colorScheme="blue"
                          isChecked={switched}
                          onChange={event => {
                            setSwitched(event.target.checked);
                          }}
                        />
                      </FormControl> */}
                      <Button
                        // variant="outline"
                        // borderColor="blue.400"
                        // textColor="blue.400"
                        maxH="45px"
                        minW="100px"
                        onClick={onReset}
                        alignSelf={'end'}
                        // mr={"16px"}
                      >
                        Đặt lại
                      </Button>
                      <Button variant="primary" maxH="45px" minW="100px" fontSize={'14'} onClick={onFilter} alignSelf={'end'}>
                        Lọc
                      </Button>
                    </Flex>
                    {/* <Button variant="primary" maxH="45px" minW="100px" fontSize={'14'} onClick={() => handleOpenRegisterTicketModal()} alignSelf={'end'}>
                      Thêm
                    </Button> */}
                  </Stack>
                </Flex>

              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <TicketTable tickets={tickets || []} handelUpdateTicket={handelUpdateTicket} refetch={refetch} />
            </Stack>
            {!isEmpty(tickets) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={pagination?.page}
                  pageLength={pagination?.pageSize}
                  totalRecords={pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {/* {isAssignModalOpen && (
        <TicketForm ticketDetail={ticketEditing} isOpen={isAssignModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )}
      {isChangeStatusModalOpen && (
        <StatusTicketForm ticketDetail={ticketEditing} isOpen={isChangeStatusModalOpen} onClose={handelCloseModal} refetch={refetch} />
      )} */}
      {isDetailModalOpen && (
        <TicketInformation
          ticketDetail={ticketEditing}
          isOpen={isDetailModalOpen}
          onClose={handelCloseModal}
          refetchData={refetch}
        />
      )}
      {isRegisterTicketOpen && (
        <RegisterTicketModal
          isOpen={isRegisterTicketOpen}
          onClose={handelCloseModal}
          refetch={refetch}
          categories={mappingOptionSelect(category?.data)}
        />
      )}
    </>
  );
}

export default Ticket;
