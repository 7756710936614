import { Button, Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, FormControl, Select, Td, Box } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { TablePagination } from '@trendmicro/react-paginations';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import InputSearch from 'components/InputSearch/InputSearch';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import { ListAdsUnit } from 'utils/constant';

const InitFilter = { ...initialFilter, searchKeyword: '', categoryId: '', adUnit: '', status: '' };

function Website() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(InitFilter);

  const [{ data: websiteData, loading }, refetchWebsite] = useAxios(
    {
      url: API_ROUTES.ListWebSite,
      params: InitFilter,
    },
    {
      // manual: true,
    }
  );
  const [{ data: websiteCategory }] = useAxios(
    {
      url: API_ROUTES.ListWebsiteCate,
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    refetchWebsite({ params: { ...filter } });
  }, []);

  const handleSearch = () => {
    refetchWebsite({ params: { ...filter, pageIndex: 0 } });
  };

  const resetSearchKeywords = () => {
    setFilter(InitFilter);
    refetchWebsite();
  };

  const onChangeFilter = event => {
    event.persist();
    const name = event?.target?.name;

    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: event?.target?.value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text pb="28px" fontSize="xl" color={textColor} fontWeight="bold">
            Website
          </Text> */}
          <FormControl rowGap="16px" mt="2px" display="flex" flexWrap="wrap" alignItems="end" gap={4}>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Tìm kiếm
              </Text>
              <InputSearch
                width={{ base: 'full', sm: '300px' }} maxW="300px"
                placeholder="Tìm kiếm tên, đường dẫn"
                value={filter?.searchKeyword || ''}
                name="searchKeyword"
                mr="0"
                onChange={onChangeFilter}
                onClearSearch={() =>
                  setFilter({
                    ...filter,
                    searchKeyword: '',
                  })
                }
              />
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Danh mục
              </Text>
              <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn danh mục" name="categoryId" value={filter.categoryId} onChange={onChangeFilter}>
                {websiteCategory?.data?.map((cate, index) => (
                  <option key={index} value={cate?._id}>
                    {cate.name}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Đơn vị quảng cáo
              </Text>
              <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn đơn vị quảng cáo" name="adUnit" value={filter.adUnit} onChange={onChangeFilter}>
                {ListAdsUnit?.map((ads, index) => (
                  <option key={index} value={ads}>
                    {ads}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Trạng thái
              </Text>
              <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn trạng thái" name="status" value={filter.status} onChange={onChangeFilter}>
                <option value="PENDING">PENDING</option>
                <option value="APPROVED">APPROVED</option>
                <option value="REJECTED">REJECTED</option>
              </Select>
            </Flex>
            <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
              Đặt lại
            </Button>
            <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={handleSearch}>
              Lọc
            </Button>
          </FormControl>
        </CardHeader>
        <CardBody w="100%" overflowX="auto">
          {loading ? (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <Loading />
            </Flex>
          ) : (
            <Box overflowX={isEmpty(websiteData?.data) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th borderColor={borderColor} color="gray.400">
                      Tên
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Đường dẫn
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Người tạo
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Đơn vị quảng cáo
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Danh mục
                    </Th>
                    <Th textAlign="left" minW={220} borderColor={borderColor} color="gray.400">
                      Trạng thái
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Ngày
                    </Th>
                    {/* <Th borderColor={borderColor} color="gray.400">
                      Day
                    </Th> */}
                    <Th pl="0" borderColor={borderColor} isTruncated color="gray.400">
                      Thao tác
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {websiteData?.data?.map(row => (
                    <Row
                      categoryData={row.categoryId}
                      key={row._id}
                      adUnit={row.adUnit}
                      creator={row.publisherId}
                      row={websiteData?.data}
                      id={row?._id}
                      url={row.url}
                      name={row.name}
                      status={row.status}
                      updatedAt={row.updatedAt}
                      day={moment.utc(row.createdAt).format('YYYY-MM-DD')}
                      removeWebsites={row.removeWebsites}
                      refetch={refetchWebsite}
                    />
                  ))}
                  {isEmpty(websiteData?.data) && !loading && (
                    <Tr>
                      <Td colSpan="8">
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              {!isEmpty(websiteData?.data) && (
                <Flex justifyContent="flex-end">
                  <TablePagination
                    type="full"
                    page={websiteData?.pagination?.page}
                    pageLength={websiteData?.pagination?.pageSize}
                    totalRecords={websiteData?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      refetchWebsite({ params: { ...filter, pageSize: pageLength, pageIndex: page - 1 } });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              )}
            </Box>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Website;
