import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    FormControl,
    Text,
    Checkbox,
    Box,
    useColorModeValue,
  } from '@chakra-ui/react';
  import React from 'react';
  import moment from 'moment';
import { STATUS_WITHDRAW } from 'utils/constant';
import { currencyFormat } from 'utils/helpers';
  
  function Detail({ transaction }) {
  const textColor = useColorModeValue('gray.500', 'white');

          let color = textColor; // default color
      
          if (transaction.status === STATUS_WITHDRAW.APPROVED || transaction.status===STATUS_WITHDRAW.SUCCESS) {
            color = 'green.500';
          } else if (transaction.status === STATUS_WITHDRAW.REJECTED) {
            color = 'red.500';
          }
      
          
    return (
      <Box>
        <Text pb="12px" fontSize="lg">
          <strong>Mã giao dịch:</strong> {transaction?.order_no}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Người dùng:</strong> {transaction?.username}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Ngân hàng:</strong> {transaction?.bank_code}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Số tài khoản NH:</strong> {transaction?.bank_number}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Tên tài khoản NK:</strong> {transaction?.beneficiary_name}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Kênh giao dịch:</strong>  {transaction?.channel}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Số tiền:</strong> {currencyFormat(transaction?.amount)}
        </Text>
        <Text pb="12px" fontSize="lg" color={color} fontWeight="bold">
          <strong style={{color:"#000"}}>Trạng thái:</strong> {transaction?.status === STATUS_WITHDRAW.APPROVED ? 'ĐÃ DUYỆT' : transaction?.status === STATUS_WITHDRAW.REJECTED ? 'TỪ CHỐI' : transaction?.status === STATUS_WITHDRAW.SUCCESS ? 'THÀNH CÔNG':'CHỜ DUYỆT'}
        </Text>
        <Text pb="12px" fontSize="lg">
          <strong>Ngày tạo:</strong> {moment(transaction?.createdAt).format('DD-MM-YYYY HH:mm')}
        </Text>
      </Box>
    );
  }
  export default Detail;
  