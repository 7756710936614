import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, useToast } from "@chakra-ui/react";
import FormRequest from "./FormRequest";
import { API_ROUTES, MessageApi, defaultPassword } from "utils/constant";
import { ManagerValidate } from "utils/validation";
import useAxios from "axios-hooks";

const defaultValues = {
  username: '',
  email: '',
  password: defaultPassword,
};

const EditUser = (props) => {
  const { userDetail, onClose, refetchData } = props;
  const toast = useToast();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ManagerValidate),
  });
  const [{ loading: updateLoading }, updateUserApi] = useAxios(
    {
      url: API_ROUTES.UpdateUser,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { manual: true }
  );

  const onSubmit = values => {
    toast.closeAll();
    updateUserApi({
      data: {
        ...values,
        userID: userDetail?.id,
        password: defaultPassword,
        passwordConf: defaultPassword,
        role: values?.role?.value,
      },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  const handleSuccess = () => {
    toast({
      title: `Cập nhật người quản lý thành công.`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    onClose();
    refetchData?.();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || `Cập nhật người quản lý thất bại.`,
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <FormRequest
          userDetail={userDetail}
        />
        <Flex justifyContent={"end"} mt="16px">
          <Button
            onClick={onClose}
          >
            Hủy
          </Button>
          <Button colorScheme="blue" ml={3} isLoading={updateLoading} type="submit">
            {'Cập nhật'}
          </Button>
        </Flex>
      </FormProvider>
    </form>
  )
};

export default EditUser;