import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { ManagerStatusLabel, ModalType } from 'utils/constant';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { BiCommentDetail } from 'react-icons/bi';
import { FaPen } from 'react-icons/fa';
import { CiBoxList } from 'react-icons/ci';

function Row({ publisherDetail, isLast, handelUpdateUser, handelUpdateUserDetail, handelChangePassword }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const history = useHistory();

  return (
    <Tr>
      <Td minWidth={{ sm: '150px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {publisherDetail?.username}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {publisherDetail?.email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {publisherDetail?.phone}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {ManagerStatusLabel[publisherDetail?.status] || 'Bình thường'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {publisherDetail?.balance || 0}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" isTruncated>
            {moment.utc(publisherDetail?.createdAt).format('YYYY-MM-DD')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          {/* <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(publisherDetail, ModalType.Block);
            }}
          >
            <LockIcon />
          </IconButton> */}
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUserDetail(publisherDetail, ModalType.Detail);
            }}
          >
            <BiCommentDetail />
          </IconButton>
          {/* <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelChangePassword(publisherDetail, ModalType.ChangePassword);
            }}
          >
            <FaPen />
          </IconButton> */}
          <IconButton p={2} bg="transparent" onClick={() => history.push(`/admin/list-referal/${publisherDetail?._id}/publisher`)}>
            <CiBoxList />
          </IconButton>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
