import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, DeviceOption, MessageApi, OsNameOption, initialFilter } from 'utils/constant';
import Row from './components/Row';
import { mappingOptionSelect } from 'utils/mapping';
import DatePicker from 'components/DatePicker/DatePicker';
import { convertDate, formatDate, getInitFilerChart } from 'utils/helpers';
import moment from 'moment';

const CPCStatistics = () => {
  const toast = useToast();
  const [filter, setFilter] = useState({
    ...initialFilter,
    // ...getInitFilerChart(),
    status: true,
    os: '',
    device: '',
    publisherId: '',
    advertiserId: '',
    countryCode: '',
    campaignId: '',
  });
  const [statistics, setStatistics] = useState([]);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.CPCStatistics,
      params: { ...filter, startDate: convertDate(filter.startDate), endDate: convertDate(filter.endDate) },
    },
    { manual: true }
  );

  const [{ data: countries }] = useAxios({
    url: API_ROUTES.Countries,
  });

  const [{ data: publisher }] = useAxios({
    url: API_ROUTES.Publisher,
  });

  const [{ data: advertiser }] = useAxios({
    url: API_ROUTES.Advertiser,
  });

  const [{ data: campaign }] = useAxios({
    url: API_ROUTES.Campaign,
  });

  useEffect(() => {
    refetch({
      params: { ...filter, startDate: convertDate(filter.startDate), endDate: convertDate(filter.endDate) },
    })
      .then(res => {
        setStatistics(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, startDate: convertDate(filter.startDate), endDate: convertDate(filter.endDate) },
    })
      .then(res => {
        setStatistics(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const onReset = () => {
    setFilter({
      ...initialFilter,
      // ...getInitFilerChart(),
      status: true,
      os: '',
      publisherId: '',
      advertiserId: '',
      countryCode: '',
      campaignId: '',
    });
  };

  const onChangeFilter = event => {
    event.persist();
    const name = event?.target?.name;

    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: event?.target?.value,
    }));
  };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      // ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
              CPC Statistics
              Thống kê CPC
            </Text> */}
          <Flex alignItems="end" flexWrap="wrap" gap={'12px'}>
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Quốc gia
              </Text>
              <Select w="300px" placeholder="Chọn quốc gia" name="countryCode" value={filter.countryCode} onChange={onChangeFilter}>
                {mappingOptionSelect(countries?.data, 'name', 'code')?.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Hệ thống
              </Text>
              <Select w="300px" placeholder="Chọn hệ thống" name="os" value={filter.os} onChange={onChangeFilter}>
                {OsNameOption?.map((os, index) => (
                  <option key={index} value={os.value}>
                    {os.label}
                  </option>
                ))}
              </Select>
            </Flex>
            {/* <Box >
                <DatePicker
                  styleInput={{
                    minWidth: '300px',
                  }}
                  selectedDate={filter.date}
                  onChange={date => onChangeDate('date')(date)}
                />
              </Box> */}
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Chiến dịch
              </Text>
              <Select w="300px" placeholder="Chọn chiến dịch" name="campaignId" value={filter.campaignId} onChange={onChangeFilter}>
                {mappingOptionSelect(campaign?.data, 'campaignName', '_id')?.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Nhà sản xuất
              </Text>
              <Select w="300px" placeholder="Chọn nhà sản xuất" name="publisherId" value={filter.publisherId} onChange={onChangeFilter}>
                {mappingOptionSelect(publisher?.data, 'username', '_id')?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Nhà quảng cáo
              </Text>
              <Select w="300px" placeholder="Chọn nhà quảng cáo" name="advertiserId" value={filter.advertiserId} onChange={onChangeFilter}>
                {mappingOptionSelect(advertiser?.data, 'username', '_id')?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexDirection="column">
              <Text fontSize="sm" color={textColor} fontWeight="bold">
                Thiết bị
              </Text>
              <Select w="300px" placeholder="Chọn thiết bị" name="device" value={filter.device} onChange={onChangeFilter}>
                {DeviceOption?.map((device, index) => (
                  <option key={index} value={device.value}>
                    {device.label}
                  </option>
                ))}
              </Select>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                <FormLabel m="0" pr="10px" fontWeight={600} fontSize={14}>
                  Ngày bắt đầu:
                </FormLabel>
                <DatePicker
                  styleInput={{
                    minWidth: '300px',
                  }}
                  selectedDate={filter.startDate}
                  onChange={date => onChangeDate('startDate')(date)}
                />
              </FormControl>
              <FormControl display="flex" flexDirection="column" maxW="300px" mr="12px">
                <FormLabel m="0" pr="10px" fontWeight={600} fontSize={14}>
                  Ngày kết thúc:
                </FormLabel>
                <DatePicker
                  styleInput={{
                    minWidth: '300px',
                  }}
                  selectedDate={filter.endDate}
                  minDate={filter.startDate}
                  // maxDate={new Date(formatDate(moment(filter.startDate).add(6, 'days')))}
                  onChange={date => onChangeDate('endDate')(date)}
                />
              </FormControl>
            </Flex>
            <Flex gap={3}>
              <Button
                // backgroundColor="white"
                // color="blue.400"
                // border="1px"
                // _hover={{
                //   text: 'white',
                // }}
                mt={3}
                maxH="40px"
                mx="0px"
                fontSize="12px"
                onClick={onReset}
              >
                Đặt lại
              </Button>
              <Button mt={3} variant="primary" maxH="40px" mx="0px" fontSize="12px" onClick={onFilter}>
                Lọc
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Box overflowX={isEmpty(statistics) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  <Th pl="0px" borderColor={borderColor} color="gray.400" isTruncated>
                    Nhà quảng cáo
                  </Th>
                  <Th pl="0px" borderColor={borderColor} color="gray.400" isTruncated>
                    Nhà sản xuất
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Tên chiến dịch
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Loại
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Đơn vị quảng cáo
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    URL cuối
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Trang đã tạo
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Quốc gia
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" isTruncated>
                    Thiết bị
                  </Th>
                  <Th borderColor={borderColor} color="gray.400" textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                    Hệ thống
                  </Th>
                  <Th borderColor={borderColor} color="gray.400">
                    Số lượng
                  </Th>
                  <Th borderColor={borderColor}>Thao tác</Th>
                </Tr>
              </Thead>
              <Tbody>
                {statistics?.map((row, index, arr) => {
                  return (
                    <Row
                      key={row._id}
                      statisticDetail={row}
                      isLast={index === arr.length - 1 ? true : false}
                      publisher={publisher?.data}
                      advertiser={advertiser?.data}
                    />
                  );
                })}
                {isEmpty(statistics) && !loading && (
                  <Tr>
                    <Td colSpan="12">
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
            {!isEmpty(statistics) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: {
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                        startDate: convertDate(filter.startDate),
                        endDate: convertDate(filter.endDate),
                      },
                    }).then(res => {
                      setStatistics(res?.data?.data);
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default CPCStatistics;
