import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import { AssignWebAdsForCampaignValidate } from 'utils/validation';

const defaultValues = {
  websiteAds: undefined,
};

const AssignWebAdsModal = ({ isOpen, campaignDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [websiteAdsOption, setWebsiteAdsOption] = useState([]);

  const [, getWebAdsApi] = useAxios(
    {
      url: API_ROUTES.GetWebAdsForCampaign,
    },
    { manual: true }
  );

  const [{ loading }, assignWebAds] = useAxios(
    {
      url: API_ROUTES.AssignWebAdsForCampaign,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignWebAdsForCampaignValidate),
    defaultValues,
  });

  useEffect(() => {
    if (!isEmpty(campaignDetail)) {
      getWebAdsApi({ params: { campaignID: campaignDetail?._id } }).then(res => {
        const { data } = res?.data || {};

        setWebsiteAdsOption(data?.map(item => ({ label: `${item?.websiteId?.name} - ${item?.websiteId?.url}`, value: item?._id })));
      });
    }
  }, [campaignDetail]);

  useEffect(() => {
    if (!isEmpty(campaignDetail)) {
      reset({
        websiteAds: campaignDetail?.websiteAds?.map(item =>
          websiteAdsOption?.find(websiteAdsItem => websiteAdsItem?.value === item?.websiteAds)
        ),
      });
    }
  }, [campaignDetail, websiteAdsOption]);

  const handleSuccess = () => {
    toast({
      title: 'Chỉ định quảng cáo trang web thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Assign);
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Chỉ định quảng cáo trang web không thành công',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    assignWebAds({
      data: {
        campaignID: campaignDetail?._id,
        websiteAds: values?.websiteAds?.map(item => item?.value),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.(ModalType.Assign);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Chỉ định quảng cáo trang web</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <SelectController
                isMulti
                control={control}
                name="websiteAds"
                label="Trang web quảng cáo"
                isRequired
                options={websiteAdsOption}
                styleContainer={{ pb: 4 }}
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.(ModalType.Assign);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
              Xác nhận
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AssignWebAdsModal;
