import React, { useEffect, useRef, useState } from 'react';
import { AspectRatio, Box, Button, Flex, FormLabel, Grid, GridItem, Image, Text, useToast } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { MessageApi, ROOT_API } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { axiosPost } from 'utils/api';
import { CreateWebsiteValidate } from 'utils/validation';

function CreateSettingSite() {
  const [{ data }] = useAxios({
    url: API_ROUTES.Sites,
  });
  const [logo, setLogo] = useState(null);
  const [video, setVideo] = useState(null);
  const toast = useToast();
  const videoRef = useRef(null);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(CreateWebsiteValidate),
    defaultValues: {
      logo: '',
      name: '',
      siteName: '',
      videoAds: '',
      footer: '',
      domain: '',
    },
  });

  const onSubmit = async values => {
    let logoData;
    if (typeof logo !== 'string') {
      logoData = new FormData();
      logoData.append('logoFile', logo);
      logoData.append('name', values.name);
    }
    try {
      let response;
      response = await axiosPost(API_ROUTES.Sites, logoData, {
        'Content-Type': 'multipart/form-data',
      });
      if (response.data.code === 0) {
        toast({
          title: MessageApi?.[response.data.msg],
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Thao tác thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(data?.data)) {
      setLogo(data?.data?.logo);
      reset({
        name: data?.data?.name,
        siteName: data?.data?.name,
      });
    }
  }, [data?.data?.name]);

  useEffect(() => {
    videoRef.current?.load();
  }, [video]);

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card pb="0px">
        <CardHeader p="6px 0px">
          <Text color="blue.500" fontSize={16} fontWeight={600}>
            {/* General information */}
            Thông tin chung
          </Text>
        </CardHeader>
        <CardBody>
          <form>
            <Flex flexDirection={'column'} gap={5} py={6}>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  lg: 'repeat(2, 1fr)',
                }}
                gap={5}
              >
                <GridItem w="100%">
                  <Flex justifyContent={'flex-start'}>
                    <InputController
                      styleContainer={{ width: 'fit-content' }}
                      id="files"
                      type="file"
                      control={control}
                      name="logo"
                      label="Logo"
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      defaultValue={logo || ''}
                      hidden
                      isRequired
                      onChange={e => {
                        setLogo(e.target.files[0]);
                      }}
                    />
                    <Flex flexDirection={'column'}>
                      <FormLabel
                        border={'1px solid #ccc'}
                        px={4}
                        p={2}
                        rounded={10}
                        cursor={'pointer'}
                        fontSize={14}
                        fontWeight={600}
                        width={'fit-content'}
                        htmlFor="files"
                      >
                        Chọn logo
                      </FormLabel>
                      {logo && (
                        <Box boxSize="sm" rounded={5} overflow={'hidden'}>
                          <Image
                            w={'100%'}
                            objectFit={'contain'}
                            height={'100%'}
                            src={typeof logo === 'string' ? `${ROOT_API}${logo}` : URL.createObjectURL(logo)}
                            alt="front side"
                          />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem w="100%">
                  <Flex flexDirection={'column'}>
                    <InputController
                      styleContainer={{ display: 'flex', flexDirection: 'row', pb: 4 }}
                      styleBoxInput={{
                        width: '100%',
                      }}
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      control={control}
                      name="name"
                      label="Tên"
                      isRequired
                    />
                    <InputController
                      styleContainer={{ display: 'flex', flexDirection: 'row', pb: 4 }}
                      styleBoxInput={{
                        width: '100%',
                      }}
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      control={control}
                      name="siteName"
                      label="Tên trang"
                      isRequired
                    />
                  </Flex>
                </GridItem>
              </Grid>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  lg: 'repeat(2, 1fr)',
                }}
                gap={5}
              >
                <GridItem w="100%">
                  <Flex justifyContent={'flex-start'}>
                    <InputController
                      styleContainer={{ width: 'fit-content' }}
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      id="video"
                      type="file"
                      control={control}
                      name="videoAds"
                      label="Video Ads"
                      hidden
                      accept="image/*,audio/*,gif/*,video/mp4,video/x-m4v,video/*"
                      onChange={e => {
                        const file = e.target.files[0];
                        if (!file) return;
                        setVideo(URL.createObjectURL(file));
                      }}
                    />
                    <Flex flexDirection={'column'}>
                      <FormLabel
                        border={'1px solid #ccc'}
                        px={4}
                        p={2}
                        rounded={10}
                        cursor={'pointer'}
                        fontSize={14}
                        fontWeight={600}
                        width={'fit-content'}
                        htmlFor="video"
                      >
                        {/* Select Video Ads */}
                        Chọn video quảng cáo
                      </FormLabel>
                      {video && (
                        <Flex rounded={10} overflow={'hidden'}>
                          <video ref={videoRef} width="100%" height="150" controls>
                            <source src={video} />
                            {/* Your browser does not support the video. */}
                            Trình duyệt của bạn không hỗ trợ video.
                          </video>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem w="100%">
                  <Flex flexDirection={'column'}>
                    <InputController
                      type="textarea"
                      styleContainer={{ display: 'flex', flexDirection: 'row', pb: 4 }}
                      styleBoxInput={{
                        width: '100%',
                      }}
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      control={control}
                      name="footer"
                      label="Giới thiệu cuối trang"
                      isRequired
                    />
                    <InputController
                      styleContainer={{ display: 'flex', flexDirection: 'row', pb: 4 }}
                      styleBoxInput={{
                        width: '100%',
                      }}
                      styleLabel={{
                        fontWeight: '600',
                        color: '#6B6B6B',
                      }}
                      control={control}
                      name="domain"
                      label="Domain"
                      isRequired
                    />
                  </Flex>
                </GridItem>
              </Grid>
              <Flex flexDirection={'row'} justifyContent={'flex-end'}>
                <Button
                  onClick={() => {
                    reset({
                      logo: '',
                      name: '',
                      siteName: undefined,
                      videoAds: undefined,
                      footer: '',
                      domain: '',
                    });
                    setLogo(null);
                    setVideo(null);
                  }}
                >
                  Đặt lại
                </Button>
                <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
                  Lưu
                </Button>
              </Flex>
            </Flex>
          </form>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default CreateSettingSite;
