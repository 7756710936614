import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Box,
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Td,
} from '@chakra-ui/react';
import { PermissionValue } from 'utils/constant';

import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const PermissionDetail = ({ data }) => {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Table variant="simple" color={textColor}>
      <Thead>
        <Tr my=".8rem" pl="0px" color="gray.400">
          <Th pl="0px" borderColor={borderColor} color="gray.400" />
          <Th borderColor={borderColor} color="gray.400">
            Tạo
          </Th>
          <Th borderColor={borderColor} color="gray.400">
            Cập nhật
          </Th>
          <Th borderColor={borderColor} isTruncated color="gray.400">
            Xoá
          </Th>
          <Th borderColor={borderColor} isTruncated color="gray.400">
            Hiển thị
          </Th>
          <Th borderColor={borderColor} color="gray.400" >
            Hiển thị tất cả
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data?.map((row, index, arr) => {
          const isLast = index === arr.length - 1 ? true : false;

          return (
            <Tr key={index}>
              <Td minWidth={{ sm: '160px' }} p="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                  <Text fontSize="14px" fontWeight="bold" minWidth="100%">
                    {PermissionValue[row?.groupName?.toUpperCase()] || ''}
                  </Text>
                </Flex>
              </Td>
              <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex justifyContent="center">
                  {row?.isCreate ? <CheckIcon color="green.400" boxSize={3} /> : <CloseIcon color="red.400" boxSize={3} />}
                </Flex>
              </Td>
              <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex justifyContent="center">
                  {row?.isUpdate ? <CheckIcon color="green.400" boxSize={3} /> : <CloseIcon color="red.400" boxSize={3} />}
                </Flex>
              </Td>
              <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex justifyContent="center">
                  {row?.isDelete ? <CheckIcon color="green.400" boxSize={3} /> : <CloseIcon color="red.400" boxSize={3} />}
                </Flex>
              </Td>
              <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex justifyContent="center">
                  {row?.isShow ? <CheckIcon color="green.400" boxSize={3} /> : <CloseIcon color="red.400" boxSize={3} />}
                </Flex>
              </Td>
              <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
                <Flex justifyContent="center">
                  {row?.isShowAll ? <CheckIcon color="green.400" boxSize={3} /> : <CloseIcon color="red.400" boxSize={3} />}
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default PermissionDetail;