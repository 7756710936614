import { Box, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import TableBody from "./TableBody";
import { useState } from "react";

const TableCampaignGroups = (props) => {
  const { data, loading, handelDetail, handelUpdate, deleteCampaignGroup } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Box overflowX={isEmpty(data) && !loading ? "unset" : "scroll"} className='custom-scrollbar-track'>
      <Table variant="simple" color={textColor}>
        <Thead>
          <Tr my=".8rem" pl="0px" color="gray.400">
            <Th pl="0px" borderColor={borderColor} color="gray.400">
              Tên nhóm
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Người tạo
            </Th>
            {/* <Th borderColor={borderColor} color="gray.400">
              email
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Só điện thoại
            </Th> */}
            <Th borderColor={borderColor} color="gray.400">
              Tổng số chiến dịch
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Ngân sách tối đa 1 ngày
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Ngày tạo
            </Th>
            <Th borderColor={borderColor} color="gray.400">
              Thao tác
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((row, index, arr) => {
            return (
              <TableBody
                key={row._id}
                campaignGroup={row}
                isLast={index === arr.length - 1 ? true : false}
                handelDetail={handelDetail}
                handelUpdate={handelUpdate}
                deleteCampaignGroup={deleteCampaignGroup}
              />
            );
          })}
          {isEmpty(data) && !loading && (
            <Tr>
              <Td colSpan="9">
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} textAlign="center" height="200px">
                  Không có dữ liệu
                </Box>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
};

export default TableCampaignGroups;