import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { API_ROUTES, ManagerStatus, ManagerStatusLabel, ManagerStatusOption, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import DatePickerController from 'components/Form/DatePickerController';
import SelectController from 'components/Form/SelectController';
import { BlockManagerValidate } from 'utils/validation';

const defaultValues = {
  status: { label: ManagerStatusLabel.Normal, value: ManagerStatus.Normal },
};

const BlockPublisher = ({ isOpen, publisherDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading }, blockManagerApi] = useAxios(
    {
      url: API_ROUTES.BlockPublisher,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(BlockManagerValidate),
    defaultValues,
  });
  const watchStatus = watch('status');

  useEffect(() => {
    if (!isEmpty(publisherDetail)) {
      reset({
        status: ManagerStatusOption.find(item => item?.value === publisherDetail?.status) || {
          label: ManagerStatusLabel.Normal,
          value: ManagerStatus.Normal,
        },
        ...(publisherDetail?.dateUnlock && { date: moment(publisherDetail.dateUnlock).toDate() }),
      });
    }
  }, [publisherDetail]);

  const handleSuccess = () => {
    toast({
      title: 'Khóa nhà xuất bản thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.(ModalType.Block);
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Khóa nhà xuất bản thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    blockManagerApi({
      data: {
        publisherID: publisherDetail?._id,
        status: values?.status?.value,
        ...(values?.date && { date: moment(values?.date).startOf('day').toISOString() }),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box w={"100%"}>
      <form>
        <SelectController
          control={control}
          name="status"
          label="Trạng thái"
          isRequired
          options={ManagerStatusOption}
          styleContainer={{ pb: 4 }}
        />
        {watchStatus?.value === ManagerStatus.Temporary && (
          <DatePickerController
            isRequired
            control={control}
            name="date"
            label="Thời gian khóa"
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
          />
        )}
      </form>
      <Flex justifyContent={"end"}>
        {/* <Button
          ref={cancelRef}
          onClick={() => onClose()}
        >
          Hủy
        </Button> */}
        <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
          Xác nhận
        </Button>
      </Flex>
    </Box >
  );
};

export default BlockPublisher;
