import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { DeleteIcon, EditIcon, LockIcon } from '@chakra-ui/icons';
import { API_ROUTES, ManagerStatusLabel, MessageApi, ModalType, Roles } from 'utils/constant';
import moment from 'moment';
import { BiCommentDetail } from 'react-icons/bi';
import capitalize from 'lodash/capitalize';
import uniqBy from 'lodash/uniqBy';
import { axiosPost } from 'utils/api';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Row({ user, isLast, handelUpdateUser, refetch }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const bgStatus = useColorModeValue('gray.400', 'navy.900');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { userInfo } = useUserState();

  const deleteUser = async () => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa ?');
    if (!confirmDelete) {
      return;
    }
    try {
      const data = {
        userID: user?.id,
      };

      const response = await axiosPost(API_ROUTES.DeleteUser, data);
      if (response.data.code === 0) {
        toast({
          title: 'Xoá tài khoản quản trị thành công',
          duration: 9000,
          status: 'success',
        });
        refetch?.();
      }
    } catch (error) {
      toast({
        title:
          MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Xoá tài khoản quản trị thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '250px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {user?.username}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {user?.email}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
              <Text mb={2}>
                {user?.role === Roles.GUIDE ? Roles.MANAGER : user?.role}
              </Text>
            {/* {user?.groups?.map((item, index) => (
              <Flex key={index} direction={'column'} alignItems={'start'} gap={'2px'}>
                <Text>
                  {item?.isCreate && 'Tạo'}
                </Text>
                <Text>
                  {item?.isDelete && 'Xoá'}
                </Text>
                <Text>
                  {item?.isShow && 'Hiển thị'}
                </Text>
                <Text>
                  {item?.isShowAll && 'Hiển thị tất cả'}
                </Text>
                <Text>
                  {item?.isUpdate && 'Cập nhật'}
                </Text>
              </Flex>
            ))} */}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {ManagerStatusLabel[user?.status] || 'Bình thường'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" isTruncated>
            {moment.utc(user?.createdAt).format('YYYY-MM-DD')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Detail);
            }}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Detail);
            }}
          >
            <BiCommentDetail />
          </IconButton>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(user, ModalType.Block);
            }}
          >
            <LockIcon />
          </IconButton> */}
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_ADMIN'}>
            <IconButton
              p={2}
              textColor="red"
              bg="transparent"
              onClick={() => {
                deleteUser();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </AuthorizationComponentWrapper>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
