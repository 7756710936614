import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, MessageApi, initialFilter } from 'utils/constant';
import Row from './components/Row';
import { ModalType } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
// import BlockPublisherModal from './components/BlockPublisherModal';
// import DetailInfoModal from './components/Detail';
// import ChangePasswordModal from './components/ChangePasswordModal';
import Loading from 'components/Layout/Loading';
import PublisherInformation from './components/PublisherInformation';

const Publisher = () => {
  const toast = useToast();
  const [filter, setFilter] = useState({
    ...initialFilter,
    role: 'all',
  });
  const [publisher, setPublisher] = useState([]);
  const [publisherDetail, setPublisherDetail] = useState(null);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isOpenBlockModal, onOpen: onOpenBlockModal, onClose: onCloseBlockModal } = useDisclosure();
  const { isOpen: isOpenDetailModal, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();
  const { isOpen: isOpenPasswordModal, onOpen: onOpenChangePasswordModal, onClose: onCloseChangePasswordModal } = useDisclosure();

  const openModal = useMemo(
    () => ({
      [ModalType.Block]: onOpenBlockModal,
    }),
    [onOpenBlockModal]
  );
  const openDetailModal = useMemo(
    () => ({
      [ModalType.Detail]: onOpenDetailModal,
    }),
    [onOpenDetailModal]
  );
  const openChangePasswordModal = useMemo(
    () => ({
      [ModalType.ChangePassword]: onOpenChangePasswordModal,
    }),
    [onOpenChangePasswordModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Block]: onCloseBlockModal,
    }),
    [onCloseBlockModal]
  );
  const closeDetailModal = useMemo(
    () => ({
      [ModalType.Detail]: onCloseDetailModal,
    }),
    [onCloseDetailModal]
  );
  const closeChangePasswordModal = useMemo(
    () => ({
      [ModalType.ChangePassword]: onCloseChangePasswordModal,
    }),
    [onCloseChangePasswordModal]
  );

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.Publisher,
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    },
    { manual: true }
  );

  useEffect(() => {
    refetch({
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    })
      .then(res => {
        setPublisher(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handelUpdateUser = (publisherDetail, modalType) => {
    setPublisherDetail(publisherDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setPublisherDetail(null);
  };

  const handelUpdateUserDetail = (publisherDetail, modalType) => {
    setPublisherDetail(publisherDetail);
    openDetailModal?.[modalType]?.();
  };

  const handelCloseModalDetail = modalType => {
    closeDetailModal?.[modalType]?.();
    setPublisherDetail(null);
  };

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, role: filter?.role !== 'all' ? filter?.role : '' },
    })
      .then(res => {
        setPublisher(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handelChangePassword = (publisher, modalType) => {
    setPublisherDetail(publisher);
    openChangePasswordModal?.[modalType]?.();
  };

  const handelCloseModalChangePassword = modalType => {
    closeChangePasswordModal?.[modalType]?.();
    setPublisherDetail(null);
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
              Publisher
              Nhà xuất bản
            </Text> */}
          {/* <Text pt="22px" mt="2px" fontSize="sm" color={textColor} fontWeight="bold">
              Search options
            </Text> */}
          <Flex flexWrap="wrap">
            <InputSearch
              value={filter?.searchKeyword}
              width={'300px'}
              onChange={onChangeSearch}
              placeholder="Tìm kiếm Tài khoản, email"
              onClearSearch={onClearSearch}
            />
            <Button variant="primary" maxH="40px" fontSize="12px" onClick={onFilter}>
              Lọc
            </Button>
          </Flex>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <Loading />
            </Flex>
          ) : (
            <>
              <Box width={'100%'} overflowX={isEmpty(publisher) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th pl="0px" borderColor={borderColor} color="gray.400" isTruncated>
                        Tài khoản
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Email
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Số điện thoại
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Trạng thái
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Số dư
                      </Th>
                      <Th borderColor={borderColor} color="gray.400" isTruncated>
                        Ngày tạo
                      </Th>
                      <Th borderColor={borderColor}>Thao tác</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {publisher?.map((row, index, arr) => {
                      return (
                        <Row
                          key={row._id}
                          publisherDetail={row}
                          isLast={index === arr.length - 1 ? true : false}
                          handelUpdateUser={handelUpdateUser}
                          handelUpdateUserDetail={handelUpdateUserDetail}
                          handelChangePassword={handelChangePassword}
                        />
                      );
                    })}
                    {isEmpty(publisher) && !loading && (
                      <Tr>
                        <Td colSpan="8">
                          <Box textAlign="center" height="200px" pt="24px">
                            No data
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Box>
              {!isEmpty(publisher) && (
                <Flex justifyContent={'flex-end'}>
                  <Pagination
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      refetch({
                        params: { role: filter?.role !== 'all' ? filter?.role : '', pageSize: pageLength, pageIndex: page - 1 },
                      }).then(res => {
                        setPublisher(res?.data?.data);
                        setFilter({
                          ...filter,
                          pageSize: pageLength,
                          pageIndex: page - 1,
                        });
                      });
                    }}
                  />
                </Flex>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {/* {isOpenBlockModal && (
        <BlockPublisherModal
          publisherDetail={publisherDetail}
          isOpen={isOpenBlockModal}
          onClose={handelCloseModal}
          refetchData={handleRefetchData}
        />
      )} */}
      {isOpenDetailModal && (
        <PublisherInformation
          publisherDetail={publisherDetail}
          isOpen={isOpenDetailModal}
          onClose={handelCloseModalDetail}
          refetchData={handleRefetchData}
        />
      )}
      {/* {isOpenPasswordModal && (
        <ChangePasswordModal publisherDetail={publisherDetail} isOpen={isOpenPasswordModal} onClose={handelCloseModalChangePassword} />
      )} */}
    </>
  );
};

export default Publisher;
