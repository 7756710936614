import { Flex, IconButton, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import { FaTrash } from 'react-icons/fa';
import { BsPencilFill } from 'react-icons/bs';
import { API_ROUTES, ModalType } from 'utils/constant';
import { axiosPost } from 'utils/api';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const TableBody = props => {
  const { ticketCategory, isLast, refetch, handleOpentModal } = props;
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const {userInfo} = useUserState();

  const handleRemoveTicketCategory = async id => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa danh mục ticket không ?');
    if (!confirmDelete) {
      return;
    }

    try {
      let response;
      response = await axiosPost(API_ROUTES.deleteTicketCategory, { categoryId: id });
      if (response.data.code === 0) {
        toast({
          title: 'Tạo thông tin danh mục ticket thành công.',
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        refetch();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Tạo thông tin ticket thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Tr>
      <Td minWidth={{ sm: '150px' }} pl="0px" maxWidth={'250px'} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {ticketCategory?.name}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {formatDate(ticketCategory?.createdAt, 'DD-MM-YYYY')}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_TICKET_CATEGORY'}>
          <IconButton p={2} bg="transparent" onClick={() => handleOpentModal(ModalType.CreateTicketCategory, ticketCategory)}>
            <BsPencilFill size={16} />
          </IconButton>
        </AuthorizationComponentWrapper>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_TICKET_CATEGORY'}>
          <IconButton p={2} bg="transparent" onClick={() => handleRemoveTicketCategory(ticketCategory._id)}>
            <FaTrash color='red.400' size={16} />
          </IconButton>
        </AuthorizationComponentWrapper>
        </Flex>
      </Td>
    </Tr>
  );
};

export default TableBody;
