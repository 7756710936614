import { checkAccessScreen, isJsonString } from 'utils/helpers';

/**
 *
 * WithAuthorization
 *
 */
function AuthorizationWrapper({ children, allowedRoles, disabled = false, groupPermission, groupName }) {
  const userInfoLocalStorage = localStorage.getItem('isUserShow');
  const user = isJsonString(userInfoLocalStorage);

  const userRoles = user?.role || [];
  const hasRole = (allowedRoles || []).some(role => userRoles.includes(role));
  const isAccess = checkAccessScreen({ userRoles, groupName, groupPermission });

  if (user?.level === '1' || (hasRole && isAccess) || (!allowedRoles && !userRoles.length)) {
    return children;
  } else {
    if (disabled) {
      return children;
    }
    return null;
  }
}

export default AuthorizationWrapper;
