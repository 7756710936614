import React from 'react';
import { HomeIcon, PersonIcon } from 'components/Icons/Icons';
import { IoIosLink, IoMdGitCommit } from 'react-icons/io';
import { BiCategory } from 'react-icons/bi';
import { CgWebsite } from 'react-icons/cg';
import { TbBrandPowershell } from 'react-icons/tb';
import { FiUsers } from 'react-icons/fi';
import { RiAdvertisementLine, RiLuggageDepositFill } from 'react-icons/ri';
import { CiViewBoard } from 'react-icons/ci';
import { MdAdsClick, MdPublic, MdCampaign, MdOutlineSettings } from 'react-icons/md';
import { GiMeepleGroup } from 'react-icons/gi';
import { BsFillSignpostSplitFill } from 'react-icons/bs';
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { SettingsIcon } from '@chakra-ui/icons';
import { Roles } from 'utils/constant';
import SignIn from 'views/Pages/SignIn';
import WebSiteCategory from 'views/Dashboard/WebSiteCategory/WebSiteCategory';
import DirectLink from 'views/Dashboard/DirectLink/DirectLink';
import WebSite from 'views/Dashboard/WebSite/WebSite';
import AdsUnit from './views/Dashboard/AdsUnit/AdsUnit';
import Dashboard from 'views/Dashboard/Dashboard';
import GroupPermission from 'views/Dashboard/GroupPermission';
import User from 'views/Dashboard/User/User';
import Advertiser from 'views/Dashboard/Advertiser';
import Publisher from 'views/Dashboard/Publisher';
import Campaign from 'views/Dashboard/Campaign';
import Post from 'views/Dashboard/Post/Post';
import CreateSettingSite from 'views/Dashboard/SettingSite/CreateSettingSite';
import CreateSocial from 'views/Dashboard/SettingSite/CreateSocial';
import Ticket from 'views/Dashboard/Ticket/Ticket';
import CreateSettingCommission from 'views/Dashboard/SettingCommission/CreateSettingCommission';
import CPMStatistics from 'views/Dashboard/CPMStatistics';
import CPCStatistics from 'views/Dashboard/CPCStatistics';
import ListReferal from 'views/Dashboard/ListReferal';
import CampaignsGroup from 'views/Dashboard/CampaignsGroup';
import TicketCatogory from 'views/Dashboard/TicketCatogory';
import Notification from 'views/Dashboard/Notification';
import Deposit from 'views/Deposit/Deposit ';
import Profile from 'views/Dashboard/Profile';
import WithDraw from 'views/WithDraw/WithDraw';
// import Profile from 'views/Profile';

var dashRoutes = [
  {
    path: '/dashboard',
    name: 'Thống kê',
    icon: <HomeIcon color="inherit" />,
    layout: '/admin',
    component: Dashboard,
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_DASHBOARD',
  },
  {
    path: '/website',
    name: 'Website',
    icon: <CgWebsite color="inherit" />,
    component: WebSite,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_WEBSITE',
  },
  {
    path: '/category',
    name: 'Danh mục',
    icon: <BiCategory color="inherit" />,
    component: WebSiteCategory,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_CATEGORY',
  },
  {
    path: '/ads-unit',
    name: 'Đơn vị quảng cáo',
    icon: <BiCategory color="inherit" />,
    component: AdsUnit,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_ADS',
  },
  {
    path: '/direct-link',
    name: 'Liên kết',
    icon: <IoIosLink color="inherit" />,
    component: DirectLink,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_DIRECT_LINK',
  },
  // {
  //   path: '/group-permission',
  //   name: 'Nhóm phân quyền',
  //   icon: <TbBrandPowershell color="inherit" />,
  //   component: GroupPermission,
  //   layout: '/admin',
  //   role: [Roles.ADMIN],
  //   groupName: 'ASSIGN_PERMISSION_ADMIN',
  // },
  {
    path: '/manager',
    name: 'Tài khoản quản trị',
    icon: <FiUsers color="inherit" />,
    component: User,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_ADMIN',
  },
  {
    path: '/advertiser',
    name: 'Nhà quảng cáo',
    icon: <RiAdvertisementLine color="inherit" />,
    component: Advertiser,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_ADVERTISER',
  },
  {
    path: '/publisher',
    name: 'Nhà xuất bản',
    icon: <MdPublic color="inherit" />,
    component: Publisher,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_PUBLISHER',
  },
  // {
  //   path: '/campaign',
  //   name: 'Campaign',
  //   icon: <MdCampaign color="inherit" />,
  //   component: Campaign,
  //   layout: '/admin',
  //   role: [Roles.ADMIN, Roles.GUIDE],
  //   groupName: 'advertiser',
  // },
  // {
  //   path: '/cpc',
  //   name: 'CPC',
  //   icon: <MdAdsClick color="inherit" />,
  //   component: Campaign,
  //   layout: '/admin',
  //   role: [Roles.ADMIN, Roles.GUIDE],
  //   groupName: 'advertiser',
  // },
  {
    path: '/cpm-statistics',
    name: 'Thống kê CPM',
    icon: <CiViewBoard color="inherit" />,
    component: CPMStatistics,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_CPM_STASTISCTIC',
  },
  {
    path: '/cpc-statistics',
    name: 'Thống kê CPC',
    icon: <MdAdsClick color="inherit" />,
    component: CPCStatistics,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_CPC_STASTISCTIC',
  },
  {
    path: '/campaign-group',
    name: 'Nhóm chiến dịch',
    icon: <GiMeepleGroup color="inherit" />,
    component: CampaignsGroup,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_CAMPAIGN_GROUP',
  },
  {
    path: '/campaign',
    name: 'Chiến dịch',
    icon: <MdCampaign color="inherit" />,
    component: Campaign,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_CAMPAIGN',
  },
  {
    path: '/post',
    name: 'Bài đăng',
    icon: <BsFillSignpostSplitFill color="inherit" />,
    component: Post,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_POST',
  },
  {
    path: '/ticket-category',
    name: 'Danh mục ticket',
    icon: <BiCategory color="inherit" />,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    component: TicketCatogory,
    groupName: 'VIEW_LIST_TICKET_CATEGORY',
  },
  {
    path: '/ticket',
    name: 'Ticket',
    icon: <SettingsIcon color="inherit" />,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
    component: Ticket,
    groupName: 'VIEW_LIST_TICKET',
  },
  {
    path: '/setting-site',
    name: 'Cài đặt trang web',
    icon: <MdOutlineSettings color="inherit" />,
    component: CreateSettingSite,
    groupName: 'UPDATE_CONTENT',
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
  },
  {
    path: '/setting-commission',
    name: 'Cài đặt hoa hồng',
    icon: <IoMdGitCommit color="inherit" />,
    component: CreateSettingCommission,
    groupName: 'UPDATE_SETTING_COMMISSION',
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
  },
  {
    path: '/notification',
    name: 'Thông báo',
    icon: <IoNotificationsCircleSharp color="inherit" />,
    component: Notification,
    groupName: 'VIEW_LIST_NOTIFICATION',
    layout: '/admin',
    role: [Roles.ADMIN, Roles.GUIDE],
  },
  {
    path: '/deposit',
    name: 'Quản lý nạp tiền',
    icon: <RiLuggageDepositFill color="inherit" />,
    layout: '/admin',
    component: Deposit,
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_DEPOSIT',
  },
  {
    path: '/withdraw',
    name: 'Quản lý rút tiền',
    icon: <RiLuggageDepositFill color="inherit" />,
    layout: '/admin',
    component: WithDraw,
    role: [Roles.ADMIN, Roles.GUIDE],
    groupName: 'VIEW_LIST_WITHDRAW',
  },
  {
    name: 'Tài khoản',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Cá nhân',
        icon: <PersonIcon color="inherit" />,
        component: Profile,
        layout: '/admin',
        groupName: 'UPDATE_INFORMATION_ADMIN',
        role: [Roles.ADMIN, Roles.MANAGER, Roles.USER, Roles.GUEST, Roles.GUIDE],
      },
      {
        path: '/signin',
        component: SignIn,
        layout: '/auth',
        redirect: true,
      },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
  {
    path: '/create-social',
    name: 'Create Social',
    component: CreateSocial,
    layout: '/admin',
    redirect: true,
  },
  {
    path: '/list-referal/:id/advertiser',
    name: 'Danh sách giới thiệu',
    redirect: true,
    subPath: '/advertiser',
    categoryName: 'Nhà quảng cáo',
    layout: '/admin',
    groupName: 'VIEW_LIST_REFERRAL_ADVERTISER',
    component: ListReferal,
    role: [Roles.ADMIN],
  },
  {
    path: '/list-referal/:id/publisher',
    name: 'Danh sách giới thiệu',
    redirect: true,
    categoryName: 'Nhà xuất bản',
    subPath: '/publisher',
    layout: '/admin',
    groupName: 'VIEW_LIST_REFERRAL_PUBLISHER',
    component: ListReferal,
    role: [Roles.ADMIN],
  },
];
export default dashRoutes;
