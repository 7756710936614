import React, { useState } from 'react';
import { Button, Flex, Table, Tbody, Text, Th, Thead, Tr, useColorModeValue, FormControl, Td, Box, useDisclosure } from '@chakra-ui/react';
import InputSearch from 'components/InputSearch/InputSearch';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { formatDate } from 'utils/helpers';
import CreatePost from './components/CreatePostModal';
import { axiosGet } from 'utils/api';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Post() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(initialFilter);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ data, loading }, refetch] = useAxios({
    url: API_ROUTES.Posts,
    params: filter,
  });
  const [postEdit, setPostEdit] = useState(null);
  const { userInfo } = useUserState();

  const handleSearchKeywordChange = e => {
    setSearchKeyword(e.target.value);
  };

  const handleSearch = () => {
    setFilter({ searchKeyword, ...initialFilter });
  };

  const resetSearchKeywords = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const handleAddRecord = () => {
    setPostEdit(null);
    onOpen();
  };

  const handleGetPostDetail = async postId => {
    const { data } = await axiosGet(`${API_ROUTES.Posts}/${postId}`);
    if (!isEmpty(data?.data)) setPostEdit(data?.data);
    onOpen();
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="0px 0px 22px 0px">
          <Flex alignItems={"end"}>
            <FormControl display="flex" alignItems="center" gap={4}>
              <Flex flexDirection="column" gap={1}>
                <Text fontSize="sm" color={textColor} fontWeight="bold">
                  Tìm kiếm
                </Text>
                <InputSearch
                  width={'300px'}
                  value={searchKeyword}
                  onClearSearch={resetSearchKeywords}
                  onChange={handleSearchKeywordChange}
                  placeholder="Tìm kiếm tiêu đề"
                />
              </Flex>
              <Flex alignSelf={'end'} gap={4}>
                {/* <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
                Đặt lại
              </Button> */}
                <Button w="100px" variant="primary" maxH="40px" fontSize="12px" fontWeight="bolder" onClick={handleSearch}>
                  Lọc
                </Button>
              </Flex>
            </FormControl>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_POST'}>
              <Flex justify={'flex-end'} alignItems={'center'}>
                <Button w="100px" variant="primary" maxH="40px" my="10px" fontSize="12px" fontWeight="bolder" onClick={handleAddRecord}>
                  Thêm
                </Button>
              </Flex>
            </AuthorizationComponentWrapper>
          </Flex>
        </CardHeader>
        <CardBody w="100%" overflowX="auto">
          {loading ? (
            <Loading />
          ) : (
            <Box width={'100%'} overflowX={isEmpty(data?.data) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    {['Tiêu đề', 'Người sở hữu', 'Loại', 'Trạng thái đã xuất bản', 'Ngày', 'Thao tác'].map((header, index) => (
                      <Th key={index} pr="100px" borderColor={borderColor} color="gray.400">
                        {header}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map(row => (
                    <Row
                      key={row._id}
                      row={row}
                      id={row._id}
                      type={row.type}
                      title={row.title}
                      status={row.isPublished}
                      owner={row.username}
                      day={formatDate(row.createdAt)}
                      refetch={refetch}
                      handleGetPostDetail={handleGetPostDetail}
                    />
                  ))}
                </Tbody>
                {isEmpty(data?.data) && !loading && (
                  <Tr>
                    <Td colSpan="6">
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                        Không có dữ liệu
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Table>
              {!isEmpty(data?.data) && (
                <Flex justifyContent="flex-end">
                  <Pagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={(page, pageLength) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
              )}
            </Box>
          )}
        </CardBody>
      </Card>
      <CreatePost isOpen={isOpen} onClose={onClose} refetch={refetch} postEdit={postEdit} setPostEdit={setPostEdit} />
    </Flex>
  );
}

export default Post;
