import moment from 'moment-timezone';
import { Roles } from './constant';

export const isJsonString = str => {
  if (str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export function rangeDesc(start, end) {
  return Array(start - end + 1)
    .fill()
    .map((_, idx) => start - idx);
}

export const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');

export const formatDate = (date, format = 'MM/DD/YYYY') => moment(date).format(format);

export const getInitFilerChart = () => ({
  startDate: new Date(formatDate(moment(new Date()).subtract(6, 'days'))),
  endDate: new Date(formatDate(new Date())),
});
export const downloadFile = (file, name) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `${name}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const checkAccessScreen = ({ userRoles, groupName, groupPermission }) => {
  if (userRoles.includes(Roles.ADMIN) || !groupName) return true;

  const groupIndex = groupPermission?.findIndex(item => item === groupName);

  return groupIndex > -1 ? true : false;
};

export const convertDate = dateString => {
  const date = new Date(moment(dateString).add(1, 'days'));;
  date.setHours(0, 0, 0, 0);
  return date.toISOString().split("T")[0] + "T00:00:01.000Z";
};

export function currencyFormat(x) {
  if (!x) {
    return '0 VND';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' VND';
}