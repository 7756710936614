import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box,
  useToast,
} from '@chakra-ui/react';
import { API_ROUTES, ChangeStatusDrawOptions, StatusWithDrawOptions, TabWithDraw } from 'utils/constant';
import { ModalType } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import VTcontent from 'components/VerticalTab/VerticalContent';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { BiCommentDetail } from 'react-icons/bi';
import { LockIcon } from '@chakra-ui/icons';
import { useUserState } from 'context/UserContext';
import Detail from './Detail';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabWithDraw.Detail,
        name: TabWithDraw.Detail,
        iconMenu: <BiCommentDetail />,
      }
    },
    {
      infor: {
        tab: TabWithDraw.ChangeStatus,
        name: TabWithDraw.ChangeStatus,
        iconMenu: <LockIcon />,
      }
    }
  ]
};

const ChangeStatus = ({ isOpen, onClose, refetchData, transaction }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [activeTabId, setActiveTabId] = useState(TabWithDraw.Detail);
  function handleClickButton(tab) {
    setActiveTabId(tab);
  }
  const { userInfo } = useUserState();
  const [{ loading }, changeStatusApi] = useAxios(
    {
      url: API_ROUTES.ChangeStatusWithDraw,
      method: 'post',
    },
    { manual: true }
  );

  const handleCloseModal = () => onClose?.(ModalType.ChangeStatus);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      status: undefined,
    },
  });
  const handleSuccess = () => {
    toast({
      title: 'Cập nhật trạng thái thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData();
    onClose?.(ModalType.ChangeStatus);
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Cập nhật trạng thái thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };
  const onSubmit = values => {
    changeStatusApi({ data: { status: values.status?.value, transactionId: transaction?._id } })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxWidth={'550px'}>
          <AlertDialogHeader textTransform="uppercase">{activeTabId===TabWithDraw.Detail ? "Chi tiết giao dịch rút tiền":"Duyệt rút tiền"}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={'16px'} minHeight={'100px'}>
            <Wrap w={'100%'}>
              <Grid w={'100%'} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w="100%">
                  <Flex bg={'white'} borderRadius={4}>
                    <Flex gap={'10px'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                      {sideMenu.data.map((item, index) => (
                        <li key={index} style={{ listStyle: 'none', textAlign: 'left' ,width:'50%'}}>
                          <Button
                            flex={'auto'}
                            gap={'10px'}
                            onClick={() => handleClickButton(item.infor.tab)}
                            bg={activeTabId === item.infor.tab ? 'gray.100' : 'transparent'}
                            style={activeTabId === item.infor.tab ? { color: '#3182ce' } : { color: '#8892b0' }}
                            width={'100%'}
                          >
                            {item.infor.iconMenu}
                            {item.infor.name}
                          </Button>
                        </li>
                      ))}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem w={'100%'}>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_LIST_WITHDRAW'}>
                    <VTcontent
                      key={TabWithDraw.Detail}
                      index={TabWithDraw.Detail}
                      activeTabId={activeTabId}
                    >
                      <Detail transaction={transaction}/>
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'LOCK_PUBLISHER'}>
                    <VTcontent
                      key={TabWithDraw.ChangeStatus}
                      index={TabWithDraw.ChangeStatus}
                      activeTabId={activeTabId}
                    >
                      <Box width={'100%'}>
                        <form>
                          <SelectController
                            control={control}
                            name="status"
                            label="Trạng thái"
                            isRequired
                            options={ChangeStatusDrawOptions}
                          />
                        </form>
                        <Flex mt={'16px'} justifyContent={'end'}>
                          <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
                            Câp nhật
                          </Button>
                        </Flex>
                      </Box>
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ChangeStatus;
