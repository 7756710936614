import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { ModalType } from 'utils/constant';

function DetailCampaign({ isOpen, campaignDetail, onClose }) {
  const cancelRef = React.useRef();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => onClose(ModalType.Detail)}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW="600px">
        <AlertDialogHeader>Thông tin chiến dịch</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text pb="12px" fontSize="lg">
            <strong>ID:</strong> {campaignDetail?._id}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Tên chiến dịch:</strong> {campaignDetail?.campaignName || campaignDetail?.title}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Loại chiến dịch:</strong> {campaignDetail?.type}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Nhóm chiến dịch:</strong> {campaignDetail?.campaignGroup?.groupName}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Danh mục website:</strong> {campaignDetail?.categories?.map(item => item?.name)}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Người tạo:</strong> {campaignDetail?.advertiser?.username || 'N/A'}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Chi phí một lần click:</strong> {campaignDetail?.CPC}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Chi phí mỗi mile:</strong> {campaignDetail?.CPM}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Trạng thái:</strong> {campaignDetail?.status || 'Normal'}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Tiền còn lại:</strong> {campaignDetail?.totalBudget || 0}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Ngày tạo:</strong> {moment(campaignDetail?.createdAt).format('DD-MM-YYYY')}
          </Text>
          <Text pb="12px" fontSize="lg">
            <strong>Ngày cập nhật:</strong> {moment(campaignDetail?.updatedAt).format('DD-MM-YYYY')}
          </Text>
        </AlertDialogBody>
        <AlertDialogFooter></AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default DetailCampaign;
