import React from 'react';
import { Flex, IconButton, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import { EditIcon } from '@chakra-ui/icons';
import { BiCommentDetail } from 'react-icons/bi';
import { ModalType } from 'utils/constant';
import moment from 'moment';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Row({ campaignDetail, isLast, handelUpdateUser }) {
  const textColor = useColorModeValue('gray.500', 'white');
  const titleColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { userInfo } = useUserState();
  return (
    <Tr>
      <Td minWidth={{ sm: '150px' }} pl="0px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="md" color={titleColor} fontWeight="bold" minWidth="100%">
            {campaignDetail?.campaignName || campaignDetail?.title}
          </Text>
        </Flex>
      </Td>
      {/* <Td minWidth={{ sm: '150px' }} maxWidth={"250px"} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.categories?.map(item => item.name).toString()}
          </Text>
        </Flex>
      </Td> */}
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.type}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.campaignGroup?.groupName}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.advertiser?.username}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.CPC}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.CPM}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.status || 'Normal'}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {campaignDetail?.totalBudget || 0}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {moment(campaignDetail?.createdAt).format("YYYY-MM-DD")}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_CAMPAIGN'}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(campaignDetail, ModalType.Detail);
            }}
          >
            <BiCommentDetail size={20} />
          </IconButton>
        </AuthorizationComponentWrapper>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_STATUS_CAMPAIGN'}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(campaignDetail, ModalType.ChangeStatus);
            }}
          >
            <EditIcon size={20} />
          </IconButton>
        </AuthorizationComponentWrapper>
        <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ASSIGN_WEBSITE_FOR_CAMPAIGN'}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handelUpdateUser(campaignDetail, ModalType.Assign);
            }}
          >
            <MdAssignmentTurnedIn size={20} />
          </IconButton>
        </AuthorizationComponentWrapper>
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
