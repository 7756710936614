import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import useAxios from 'axios-hooks';
import { API_ROUTES, MessageApi, ModalType } from 'utils/constant';
import { AssignTicketValidate } from 'utils/validation';

const AssignTicketForm = ({ ticketDetail, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const [userOption, setUserOption] = useState([]);
  const toast = useToast();
  const [{ data, loading }] = useAxios(
    {
      url: API_ROUTES.Users,
    },
    { useCache: false }
  );
  const [{ loading: assignLoading }, assignTicketApi] = useAxios(
    {
      url: API_ROUTES.AssignTicket,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignTicketValidate),
    defaultValues: {
      user: undefined,
    },
  });

  useEffect(() => {
    if (!isEmpty(data?.data)) {
      setUserOption(data?.data?.map(item => ({ label: item?.username, value: item?._id })));
    }
  }, [data?.data]);

  useEffect(() => {
    reset({ user: userOption?.find(item => item.value === ticketDetail?.user?._id) });
  }, [ticketDetail, userOption]);

  const handleSuccess = () => {
    toast({
      title: 'Chọn người xử lý thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetch?.();
    onClose();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Chọn người xử lý thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    assignTicketApi({ data: { userID: values?.user?.value, ticketID: ticketDetail?._id } })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box width={"100%"}>
      <form>
        <SelectController control={control} name="user" label="Người xử lý" isRequired options={userOption} />
      </form>
      <Flex mt={"16px"} justifyContent={"end"}>
        {/* <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Assign);
              }}
            >
              Hủy
            </Button> */}
        <Button colorScheme="blue" ml={3} isLoading={assignLoading} onClick={handleSubmit(onSubmit)}>
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default AssignTicketForm;
