import { Button, Flex, useToast, Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { useForm } from 'react-hook-form';
import { UpdateInformationAdvValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from 'components/Form/InputController';
import { axiosPost } from 'utils/api';
import CheckboxController from 'components/Form/CheckboxController';
import isEmpty from 'lodash/isEmpty';

function UpdateInformation({ publisherDetail, onClose, refetch }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isLoading },
  } = useForm({
    resolver: yupResolver(UpdateInformationAdvValidate),
    defaultValues: {
      email: '',
      username: '',
      phone: '',
      verify: '',
    },
  });
  const toast = useToast();

  const onSubmit = async values => {
    const payload = {
      ...values,
      verify: values?.verify === 'true' ? true : false,
    };
    try {
      let response;
      response = await axiosPost(API_ROUTES.updateInforAdv, payload);
      if (response.data.code === 0) {
        toast({
          title: 'Cập nhật thông tin thành công.',
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Thay đổi mật khẩu thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(publisherDetail)) {
      reset({
        username: publisherDetail?.username,
        phone: publisherDetail?.phone,
        verify: publisherDetail?.verify === 'true' ? true : false,
        email: publisherDetail?.email,
      });
    }
  }, [publisherDetail]);

  return (
    <Box w={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)} className="border border-[#ddd] rounded w-full bg-[#fff] ">
        <div className="w-full">
          <InputController
            name="username"
            label="Tên"
            id={'newpasswordInput'}
            type={'text'}
            control={control}
            styleContainer={{ mt: 4 }}
            readOnly
          />
          <InputController
            name="phone"
            label="Số điện thoại"
            id={'fullnameInput'}
            type={'text'}
            control={control}
            styleContainer={{ mt: 4 }}
          />
          <InputController name="email" label="Email" id={'fullnameInput'} type={'text'} control={control} styleContainer={{ mt: 4 }} />
          <CheckboxController
            name="verify"
            label="Xác thực"
            id={'newpasswordInput'}
            type={'text'}
            control={control}
            styleContainer={{ mt: 4 }}
          />
        </div>
        <Flex justifyContent={'end'} mt={'4'}>
          <Button colorScheme="blue" type="submit" isLoading={isLoading} className="bg-[#c58560] text-white rounded-lg px-6 py-2 text-base">
            Cập nhật
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
export default UpdateInformation;
