import React, { useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box
} from '@chakra-ui/react';
import { TabFeaturePublisher } from 'utils/constant';
import { ModalType } from 'utils/constant';

import { LockIcon } from '@chakra-ui/icons';
import { FiEdit } from "react-icons/fi";
import { BiCommentDetail } from 'react-icons/bi';
import { MdEditSquare } from "react-icons/md";

import VTcontent from 'components/VerticalTab/VerticalContent';
import Detail from './Detail';
import BlockPublisher from './BlockPublisher';
import ChangePassword from './ChangePassword';
import UpdateInformation from './UpdateInformation';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeaturePublisher.PublisherInformation,
        name: TabFeaturePublisher.PublisherInformation,
        iconMenu: <BiCommentDetail />,
      }
    },
    {
      infor: {
        tab: TabFeaturePublisher.LockManagement,
        name: TabFeaturePublisher.LockManagement,
        iconMenu: <LockIcon />,
      }
    },
    {
      infor: {
        tab: TabFeaturePublisher.ChangePassword,
        name: TabFeaturePublisher.ChangePassword,
        iconMenu: <FiEdit />,
      }
    },
    {
      infor: {
        tab: TabFeaturePublisher.UpdateInformation,
        name: TabFeaturePublisher.UpdateInformation,
        iconMenu: <MdEditSquare />,
      }
    }
  ]
};

const PublisherInformation = ({ isOpen, publisherDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const [activeTabId, setActiveTabId] = useState(TabFeaturePublisher.PublisherInformation);
  const { userInfo } = useUserState();

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const handleCloseModal = () => onClose?.(ModalType.Detail);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxWidth={"900px"}>
          <AlertDialogHeader textTransform="uppercase">Quản lý nhà xuất bản {publisherDetail?.username}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={"16px"} minHeight={"400px"}>
            <Wrap w={"100%"}>
              <Grid w={"100%"} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w='100%'>
                  <Flex bg={"white"} borderRadius={4}>
                    <Flex gap={"10px"} alignItems={'center'} justifyContent={'space-between'}>
                      {sideMenu.data.map((item, index) => (
                        <li key={index} style={{ listStyle: "none", textAlign: "left" }}>
                          <Button
                            flex={"auto"}
                            gap={"10px"}
                            onClick={() => handleClickButton(item.infor.tab)}
                            bg={activeTabId === item.infor.tab ? "gray.100" : "transparent"}
                            style={
                              activeTabId === item.infor.tab
                                ? { color: "#3182ce" }
                                : { color: "#8892b0" }
                            }
                          >
                            {item.infor.iconMenu}
                            {item.infor.name}
                          </Button>
                        </li>
                      ))}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem
                  w={"100%"}
                >
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_PUBLISHER'}>
                    <VTcontent
                      key={TabFeaturePublisher.PublisherInformation}
                      index={TabFeaturePublisher.PublisherInformation}
                      activeTabId={activeTabId}
                    >
                      <Detail
                        publisherDetail={publisherDetail}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'LOCK_PUBLISHER'}>
                    <VTcontent
                      key={TabFeaturePublisher.LockManagement}
                      index={TabFeaturePublisher.LockManagement}
                      activeTabId={activeTabId}
                    >
                      <BlockPublisher
                        publisherDetail={publisherDetail}
                        refetchData={refetchData}
                        onClose={handleCloseModal}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_PASSWORD_PUBLISHER'}>
                    <VTcontent
                      key={TabFeaturePublisher.ChangePassword}
                      index={TabFeaturePublisher.ChangePassword}
                      activeTabId={activeTabId}
                    >
                      <ChangePassword
                        publisherDetail={publisherDetail}
                        onClose={handleCloseModal}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_INFORMATION_PUBLISHER'}>
                    <VTcontent
                      key={TabFeaturePublisher.UpdateInformation}
                      index={TabFeaturePublisher.UpdateInformation}
                      activeTabId={activeTabId}
                    >
                      <UpdateInformation
                        publisherDetail={publisherDetail}
                        onClose={handleCloseModal}
                        refetch={refetchData}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default PublisherInformation;
