import { Td, Text, Tr, useColorModeValue, IconButton, useDisclosure, useToast, Flex } from '@chakra-ui/react';
import React from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import UpdateSiteCategory from './UpdateWebSiteCategory';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi } from 'utils/constant';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Row(props) {
  const toast = useToast();
  const { day, name, slug, isLast, refetch, id, updatedAt, countWebsite, countDirectLink } = props;
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useUserState();

  const deleteWebSiteCategory = async () => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa ?');
    if (!confirmDelete) {
      return;
    }
    const categoryId = {
      categoryId: id,
    };
    try {
      const response = await axiosPost(API_ROUTES.DeleteCategory, categoryId);
      if (response.data.code === 0) {
        toast({
          title: 'Xóa danh mục trang web thành công.',
          duration: 9000,
        });
        refetch();
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Xóa danh mục trang web thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {countWebsite}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {countDirectLink}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {updatedAt}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_CATEGORY'}>
            <IconButton bg="transparent" onClick={onOpen}>
              <EditIcon />
            </IconButton>
          </AuthorizationComponentWrapper>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_CATEGORY'}>
            <IconButton
              bg="transparent"
              color="red.400"
              onClick={() => {
                deleteWebSiteCategory();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </AuthorizationComponentWrapper>
          </Flex>
        </Td>
      </Tr>
      {isOpen && <UpdateSiteCategory id={id} name={name} refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </>
  );
}

export default Row;
