import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box
} from '@chakra-ui/react';
import { TabFeatureAdvertiser, TabFeatureManagement } from 'utils/constant';
import { defaultPassword } from 'utils/constant';
import { ModalType } from 'utils/constant';

import { ImProfile } from "react-icons/im";
import { TbLock } from "react-icons/tb";
import { LockIcon } from '@chakra-ui/icons';
import { FiEdit } from "react-icons/fi";
import { BiCommentDetail } from 'react-icons/bi';
import { FaPen } from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";

import { MdAssignmentTurnedIn } from 'react-icons/md';

import VTcontent from 'components/VerticalTab/VerticalContent';
import Detail from './Detail';
import AssignManager from './AssignManager';
import BlockAdvertiser from './BlockAdvertiser';
import ChangePassword from './ChangePassword';
import UpdateInformation from 'views/Dashboard/Advertiser/components/UpdateInformation';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeatureAdvertiser.AdvertiserInformation,
        name: TabFeatureAdvertiser.AdvertiserInformation,
        iconMenu: <BiCommentDetail />,
      }
    },
    {
      infor: {
        tab: TabFeatureAdvertiser.ChooseManagement,
        name: TabFeatureAdvertiser.ChooseManagement,
        iconMenu: <MdAssignmentTurnedIn />,
      }
    },
    {
      infor: {
        tab: TabFeatureAdvertiser.LockManagement,
        name: TabFeatureAdvertiser.LockManagement,
        iconMenu: <LockIcon />,
      }
    },
    {
      infor: {
        tab: TabFeatureAdvertiser.ChangePassword,
        name: TabFeatureAdvertiser.ChangePassword,
        iconMenu: <FiEdit />,
      }
    },
    {
      infor: {
        tab: TabFeatureAdvertiser.UpdateInformation,
        name: TabFeatureAdvertiser.UpdateInformation,
        iconMenu: <IoIosInformationCircle />,
      }
    }
  ]
};

const AdvertiserInformation = ({ isOpen, advertiserDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const [activeTabId, setActiveTabId] = useState(TabFeatureAdvertiser.AdvertiserInformation);
  const { userInfo } = useUserState();
  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const handleCloseModal = () => onClose?.(ModalType.Detail);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxWidth={"1200px"}>
          <AlertDialogHeader textTransform="uppercase">QUẢN LÝ NHÀ QUẢNG CÁO {advertiserDetail?.username}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={"16px"} minHeight={"400px"} maxWidth={"1200px"}>
            <Wrap w={"100%"}>
              <Grid w={"100%"} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w='100%'>
                  <Flex w='100%' bg={"white"} borderRadius={4}>
                    <Flex w='100%' gap={"10px"} alignItems={'center'} justifyContent={'space-between'} overflowX="scroll" className='no-scrollbar'>
                      {sideMenu.data.map((item, index) => (
                        <li key={index} style={{ listStyle: "none", textAlign: "left" }}>
                          <Button
                            flex={"auto"}
                            gap={"10px"}
                            onClick={() => handleClickButton(item.infor.tab)}
                            bg={activeTabId === item.infor.tab ? "gray.100" : "transparent"}
                            style={
                              activeTabId === item.infor.tab
                                ? { color: "#3182ce" }
                                : { color: "#8892b0" }
                            }
                          >
                            {item.infor.iconMenu}
                            {item.infor.name}
                          </Button>
                        </li>
                      ))}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem
                  w={"100%"}
                >
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_ADVERTISER'}>
                    <VTcontent
                      key={TabFeatureAdvertiser.AdvertiserInformation}
                      index={TabFeatureAdvertiser.AdvertiserInformation}
                      activeTabId={activeTabId}
                    >
                      <Detail
                        advertiserDetail={advertiserDetail}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ASSIGN_MANAGER_FOR_ADVERTISER'}>
                    <VTcontent
                      key={TabFeatureAdvertiser.ChooseManagement}
                      index={TabFeatureAdvertiser.ChooseManagement}
                      activeTabId={activeTabId}
                    >
                      <Box w={"100%"}>
                        <AssignManager
                          advertiserDetail={advertiserDetail}
                          refetchData={refetchData}
                          onClose={handleCloseModal}
                        />
                      </Box>
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'LOCK_ADVERTISER'}>
                    <VTcontent
                      key={TabFeatureAdvertiser.LockManagement}
                      index={TabFeatureAdvertiser.LockManagement}
                      activeTabId={activeTabId}
                    >
                      <BlockAdvertiser
                        advertiserDetail={advertiserDetail}
                        refetchData={refetchData}
                        onClose={handleCloseModal}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_PASSWORD_ADVERTISER'}>
                    <VTcontent
                      key={TabFeatureAdvertiser.ChangePassword}
                      index={TabFeatureAdvertiser.ChangePassword}
                      activeTabId={activeTabId}
                    >
                      <ChangePassword
                        advertiserDetail={advertiserDetail}
                        onClose={handleCloseModal}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_INFORMATION_ADVERTISER'}>
                    <VTcontent
                      key={TabFeatureAdvertiser.UpdateInformation}
                      index={TabFeatureAdvertiser.UpdateInformation}
                      activeTabId={activeTabId}
                    >
                      <UpdateInformation
                        publisherDetail={advertiserDetail}
                        onClose={handleCloseModal}
                        refetch={refetchData}
                      />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AdvertiserInformation;
