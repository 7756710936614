import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Button,
  Flex,
  useToast,
  Box,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import moment from 'moment';
import { API_ROUTES, MessageApi, ModalType } from 'utils/constant';
import { useForm } from 'react-hook-form';
import { changePasswordFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import InputController from 'components/Form/InputController';

import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { axiosPost } from 'utils/api';

function ChangePassword({ publisherDetail, onClose }) {
  const { handleSubmit, control, formState: { isLoading } } = useForm({
    resolver: yupResolver(changePasswordFormValidate()),
    defaultValues: {
      newPassword: '',
      passwordConf: '',
    },
  });
  const [show, setShow] = useState({
    password: false,
    passwordConf: false
  });
  const toast = useToast();

  const onSubmit = async values => {
    const payload = {
      newPassword: values?.newPassword,
      passwordConf: values?.passwordConf,
    }
    try {
      let response;
      response = await axiosPost(API_ROUTES.ChangePassword(publisherDetail._id), payload);
      if (response.data.code === 0) {
        toast({
          title: 'Thay đổi mật khẩu thành công.',
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Thay đổi mật khẩu thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box w={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)} className="border border-[#ddd] rounded w-full bg-[#fff] ">
        <div className='w-full'>
          <InputController
            name="newPassword"
            label="Mật khẩu"
            id={'newpasswordInput'}
            type={show.password ? 'text' : 'password'}
            control={control}
            styleContainer={{ mt: 4 }}
            inputRightElement={
              <Button
                padding={"12px"}
                background={"transparent"}
                onClick={() =>
                  setShow({
                    ...show,
                    password: !show.password,
                  })
                }
              >
                {show.password ? <FaRegEyeSlash color="#98A7B5" size={32} /> : <FaRegEye color="#98A7B5" size={32} />}
              </Button>
            }
          />
          <InputController
            name="passwordConf"
            label="Nhập lại mật khẩu"
            id={'fullnameInput'}
            type={show.passwordConf ? 'text' : 'password'}
            control={control}
            styleContainer={{ mt: 4 }}
            inputRightElement={
              <Button
                padding={"12px"}
                background={"transparent"}
                onClick={() =>
                  setShow({
                    ...show,
                    passwordConf: !show.passwordConf,
                  })
                }
              >
                {show.passwordConf ? <FaRegEyeSlash color="#98A7B5" size={32} /> : <FaRegEye color="#98A7B5" size={32} />}
              </Button>
            }
          />
        </div>
        <Flex justifyContent={"end"} mt={"4"}>
          {/* <Button
                mr={"4"}
                ref={cancelRef}
                onClick={() => {
                  onClose?.(ModalType.ChangePassword);
                }}
              >
                Hủy
              </Button> */}
          <Button colorScheme="blue" type="submit" isLoading={isLoading} className="bg-[#c58560] text-white rounded-lg px-6 py-2 text-base">
            Cập nhật
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
export default ChangePassword;
