import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES, MessageApi, initialFilter } from 'utils/constant';
import { RoleOption } from 'utils/constant';
import { ModalType } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
import Row from './components/Row';
import Header from './components/Header';

const ListReferal = () => {
  const toast = useToast();
  const params = useParams();
  const { id } = params || {};
  const [referals, setReferals] = useState([]);
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(initialFilter);
  const { location } = useHistory();
  const { pathname } = location;
  const _pathname = pathname.split('/');
  const type = _pathname[_pathname.length - 1] || 'advertiser';

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.getListReferralById(id, type),
      params: { ...filter },
    },
    { manual: true }
  );

  useEffect(() => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        setReferals(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handleRefetchData = () => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        setReferals(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };


  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onFilter = () => {
    handleRefetchData();
  };


  return (
    <Fragment>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {/* Advertiser */}
              {`${type === 'advertiser' ? 'Nhà quảng cáo: ' + (data?.advertiser || '-') : 'Nhà xuất bản: ' + (data?.publisher || '-')}`}
            </Text>
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {/* Advertiser */}
              Danh sách giới thiệu
            </Text>
            <Flex pt="22px" flexWrap="wrap">
              <InputSearch value={filter?.searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
              <Button variant="primary" maxH="40px" m="10px" fontSize="12px" onClick={onFilter}>
                Tìm kiếm
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Box width={'100%'} overflowX={isEmpty(referals) && !loading ? 'unset' : 'auto'} className="custom-scrollbar-track">
              <Table variant="simple" color={textColor}>
                <Header />
                <Tbody>
                  {referals?.map((row, index, arr) => {
                    return <Row key={row._id} data={row} isLast={index === arr.length - 1 ? true : false} />;
                  })}
                  {isEmpty(referals) && !loading && (
                    <Tr>
                      <Td colSpan="7">
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign="center" height="200px">
                          Không có dữ liệu
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
            {!isEmpty(referals) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    refetch({
                      params: { pageSize: pageLength, pageIndex: page - 1 },
                    }).then(res => {
                      setReferals(res?.data?.data);
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1,
                      });
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ListReferal;
