import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { API_ROUTES, ManagerStatus, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import SelectController from 'components/Form/SelectController';
import { AssignManagerValidate } from 'utils/validation';
import { mappingOptionSelect } from 'utils/mapping';

const defaultValues = {
  userID: undefined,
};

const AssignManager = ({ advertiserDetail, refetchData, onClose }) => {
  const toast = useToast();
  const [managerOption, setManagerOption] = useState([]);

  const [{ data: managerData }] = useAxios({
    url: API_ROUTES.Users,
    params: { pageSize: 10000, pageIndex: 0 },
  });

  const [{ loading }, assignManagerApi] = useAxios(
    {
      url: API_ROUTES.AssignManagerForAdvertiser,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AssignManagerValidate),
    defaultValues,
  });

  useEffect(() => {
    if (!isEmpty(advertiserDetail)) {
      reset({
        userID: managerOption?.find(item => item?.value === advertiserDetail?.user),
      });
    }
  }, [advertiserDetail, managerOption]);

  useEffect(() => {
    if (!isEmpty(managerData?.data)) {
      const managerActive = managerData?.data?.filter(item => item?.status === ManagerStatus.Normal || !item?.status);

      setManagerOption(mappingOptionSelect(managerActive, 'username', '_id'));
    }
  }, [managerData?.data]);

  const handleSuccess = () => {
    toast({
      title: 'Chọn nhà quản lý thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Chọn nhà quản lý thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    assignManagerApi({
      data: {
        userID: values?.userID?.value,
        advertiserID: advertiserDetail?._id,
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box>
      <form>
        <SelectController
          control={control}
          name="userID"
          label="Nhà quản lý"
          isRequired
          options={managerOption}
          styleContainer={{ pb: 4 }}
        />
      </form>
      <Flex justifyContent={"end"}>
        <Button colorScheme="blue" ml={3} isLoading={loading} onClick={handleSubmit(onSubmit)}>
          Xác nhận
        </Button>
      </Flex>
    </Box>
  );
};

export default AssignManager;
