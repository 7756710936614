import React from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { BiDetail } from 'react-icons/bi';
import { CiEdit } from 'react-icons/ci';
import { Td, Text, Tr, useColorModeValue, useToast, FormControl, Switch, IconButton, Flex, useDisclosure } from '@chakra-ui/react';
import { axiosPost } from 'utils/api';
import { API_ROUTES } from 'utils/constant';
import Detail from './DetailPost';
import { useUserState } from 'context/UserContext';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';

function Row({ day, title, type, isLast, refetch, status, owner, id, handleGetPostDetail }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useUserState();

  const showAlert = message => {
    toast({
      title: message,
      duration: 9000,
      isClosable: true,
    });
  };

  const handleApiCall = async (apiRoute, postData, successMessage) => {
    try {
      const response = await axiosPost(apiRoute, postData);
      if (response.data.code === 0) {
        showAlert(successMessage);
        refetch();
      }
    } catch (error) {
      showAlert(error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg || 'Operation failed!');
    }
  };

  const updateStatus = async () => {
    const confirmStatus = window.confirm('Bạn có chắc chắn muốn thay đổi trạng thái?');
    if (confirmStatus) {
      const postData = { postId: id };
      await handleApiCall(API_ROUTES.ChangeStatusPost, postData, 'Thay đổi trạng thái thành công.');
    }
  };

  const deletePost = async () => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa?');
    if (confirmDelete) {
      const postData = { postId: id };
      await handleApiCall(API_ROUTES.DeletePost, postData, 'Xóa bài viết thành công.');
    }
  };

  const handleEditPost = () => {
    handleGetPostDetail(id);
  };

  return (
    <>
      <Tr>
        {[title, owner, type].map((item, index) => (
          <Td key={index} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {item}
            </Text>
          </Td>
        ))}
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <FormControl pl="6px">
            <Switch id="" isChecked={status} onChange={updateStatus} />
          </FormControl>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Flex>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_POST'}>
            <IconButton p={2} bg="transparent" onClick={handleEditPost}>
              <CiEdit />
            </IconButton>
          </AuthorizationComponentWrapper>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_POST'}>
            <IconButton
              p={2}
              bg="transparent"
              onClick={onOpen}
              color={undefined}
            >
              <BiDetail />
            </IconButton>
          </AuthorizationComponentWrapper>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_POST'}>
            <IconButton
                p={2}
                bg="transparent"
                onClick={deletePost}
                color={'red.400'}
              >
              <DeleteIcon />
            </IconButton>
          </AuthorizationComponentWrapper>
          </Flex>
        </Td>
      </Tr>
      {isOpen && <Detail id={id} name={title} refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />}
    </>
  );
}

export default Row;
