import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Box,
  Flex,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { API_ROUTES, ManagerStatus, ManagerStatusLabel, ManagerStatusOption, MessageApi } from 'utils/constant';
import { ModalType } from 'utils/constant';
import DatePickerController from 'components/Form/DatePickerController';
import SelectController from 'components/Form/SelectController';
import { BlockManagerValidate } from 'utils/validation';

const defaultValues = {
  status: { label: ManagerStatusLabel.Normal, value: ManagerStatus.Normal },
};

const BlockUserModal = ({ userDetail, onClose, refetchData }) => {
  const toast = useToast();
  const [{ loading }, blockManagerApi] = useAxios(
    {
      url: API_ROUTES.BlockUser,
      method: 'post',
    },
    { manual: true }
  );

  const { control, handleSubmit, watch, reset } = useForm({
    resolver: yupResolver(BlockManagerValidate),
    defaultValues,
  });
  const watchStatus = watch('status');

  useEffect(() => {
    if (!isEmpty(userDetail)) {
      reset({
        status: ManagerStatusOption.find(item => item?.value === userDetail?.status) || {
          label: ManagerStatusLabel.Normal,
          value: ManagerStatus.Normal,
        },
        ...(userDetail?.dateUnlock && { date: moment(userDetail.dateUnlock).toDate() }),
      });
    }
  }, [userDetail]);

  const handleSuccess = () => {
    toast({
      title: 'Khóa tài khoản quản trị thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Trình quản lý khối thất bại',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    blockManagerApi({
      data: {
        userID: userDetail?.id,
        status: values?.status?.value,
        ...(values?.date && { date: moment(values?.date).startOf('day').toISOString() }),
      },
    })
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <Box w={"100%"} p={"2"}>
      <form>
        <SelectController
          control={control}
          name="status"
          label="Trạng thái"
          isRequired
          options={ManagerStatusOption}
          styleContainer={{ pb: 4 }}
        />
        {watchStatus?.value === ManagerStatus.Temporary && (
          <DatePickerController
            isRequired
            control={control}
            name="date"
            label="Lock time"
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
          />
        )}
        <Flex justifyContent={"end"}>
          <Button colorScheme="blue" isLoading={loading} onClick={handleSubmit(onSubmit)}>
            Khóa
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default BlockUserModal;
