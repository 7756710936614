import { Td, Text, Tr, useColorModeValue, useToast, FormControl, IconButton, useDisclosure, Select, Flex } from '@chakra-ui/react';
import { DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import React, { useState } from 'react';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi } from 'utils/constant';
import WebsiteInfo from './InfoWebSite';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

function Row(props) {
  const { adUnit, creator, day, removeWebsites, updatedAt, name, url, isLast, refetch, status, id, row, categoryData } = props;
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [valueStatus, setValueStatus] = useState(status);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useUserState();

  const handleStatus = event => {
    const selectedStatus = event.target.value;
    const confirmStatus = window.confirm(`Bạn có chắc chắn muốn thay đổi trạng thái thành ${selectedStatus} ?`);
    if (!confirmStatus) {
      return;
    }
    setValueStatus(selectedStatus);
    updateStatus(selectedStatus);
  };

  const updateStatus = async selectedStatus => {
    const websiteData = {
      websiteId: id,
      status: selectedStatus,
    };
    try {
      const response = await axiosPost(API_ROUTES.UpdateStatusWebSite, websiteData);
      if (response.data.code === 0) {
        toast({
          title: `Thay đổi trạng thái thành công.`,
          duration: 9000,
          status: 'success',
        });
        refetch();
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Thay đổi trạng thái thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onDeleteWebsite= async () => {
    const confirmDelete = window.confirm(`Bạn có chắc chắn muốn xóa website ${name}?`);
    if (!confirmDelete) {
      return;
    }
    const websiteId = {
      websiteId: id,
    };
    try {
      const response = await axiosPost(API_ROUTES.DeleteWebsite, websiteId);
      if (response.data.code === 0) {
        toast({
          title: 'Xóa website thành công.',
          duration: 9000,
        });
        refetch();
      }
    } catch (error) {
      toast({
        title:
          MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Xóa website thất bại.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} isTruncated>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} maxW={400} whiteSpace="normal">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={1}>
            {url}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} isTruncated>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {creator?.username}
          </Text>
        </Td>
        <Td maxW="300px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {adUnit.join('\n')}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null} isTruncated>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {categoryData?.name}
          </Text>
        </Td>
        <Td w="200px" borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <FormControl display="flex" columnGap="10px" pl="6px">
            <Select placeholder="PENDING" value={valueStatus} onChange={handleStatus}>
              <option value="APPROVED">APPROVED</option>
              <option value="REJECTED">REJECTED</option>
            </Select>
          </FormControl>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Flex>
            <IconButton p={2} bg="transparent" onClick={onOpen}>
              <InfoIcon />
            </IconButton>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'DELETE_WEBSITE'}>
              <IconButton p={2} bg="transparent" onClick={onDeleteWebsite}>
                <DeleteIcon />
              </IconButton>
            </AuthorizationComponentWrapper>
          </Flex>
        </Td>
      </Tr>
      {isOpen && (
        <WebsiteInfo
          creator={creator}
          adUnit={adUnit}
          row={row}
          url={url}
          name={name}
          status={status}
          id={id}
          refetch={refetch}
          isOpen={isOpen}
          isApprove={status}
          day={day}
          updatedAt={updatedAt}
          removeWebsites={removeWebsites}
          onOpen={onOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}

export default Row;
