import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react';

const RadioController = ({
  name,
  label,
  control,
  styleContainer,
  styleBoxInput,
  isRequired,
  disabled,
  minWLabel = '150px',
  options,
  onChange,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <FormControl {...styleContainer} isRequired={isRequired} isInvalid={error && error?.message}>
        {label && (
          <FormLabel minW={minWLabel} htmlFor={name}>
            {label}
          </FormLabel>
        )}
        <Box {...styleBoxInput}>
          <RadioGroup value={field.value} onChange={field.onChange} disabled={disabled}>
            <Stack direction="row">
              {options?.map((item, index) => (
                <Radio key={index} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{error && error?.message}</FormErrorMessage>
        </Box>
      </FormControl>
    )}
  />
);

export default RadioController;
