import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box,
} from '@chakra-ui/react';
import { Roles, TabFeatureManagement } from 'utils/constant';
import { defaultPassword } from 'utils/constant';
import { ModalType } from 'utils/constant';

import { ImProfile } from 'react-icons/im';
import { TbLock } from 'react-icons/tb';
import { FiEdit } from 'react-icons/fi';
import { GrUserAdmin } from 'react-icons/gr';

import VTcontent from 'components/VerticalTab/VerticalContent';
import BlockUserModal from './BlockUserModal';
import DetailInfoModal from './DetailModal';
import EditUser from './EditUser';
import AssignPermission from './AssignPermission';
import AuthorizationComponentWrapper, { authorizationOption } from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeatureManagement.DetailInformation,
        name: TabFeatureManagement.DetailInformation,
        iconMenu: <ImProfile />,
      },
    },
    {
      infor: {
        tab: TabFeatureManagement.EditInformation,
        name: TabFeatureManagement.EditInformation,
        iconMenu: <FiEdit />,
      },
    },
    {
      infor: {
        tab: TabFeatureManagement.BLock,
        name: TabFeatureManagement.BLock,
        iconMenu: <TbLock />,
      },
    },
    {
      infor: {
        tab: TabFeatureManagement.AssignPermission,
        name: TabFeatureManagement.AssignPermission,
        iconMenu: <GrUserAdmin />,
      },
    },
  ],
};

const UserInformationModal = ({ isOpen, userDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const [activeTabId, setActiveTabId] = useState(TabFeatureManagement.DetailInformation);
  const { userInfo } = useUserState();

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const handleCloseModal = () => onClose?.(ModalType.Detail);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
        size="full"
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Tài khoản quản trị</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Wrap w={'100%'}>
              <Grid w={'100%'} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w="100%">
                  <Flex w={'100%'} bg={'white'} borderRadius={4}>
                    <Flex gap={'10px'} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                      {sideMenu.data.map((item, index) => {
                        if (item.infor.name === TabFeatureManagement.AssignPermission && userDetail?.role === Roles.ADMIN) return null;
                        return (
                          <li key={index} style={{ listStyle: 'none', textAlign: 'left' }}>
                            <Button
                              flex={'auto'}
                              gap={'10px'}
                              onClick={() => handleClickButton(item.infor.tab)}
                              bg={activeTabId === item.infor.tab ? 'gray.100' : 'transparent'}
                              style={activeTabId === item.infor.tab ? { color: '#3182ce' } : { color: '#8892b0' }}
                            >
                              {item.infor.iconMenu}
                              {item.infor.name}
                            </Button>
                          </li>
                        );
                      })}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem
                  w={'100%'}
                  // minHeight={"400px"}
                  // overflowY={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "hidden" : ""}
                  // overflowX={activeTabId === TabFeatureManagement.DetailInformation && userDetail?.groups?.length > 0 ? "scroll" : ""}
                  // className='custom-scrollbar-track'
                >
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_ADMIN'}>
                    <VTcontent
                      key={TabFeatureManagement.DetailInformation}
                      index={TabFeatureManagement.DetailInformation}
                      activeTabId={activeTabId}
                    >
                      <DetailInfoModal infor={userDetail} />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'UPDATE_INFORMATION_ADMIN'}>
                    <VTcontent
                      key={TabFeatureManagement.EditInformation}
                      index={TabFeatureManagement.EditInformation}
                      activeTabId={activeTabId}
                    >
                      <Box w={'100%'}>
                        <EditUser userDetail={userDetail} onClose={handleCloseModal} refetchData={refetchData} />
                      </Box>
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'LOCK_ADMIN'}>
                    <VTcontent key={TabFeatureManagement.BLock} index={TabFeatureManagement.BLock} activeTabId={activeTabId}>
                      <BlockUserModal onClose={handleCloseModal} userDetail={userDetail} refetchData={refetchData} />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ASSIGN_PERMISSION_ADMIN'}>
                    <VTcontent
                      key={TabFeatureManagement.AssignPermission}
                      index={TabFeatureManagement.AssignPermission}
                      activeTabId={activeTabId}
                    >
                      <AssignPermission onClose={handleCloseModal} userDetail={userDetail} refetchData={refetchData} />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UserInformationModal;
