import {
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialog,
  AlertDialogFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { axiosPost } from 'utils/api';
import useAxios from 'axios-hooks';
import { mappingOptionSelect } from 'utils/mapping';
import { AddPageValidate } from 'utils/validation';

const AddPage = ({ isOpen, onClose, pageEdit, refetch, setPageEdit }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [postOption, setPostOption] = useState([]);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(AddPageValidate),
    defaultValues: {
      name: '',
      postId: undefined,
    },
  });

  const [{ data }] = useAxios({
    url: API_ROUTES.Posts,
  });

  const onSubmit = async values => {
    const payload = {
      ...values,
      postId: values?.postId?.value,
    };
    try {
      let response;
      if (!isEmpty(pageEdit)) {
        response = await axiosPost(API_ROUTES.Sites + '/update-page', {
          ...payload,
          pageId: pageEdit?.id,
        });
      } else {
        response = await axiosPost(API_ROUTES.Sites + '/create-page', payload);
      }

      if (response.data.code === 0) {
        toast({
          title: MessageApi?.[response.data.msg],
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Thao tác thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setPostOption(mappingOptionSelect(data?.data, 'title'));
  }, [data]);

  useEffect(() => {
    reset({
      name: pageEdit?.name || '',
      postId:
        {
          label: pageEdit?.title,
          value: pageEdit?.postId,
        } || null,
    });
  }, [pageEdit]);

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setPageEdit(null);
        onClose();
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent minWidth={'60%'} marginY={4}>
        <AlertDialogHeader>{!isEmpty(pageEdit) ? 'Cập nhật Page' : 'Thêm Page'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody width={'100%'}>
          <form>
            <InputController styleContainer={{ pb: 4 }} control={control} name="name" label="Tên" isRequired />
            <SelectController
              control={control}
              name="postId"
              label="Bài viết"
              placeholder="Chọn"
              isRequired
              options={postOption}
              styleContainer={{ pb: 4, zIndex: 10 }}
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={() => {
              setPageEdit(null);
              onClose();
            }}
          >
            Quay lại
          </Button>
          <Button
            ml={3}
            ref={cancelRef}
            onClick={() => {
              reset({
                name: '',
                postId: null,
              });
            }}
          >
            Cài lại
          </Button>
          <Button colorScheme="blue" ml={3} onClick={handleSubmit(onSubmit)}>
            Lưu lại
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AddPage;
