import { Box, Button, Flex, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import InputSearch from 'components/InputSearch/InputSearch';
import { useEffect, useMemo, useState } from 'react';
import { API_ROUTES, MessageApi, ModalType, initialFilter } from 'utils/constant';
import useAxios from 'axios-hooks';
import CardBody from 'components/Card/CardBody';
import TableTicketCategory from './components/Table';
import { isEmpty } from 'lodash';
import Pagination from 'components/Pagination/Pagination';
import FormRequestModal from './components/FormRequestModal';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const InitFilter = { ...initialFilter, searchKeyword: '' };
const TicketCatogory = () => {
  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  const [filter, setFilter] = useState(InitFilter);
  // const [ticketCategories, setTicketCategories] = useState([]);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [typeAction, setTypeAction] = useState(null);
  const {userInfo} = useUserState();
  
  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.CreateTicketCategory]: onOpenCreateModal,
    }),
    [onOpenCreateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.CreateTicketCategory]: onCloseCreateModal,
    }),
    [onCloseCreateModal]
  );
  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.getTicketCategories,
      params: { ...InitFilter },
    }
    // { manual: true }
  );
  const { data: dataTicketCategories, pagination } = data || {};

  useEffect(() => {
    handleRefetchData(filter);
  }, []);

  const handleRefetchData = params => {
    refetch({
      params: { ...params },
    })
      .then(res => {
        // setTicketCategories(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = params => {
    handleRefetchData(params);
  };

  const onChangeSearch = event => {
    event.persist();

    setFilter(prev => ({
      ...prev,
      searchKeyword: event?.target?.value,
      pageIndex: 0,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  const onReset = () => {
    const params = {
      ...initialFilter,
      searchKeyword: '',
    };
    setFilter(params);
    refetch();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
  };

  const handleOpentModal = (modalType, values) => {
    setTicketCategory(values);
    setTypeAction(modalType);
    openModal?.[modalType]?.();
  };

  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="0px 0px 22px 0px">
          <Flex justifyContent={'space-between'} alignItems="center" flexWrap="wrap" gap={'12px'}>
            <Flex flexDirection="row">
              <Flex flexDirection="column" w={'300px'}>
                <Text fontSize="sm" color={textColor} fontWeight="bold" mb={'2'}>
                  Tìm kiếm
                </Text>
                <InputSearch
                  value={filter?.searchKeyword}
                  name="searchKeyword"
                  placeholder="Tìm kiếm tên danh mục"
                  onChange={onChangeSearch}
                  onClearSearch={onClearSearch}
                />
              </Flex>

              <Button maxH="40px" fontSize="12px" onClick={onReset} alignSelf={'end'} ml={'10px'}>
                Đặt lại
              </Button>
              <Button variant="primary" maxH="40px" margin={'0 16px'} fontSize="12px" onClick={() => onFilter(filter)} alignSelf={'end'}>
                Lọc
              </Button>
            </Flex>
          <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_TICKET_CATEGORY'}>
            <Button
              variant="primary"
              maxH="40px"
              fontSize="12px"
              onClick={() => handleOpentModal(ModalType.CreateTicketCategory)}
              alignSelf={'end'}
            >
              Thêm
            </Button>
          </AuthorizationComponentWrapper>
          </Flex>
        </CardHeader>
        <CardBody>
          <TableTicketCategory
            data={dataTicketCategories}
            loading={loading}
            refetch={() => handleRefetchData(initialFilter)}
            handleOpentModal={handleOpentModal}
          />
          {!isEmpty(dataTicketCategories) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
      {isCreateModalOpen && (
        <FormRequestModal
          isOpen={isCreateModalOpen}
          onClose={handelCloseModal}
          refetch={() => handleRefetchData(initialFilter)}
          type={typeAction}
          ticketCategory={ticketCategory}
        />
      )}
    </>
  );
};

export default TicketCatogory;
