import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  FormControl,
  Select,
  Td,
  Box,
  useDisclosure,
  FormLabel,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { TablePagination } from '@trendmicro/react-paginations';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Row from './components/Row';
import Loading from 'components/Layout/Loading';
import { ListAdsUnit, ModalType, initialFilter } from 'utils/constant';
import { API_ROUTES } from 'utils/constant';
import InputSearch from 'components/InputSearch/InputSearch';
import AssignCampaignModal from './components/AssignCampaignModal';
import DatePicker from 'components/DatePicker/DatePicker';

const InitFilter = { ...initialFilter, searchKeyword: '', publisherId: '', adUnit: '', status: '', searchPublisher: '' };

function AdsUnit() {
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [adsUnitDetail, setAdsUnitDetail] = useState();
  const [filter, setFilter] = useState(InitFilter);
  const { isOpen: isOpenAssignModal, onOpen: onOpenAssignModal, onClose: onCloseAssignModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Assign]: onOpenAssignModal,
    }),
    [onOpenAssignModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Assign]: onCloseAssignModal,
    }),
    [onCloseAssignModal]
  );

  const [{ data: adsUnitData, loading: loadingAds }, refetchAds] = useAxios(
    {
      url: API_ROUTES.AdsUnit,
      params: InitFilter,
    },
    {
      // manual: true,
    }
  );
  // const [{ data: websiteCategory }] = useAxios(
  //   {
  //     url: API_ROUTES.ListWebSite,
  //   },
  //   {
  //     useCache: false,
  //   }
  // );
  // const [{ data: publisher }] = useAxios(
  //   {
  //     url: API_ROUTES.Publisher,
  //   },
  //   {
  //     useCache: false,
  //   }
  // );

  useEffect(() => {
    refetchAds({ params: { ...filter } });
  }, []);

  const handleSearch = () => {
    refetchAds({ params: { ...filter, pageIndex: 0 } });
  };

  const resetSearchKeywords = () => {
    refetchAds();
    setFilter(InitFilter);
  };

  const onChangeFilter = event => {
    event.persist();
    const name = event?.target?.name;

    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: event?.target?.value,
    }));
  };

  const handelUpdateAdsUnit = (adsUnitDetail, modalType) => {
    setAdsUnitDetail(adsUnitDetail);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setAdsUnitDetail(null);
  };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            {/* <Text pb="28px" fontSize="xl" color={textColor} fontWeight="bold">
              Đơn vị quảng cáo
            </Text> */}
            <FormControl rowGap="16px" display="flex" flexWrap="wrap" alignItems="center" gap={4} width={'100%'}>
              <Flex flexDirection="column">
                <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                  Tìm kiếm
                </Text>
                <InputSearch
                  width={{ base: 'full', sm: '300px' }} maxW="300px"
                  placeholder="Tìm kiếm đơn vị quảng cáo, website"
                  name="searchKeyword"
                  value={filter?.searchKeyword}
                  mr="0"
                  onChange={onChangeFilter}
                  onClearSearch={() =>
                    setFilter({
                      ...filter,
                      searchKeyword: '',
                    })
                  }
                />
              </Flex>
              <Flex flexDirection="column">
                <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                  Chọn đơn vị quảng cáo
                </Text>
                <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn đơn vị quảng cáo" name="adUnit" value={filter.adUnit} onChange={onChangeFilter}>
                  {ListAdsUnit?.map((ads, index) => (
                    <option key={index} value={ads}>
                      {ads}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex flexDirection="column">
                <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                  Tìm kiếm người tạo
                </Text>
                <InputSearch
                  width="300px"
                  placeholder="Tìm kiếm người tạo"
                  name="searchPublisher"
                  value={filter?.searchPublisher}
                  mr="0"
                  onChange={onChangeFilter}
                  onClearSearch={() =>
                    setFilter({
                      ...filter,
                      searchPublisher: '',
                    })
                  }
                />
              </Flex>
              <Flex flexDirection="column">
                <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                  Chọn trạng thái
                </Text>
                <Select width={{ base: 'full', sm: '300px' }} maxW="300px" placeholder="Chọn trạng thái" name="status" value={filter.status} onChange={onChangeFilter}>
                  <option value="PENDING">PENDING</option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="REJECTED">REJECTED</option>
                  <option value="WAITING">WAITING</option>
                </Select>
              </Flex>
              <Flex flexWrap="wrap" alignItems={'end'} marginTop={4} gap={'20px'}>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                  <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                    Từ ngày
                  </FormLabel>
                  <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
                </FormControl>
                <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                  <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                    Đến ngày
                  </FormLabel>
                  <DatePicker selectedDate={filter.endDate} minDate={filter.startDate} onChange={date => onChangeDate('endDate')(date)} />
                </FormControl>
                <Button w="100px" maxH="40px" fontSize="12px" onClick={resetSearchKeywords}>
                  Đăt lại
                </Button>
                <Button w="100px" variant="primary" maxH="40px" fontSize="12px" onClick={handleSearch}>
                  Lọc
                </Button>
              </Flex>
            </FormControl>
          </CardHeader>
          <CardBody w="100%" overflowX="auto">
            {loadingAds ? (
              <Flex justifyContent="center" alignItems="center" height="200px">
                <Loading />
              </Flex>
            ) : (
              <Box overflowX={isEmpty(adsUnitData?.data) && !loadingAds ? 'unset' : 'auto'} className="custom-scrollbar-track">
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th borderColor={borderColor} color="gray.400">
                        {/* Ads Unit */}
                        Đơn vị quảng cáo
                      </Th>
                      <Th pl="30px" borderColor={borderColor} color="gray.400">
                        Website
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Người tạo
                      </Th>
                      <Th pl="64px" borderColor={borderColor} color="gray.400">
                        Trạng thái
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Ngày tạo
                      </Th>
                      <Th borderColor={borderColor} color="gray.400">
                        Thao tác
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {adsUnitData?.data?.map((row, index) => (
                      <Row
                        key={index}
                        adUnit={row.adUnit}
                        website={row?.websiteId?.name}
                        creator={row.publisherId}
                        id={row?._id}
                        name={row.name}
                        status={row.status}
                        day={moment.utc(row.createdAt).format('YYYY-MM-DD')}
                        refetch={refetchAds}
                        adsUnitDetail={row}
                        handelUpdateAdsUnit={handelUpdateAdsUnit}
                      />
                    ))}
                    {isEmpty(adsUnitData?.data) && !loadingAds && (
                      <Tr>
                        <Td colSpan="8">
                          <Box textAlign="center" height="200px" pt="24px">
                            Không có dữ liệu
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
                {!isEmpty(adsUnitData?.data) && (
                  <Flex justifyContent="flex-end">
                    <TablePagination
                      type="full"
                      page={adsUnitData?.pagination?.page}
                      pageLength={adsUnitData?.pagination?.pageSize}
                      totalRecords={adsUnitData?.pagination?.count}
                      onPageChange={({ page, pageLength }) => {
                        refetchAds({ params: { ...filter, pageSize: pageLength, pageIndex: page - 1 } }).then(res => {
                          setFilter({
                            ...filter,
                            pageSize: pageLength,
                            pageIndex: page - 1,
                          });
                        });
                      }}
                      prevPageRenderer={() => <i className="fa fa-angle-left" />}
                      nextPageRenderer={() => <i className="fa fa-angle-right" />}
                    />
                  </Flex>
                )}
              </Box>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isOpenAssignModal && (
        <AssignCampaignModal isOpen={isOpenAssignModal} adsUnitDetail={adsUnitDetail} onClose={handelCloseModal} refetchData={refetchAds} />
      )}
    </>
  );
}

export default AdsUnit;
