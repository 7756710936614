import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { FaLock } from 'react-icons/fa';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { useUserState } from 'context/UserContext';
import { API_ROUTES, MessageApi } from 'utils/constant';
import { ErrorMessage } from 'utils/constant';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangePasswordFormValidateProfile } from 'utils/validation';
import InputController from 'components/Form/InputController';

const changePassApi = process.env.REACT_APP_API_HOST + process.env.REACT_APP_CHANGE_PASS_USER;
const defaultValue = {
  password: '',
  newPassword: '',
  confirmPass: '',
};
const defaultValueShow = {
  password: false,
  newPassword: false,
  confirmPass: false,
};
function Profile() {
  const { colorMode } = useColorMode();
  const [changePass, setChangePass] = useState(defaultValue);
  const [show, setShow] = useState(defaultValueShow);
  const textColor = useColorModeValue('gray.700', 'white');
  const bgProfile = useColorModeValue('hsla(0,0%,100%,.8)', 'navy.800');
  const borderProfileColor = useColorModeValue('white', 'transparent');
  const emailColor = useColorModeValue('gray.400', 'gray.300');
  const { isAuthenticated, userInfo } = useUserState();
  const toast = useToast();

  const [{ loading }, changePasswordApi] = useAxios(
    {
      url: API_ROUTES.ChangePassUser,
      method: 'post',
    },
    { manual: true }
  );
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(ChangePasswordFormValidateProfile),
    defaultValues: {
      password: '',
      newPassword: '',
      passwordConf: '',
    },
  });

  const onSubmit = values => {
    changePassword(values);
  };

  const changePassword = values => {
    changePasswordApi({ data: values })
      .then(res => {
        toast({
          title: 'Đổi mật khẩu thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        console.log(res);
        setChangePass(defaultValue);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Đổi mật khẩu thất bại',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      {!!isAuthenticated ? (
        <>
          <Flex
            direction={{ sm: 'column', md: 'row' }}
            mb="24px"
            maxH="330px"
            justifyContent={{ sm: 'center', md: 'space-between' }}
            align="center"
            backdropFilter="blur(21px)"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            border="1.5px solid"
            borderColor={borderProfileColor}
            bg={bgProfile}
            p="24px"
            borderRadius="20px"
          >
            <Flex
              align="center"
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}
            >
              <Avatar me={{ md: '22px' }} w="80px" h="80px" borderRadius="15px" />
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text fontSize={{ sm: 'lg', lg: 'xl' }} color={textColor} fontWeight="bold" ms={{ sm: '8px', md: '0px' }}>
                  {userInfo.username}
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color={emailColor} fontWeight="semibold">
                  {userInfo.email}
                </Text>
              </Flex>
            </Flex>
            <Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}></Flex>
          </Flex>
          <Card p="16px" my="24px">
            <CardHeader p="12px 5px" mb="12px">
              <Flex direction="column">
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  Đổi mật khẩu
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Flex direction="column">
                  <FormControl mb={3}>
                    <InputGroup size="md" position="relative">
                      <InputController
                        control={control}
                        hasIconPassword
                        type={show.password ? 'text' : 'password'}
                        name="password"
                        label="Mật khẩu cũ"
                        isRequired
                        styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                        styleBoxInput={{ flex: 1, width: '100%' }}
                        styleLabel={{
                          fontWeight: 'bold',
                        }}
                      />
                      <InputRightElement width="4.5rem" position="absolute" top="32px" right="0px">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              password: !show.password,
                            })
                          }
                        >
                          {show.password ? 'Ẩn' : 'Hiện'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <InputGroup size="md" position="relative">
                      <InputController
                        styleLabel={{
                          fontWeight: 'bold',
                        }}
                        control={control}
                        hasIconPassword
                        type={show.password ? 'text' : 'password'}
                        name="newPassword"
                        label="Mật khẩu mới"
                        isRequired
                        styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                        styleBoxInput={{ flex: 1, width: '100%' }}
                      />
                      <InputRightElement width="4.5rem" position="absolute" top="32px" right="0px">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              password: !show.password,
                            })
                          }
                        >
                          {show.password ? 'Ẩn' : 'Hiện'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Box mb={'15px'} mt={1}>
                      <Text color="gray.500">Tối thiểu 8 kí tự.</Text>
                      <Text color="blue.500">Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt</Text>
                    </Box>
                    <InputGroup size="md" position="relative">
                      <InputController
                        styleLabel={{
                          fontWeight: 'bold',
                        }}
                        control={control}
                        hasIconPassword
                        type={show.password ? 'text' : 'password'}
                        name="passwordConf"
                        label="Xác nhận mật khẩu mới"
                        isRequired
                        styleContainer={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '15px' }}
                        styleBoxInput={{ flex: 1, width: '100%' }}
                      />
                      <InputRightElement width="4.5rem" position="absolute" top="32px" right="0px">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() =>
                            setShow({
                              ...show,
                              password: !show.password,
                            })
                          }
                        >
                          {show.password ? 'Ẩn' : 'Hiện'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Box mb={'15px'} mt={1}>
                      <Text color="gray.500">Trùng với mật khẩu mới.</Text>
                      <Text color="gray.500">Tối thiểu 8 kí tự.</Text>
                      <Text color="blue.500">Chỉ bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt</Text>
                    </Box>
                  </FormControl>
                </Flex>
              </form>
              <Button type="submit" p="0px" bg="transparent" variant="no-effects" onClick={handleSubmit(onSubmit)}>
                <Flex
                  align="center"
                  w={{ sm: '100%', lg: '135px' }}
                  bg={colorMode === 'dark' ? 'navy.900' : 'blue.500'}
                  borderRadius="8px"
                  justifyContent="center"
                  py="10px"
                  boxShadow="2px 2px 5.5px rgba(0, 0, 0, 0.06)"
                  cursor="pointer"
                >
                  <Text fontSize="sm" color={'white'} fontWeight="bold">
                    Xác nhận
                  </Text>
                </Flex>
              </Button>
            </CardBody>
          </Card>
        </>
      ) : (
        ''
      )}
    </Flex>
  );
}

export default Profile;
