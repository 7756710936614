import { BankLogo, BankStatus } from "./constant";

export const mappingCustomers = data =>
  data?.map(item => ({
    name: item?.name,
    email: item?.email,
    code: item?.code,
    id: item?._id,
  }));

export const mappingOptionSelect = (data, labelKey = 'name', valueKey = '_id') =>
  data?.map(item => ({
    label: item?.[labelKey],
    value: item?.[valueKey],
  }));

export const mappingChannel = data =>
  data?.map(item => ({
    name: item?.name,
    manager: item?.manager,
    customers: mappingOptionSelect(item?.customers),
    id: item?._id,
    isActive: item?.isActive,
  }));

export const mappingChannelFormData = data => ({
  ...(data?.id && { id: data?.id }),
  name: data?.name,
  customerIds: data?.customers?.map(customerItem => customerItem?.value).join(','),
});

export const mappingCampaign = data =>
  data?.map(item => ({
    id: item?._id,
    name: item?.name,
  }));

export const mappingCampaignFormData = data => ({
  ...(data?.id && { id: data?.id }),
  name: data?.name,
  channelIds: data?.channels?.map(item => item?.value).join(','),
});

export const mappingUsers = data =>
  data?.map(item => ({
    username: item?.username,
    role: item?.role,
    permissions: item?.permissions,
    email: item?.email,
    id: item?._id,
    groups: item?.groups,
    status: item?.status,
    dateUnlock: item?.dateUnlock,
    createdAt: item?.createdAt,
    updatedAt: item?.updatedAt,
  }));

  export const mappingBank = data =>
    data
      .map(item => {
        if (item.status === BankStatus.Available) {
          return {
            ...item,
            logo: BankLogo[item.bankCode],
          };
        }
        return undefined;
      })
      ?.filter(item => !!item);

export const mappingTemplate = data => data?.map(item => ({ id: item?._id, name: item?.name, content: item?.content }));
