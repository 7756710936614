import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Button,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { API_ROUTES, MessageApi, ModalType, TypeTicket } from 'utils/constant';
import isEmpty from 'lodash/isEmpty';
import SelectController from 'components/Form/SelectController';
import InputController from 'components/Form/InputController';
import { useForm } from 'react-hook-form';
import { TicketCategoryFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosPost } from 'utils/api';

function FormRequestModal({ isOpen, onClose, refetch, type, ticketCategory }) {
  const cancelRef = React.useRef();
  const toast = useToast();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(TicketCategoryFormValidate),
    defaultValues: {
      name: ticketCategory?.name || '',
    },
  });

  const onSubmit = async (values) => {
    let payload = {
      name: values.name
    };
    let _router = API_ROUTES.createTicketCategory;
    if (!isEmpty(ticketCategory)) {
      payload.categoryId = ticketCategory._id;
      _router = API_ROUTES.updateTicketCategory;
    }
    try {
      let response;
      response = await axiosPost(_router, { ...payload });
      if (response.data.code === 0) {
        toast({
          title: `${isEmpty(ticketCategory) ? 'Tạo' : 'Sửa'} thông tin danh mục ticket thành công.`,
          duration: 9000,
          status: 'info',
          isClosable: true,
        });
        onClose?.(ModalType.CreateTicketCategory);
        refetch();
      } else {
        toast({
          title: MessageApi?.[response.data.msg],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.errors[0]?.msg || error?.response?.data?.msg] || 'Tạo thông tin ticket thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => {
        onClose?.(type);
      }}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent maxW="500px" maxH="600px">
        <AlertDialogHeader>{`${isEmpty(ticketCategory) ? 'Tạo' : 'Chỉnh sửa'} Danh mục ticket`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <InputController
              styleContainer={{ pt: "4" }}
              control={control}
              name="name"
              label="Danh mục ticket"
              isRequired
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={() => onClose?.(type)}>
            Hủy
          </Button>
          <Button colorScheme="red" ml={3} onClick={handleSubmit(onSubmit)}>
            {isEmpty(ticketCategory) ? 'Tạo' : 'Chỉnh sửa'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export default FormRequestModal;
