import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Wrap,
  Grid,
  GridItem,
  Flex,
  Box,
} from '@chakra-ui/react';
import { TabFeatureTicket } from 'utils/constant';
import { defaultPassword } from 'utils/constant';
import { ModalType } from 'utils/constant';

import { ImProfile } from 'react-icons/im';
import { TbLock } from 'react-icons/tb';
import { FiEdit } from 'react-icons/fi';

import VTcontent from 'components/VerticalTab/VerticalContent';
import DetailInfoModal from './DetailInfoModal';
import StatusTicketForm from './StatusTicketForm';
import AssignTicketForm from './AssignTicketForm';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';

const sideMenu = {
  data: [
    {
      infor: {
        tab: TabFeatureTicket.TicketInformation,
        name: TabFeatureTicket.TicketInformation,
        iconMenu: <ImProfile />,
      },
    },
    {
      infor: {
        tab: TabFeatureTicket.ChangeStatus,
        name: TabFeatureTicket.ChangeStatus,
        iconMenu: <FiEdit />,
      },
    },
    {
      infor: {
        tab: TabFeatureTicket.ChooseManagement,
        name: TabFeatureTicket.ChooseManagement,
        iconMenu: <TbLock />,
      },
    },
  ],
};

const TicketInformation = ({ isOpen, ticketDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const [activeTabId, setActiveTabId] = useState(TabFeatureTicket.TicketInformation);
  const {userInfo} = useUserState();

  function handleClickButton(tab) {
    setActiveTabId(tab);
  }

  const handleCloseModal = () => onClose?.(ModalType.Detail);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => handleCloseModal()}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent maxWidth={'750px'}>
          <AlertDialogHeader textTransform="uppercase">Quản lý ticket</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody mb={'16px'} minHeight={'400px'}>
            <Wrap w={'100%'}>
              <Grid w={'100%'} gridTemplateColumns={'1fr'} gap={6}>
                <GridItem w="100%">
                  <Flex w={'100%'} bg={'white'} borderRadius={4}>
                    <Flex gap={'10px'} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                      {sideMenu.data.map((item, index) => (
                        <li key={index} style={{ listStyle: 'none', textAlign: 'left' }}>
                          <Button
                            flex={'auto'}
                            gap={'10px'}
                            onClick={() => handleClickButton(item.infor.tab)}
                            bg={activeTabId === item.infor.tab ? 'gray.100' : 'transparent'}
                            style={activeTabId === item.infor.tab ? { color: '#3182ce' } : { color: '#8892b0' }}
                          >
                            {item.infor.iconMenu}
                            {item.infor.name}
                          </Button>
                        </li>
                      ))}
                    </Flex>
                  </Flex>
                </GridItem>
                <GridItem w={'100%'}>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_TICKET'}>
                    <VTcontent
                      key={TabFeatureTicket.TicketInformation}
                      index={TabFeatureTicket.TicketInformation}
                      activeTabId={activeTabId}
                    >
                      <DetailInfoModal ticketDetail={ticketDetail} />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CHANGE_STATUS_TICKET'}>
                    <VTcontent key={TabFeatureTicket.ChangeStatus} index={TabFeatureTicket.ChangeStatus} activeTabId={activeTabId}>
                      <Box w={'100%'}>
                        <StatusTicketForm ticketDetail={ticketDetail} onClose={handleCloseModal} refetch={refetchData} />
                      </Box>
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ASSIGN_MANAGER_FOR_TICKET'}>
                    <VTcontent key={TabFeatureTicket.ChooseManagement} index={TabFeatureTicket.ChooseManagement} activeTabId={activeTabId}>
                      <AssignTicketForm onClose={handleCloseModal} ticketDetail={ticketDetail} refetch={refetchData} />
                    </VTcontent>
                  </AuthorizationComponentWrapper>
                </GridItem>
              </Grid>
            </Wrap>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default TicketInformation;
