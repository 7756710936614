import React, { useMemo, useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Stack, Switch, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { Select } from 'chakra-react-select';
// import Pagination from 'components/Pagination/Pagination';
// import TicketTable from './components/Table';
// import { isEmpty } from 'lodash';
import DatePicker from 'components/DatePicker/DatePicker';
import InputSearch from 'components/InputSearch/InputSearch';
import { API_ROUTES, ModalType, StatusTicketOptions, StatusWithDrawOptions } from 'utils/constant';
import useAxios from 'axios-hooks';
import Pagination from 'components/Pagination/Pagination';
import { isEmpty } from 'lodash';
import { formatDate } from 'utils/helpers';
import WithDrawTable from './components/Table';
import ChangeStatus from './components/ChangeStatusModal';
// import { useQueryGetMyHistoryTransactions } from 'services/transaction';

export const initialFilter = {
  pageSize: 10,
  pageIndex: 0,
};

function WithDraw() {
    const { isOpen: isChangeStatusModalOpen, onOpen: onOpenChangeStatusModal, onClose: onCloseChangeStatusModal } = useDisclosure();
    const openModal = useMemo(
        () => ({
          [ModalType.ChangeStatus]: onOpenChangeStatusModal,
        }),
        [onOpenChangeStatusModal]
      );
      const closeModal = useMemo(
        () => ({
          [ModalType.ChangeStatus]: onCloseChangeStatusModal,
        }),
        [onCloseChangeStatusModal]
      );
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [transaction, setTransaction] = useState(null);
  const [status, setStatus] = useState(null);
  const [valueSearch, setValueSearch] = useState(null);
  const [filterDate, setFilterDate] = useState({ start: null, end: null });

  const [{ data }, refetch] = useAxios({
    url: API_ROUTES.ListWithDraw,
    params: filter,
  });
  const { data: historyData, pagination } = data || {};
  const onFilter = () => {
    setFilter({
      ...filter,
      start: filterDate.start ? formatDate(filterDate.start, 'YYYY-MM-DD') : undefined,
      end: filterDate.end ? formatDate(filterDate.end, 'YYYY-MM-DD') : undefined,
      status: status?.value,
      searchKeyword: valueSearch ? valueSearch : null,
    });
  };
  const onReset = () => {
    setFilter(initialFilter);
    setValueSearch('');
    setStatus(null);
    setFilterDate({ start: null, end: null });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const onChangeSearch = event => {
    event.persist();
    setValueSearch(event?.target?.value);
  };

  const onClearSearch = () => {
    setValueSearch('');
  };
  const handleChangeStatus = (item, modalType) => {
    openModal?.[modalType]?.();
    setTransaction(item);
  };
  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setTransaction(null);
  };
  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex justifyContent={'start'} alignItems={'end'} gap={'20px'}>
                  <FormControl minWidth={{ base: '200px', sm: '300px' }} maxW="300px">
                    <FormLabel fontWeight={600} fontSize={14}>
                      Tìm kiếm
                    </FormLabel>
                    <InputSearch
                      width={'300px'}
                      placeholder="Tìm kiếm"
                      value={valueSearch}
                      onChange={onChangeSearch}
                      onClearSearch={onClearSearch}
                    />
                  </FormControl>
                  <FormControl minWidth={'230px'} maxW="250px">
                    <FormLabel fontWeight={600} fontSize={14}>
                      Trạng thái
                    </FormLabel>
                    <Select
                      isClearable
                      value={status}
                      onChange={e => {
                        setStatus(e);
                      }}
                      options={StatusWithDrawOptions}
                      placeholder="Chọn"
                    />
                  </FormControl>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'}>
                  <Stack>
                    <Flex flexWrap="wrap" marginTop={4} gap={'20px'}>
                      <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                        <FormLabel fontWeight={600} fontSize={14} m="0">
                          Từ ngày
                        </FormLabel>
                        <DatePicker selectedDate={filterDate.start} onChange={date => onChangeDate('start')(date)} />
                      </FormControl>
                      <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                        <FormLabel fontWeight={600} fontSize={14} m="0">
                          Đến ngày
                        </FormLabel>
                        <DatePicker selectedDate={filterDate.end} minDate={filterDate.start} onChange={date => onChangeDate('end')(date)} />
                      </FormControl>
                      <Button
                        backgroundColor="white"
                        color="blue.400"
                        border="1px"
                        _hover={{
                          text: 'white',
                        }}
                        maxH="60px"
                        mx="0px"
                        fontSize="14px"
                        alignSelf={'end'}
                        px={8}
                        onClick={onReset}
                      >
                        Đặt lại
                      </Button>
                      <Button variant="primary" minW={100} maxW={150} maxH="60px" fontSize="14px" onClick={onFilter} alignSelf={'end'}>
                        Lọc
                      </Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack overflow={'auto'}>
              <WithDrawTable handleChangeStatus={handleChangeStatus} data={historyData || []} refetch={refetch}/>
            </Stack>
            {!isEmpty(historyData) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={pagination?.page}
                  pageLength={pagination?.pageSize}
                  totalRecords={pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isChangeStatusModalOpen && (
        <ChangeStatus isOpen={isChangeStatusModalOpen} onClose={handelCloseModal} refetchData={refetch} transaction={transaction}/>
      )}
    </>
  );
}

export default WithDraw;
