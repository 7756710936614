import {
  AlertDialogOverlay,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialog,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Editor from 'components/Editor/Editor';
import React from 'react';
import { API_ROUTES } from 'utils/constant';
import { formatDate } from 'utils/helpers';

const Detail = ({ isOpen, id, onClose }) => {
  const cancelRef = React.useRef();

  const [{ data }] = useAxios({
    url: API_ROUTES.Posts + `/${id}`,
  });

  const renderPost = (title, type, content, description, username, createdAt) => {
    return `<h1>${title}</h1><h5>${type}</h5><code>Created by ${username}</code>

  <p>${content}</p>
  
  <p>${description}</p>

  <code>Created at ${formatDate(createdAt)}</code>
  `;
  };

  return (
    <>
      <AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent minWidth={'60%'}>
          <AlertDialogHeader>Chi tiết bài đăng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody width={'100%'}>
            <Editor
              initialValue={renderPost(
                data?.data?.title,
                data?.data?.type,
                data?.data?.content,
                data?.data?.description,
                data?.data?.username,
                data?.data?.createdAt
              )}
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Detail;
