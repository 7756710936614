import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

const BoxCard = ({ title, content, actions, ...rest }) => {
  const iconBlue = useColorModeValue('blue.500', 'blue.500');
  const iconBoxInside = useColorModeValue('white', 'white');
  const textColor = useColorModeValue('gray.700', 'white');
  return (
    <Box color={iconBoxInside} bgColor={iconBlue} maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' {...rest}>
      <Box p='6'>
        <Box display='flex' alignItems='baseline'>
         {title}
        </Box>

        <Box
          mt='1'
          fontWeight='semibold'
          as='h4'
          lineHeight='tight'
          noOfLines={1}
        >
          {content}
        </Box>

        <Box display='flex' mt='2' alignItems='center'>
         {actions}
        </Box>
      </Box>
    </Box>
  );
};

export default BoxCard;
