import React, { useState } from 'react';
import { Button, Flex, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import { API_ROUTES } from 'utils/constant';
import SettingCommissionModal from './components/SettingCommissionModal';
import { formatDate } from 'utils/helpers';

function CreateSettingCommission() {
  const [{ data }, refetch] = useAxios({
    url: API_ROUTES.SettingCommission,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageEdit, setPageEdit] = useState();

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px' }}>
      <Card pb="0px">
        <CardHeader p="6px 0px">
          <Text color="black.500" fontSize={24} fontWeight={600}>
            {/* Commission setup information */}
            Thông tin thiết lập hoa hồng
          </Text>
        </CardHeader>
        <CardBody>
          <form>
            <Flex flexDirection={'column'} gap={5} py={6}>
              <Flex flexDirection="column">
                <GridItem w="100%">
                  <Flex justifyContent={'flex-start'}>
                    <Text fontWeight={600} opacity={0.8}>
                      {/* Number of commissions per click: */}
                      Số tiền hoa hồng cho mỗi cú nhấp chuột:
                    </Text>
                    <Text pl={4} color="#6B6B6" opacity={0.9} fontWeight={700}>
                      {data?.data?.commissionPerClick}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem w="100%">
                  <Flex justifyContent={'flex-start'}>
                    <Text fontWeight={600} opacity={0.8}>
                      Tỷ lệ hoa hồng:
                    </Text>
                    <Text pl={4} color="#6B6B6" opacity={0.9} fontWeight={700}>
                      {data?.data?.commissionRate}
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem w="100%">
                  <Flex justifyContent={'flex-start'}>
                    <Text fontWeight={600} opacity={0.8}>
                      {/* Latest updates: */}
                      Cập nhật lần cuối
                    </Text>
                    <Text pl={4} color="#6B6B6" opacity={0.9} fontWeight={700}>
                      {formatDate(data?.data?.updatedAt)}
                    </Text>
                  </Flex>
                </GridItem>
              </Flex>
              <Flex flexDirection={'row'} justifyContent={'flex-end'}>
                <Button colorScheme="blue" ml={3} onClick={() => {
                  onOpen();
                  setPageEdit(data?.data);
                }}>
                  Sửa
                </Button>
              </Flex>
            </Flex>
          </form>
        </CardBody>
      </Card>
      <SettingCommissionModal isOpen={isOpen} onClose={onClose} refetch={refetch} pageEdit={pageEdit} setPageEdit={setPageEdit} />
    </Flex>
  );
}

export default CreateSettingCommission;
