import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  FormControl,
  Text,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { ModalType } from 'utils/constant';

function DetailInfoModal({ ticketDetail }) {

  return (
    <Box>
      <Text pb="12px" fontSize="lg">
        <strong>Loại:</strong> {(ticketDetail?.type?.name)}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Tiêu đề:</strong> {ticketDetail?.subject}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Nội dung:</strong> {ticketDetail?.content}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Trạng thái:</strong> {capitalize(ticketDetail?.status)}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Người tạo:</strong> {ticketDetail?.advertiser?.username || ticketDetail?.publisher?.username}(
        {isEmpty(ticketDetail?.advertiser) ? 'Nhà xuất bản' : 'Nhà quảng cáo'})
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Email:</strong> {ticketDetail?.advertiser?.email || ticketDetail?.publisher?.email}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Ngày tạo:</strong> {moment(ticketDetail?.createdAt).format('DD-MM-YYYY')}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Ngày cập nhập:</strong> {moment(ticketDetail?.updatedAt).format('DD-MM-YYYY')}
      </Text>
      <Text pb="12px" fontSize="lg">
        <strong>Người xử lý:</strong> {ticketDetail?.user?.username}
      </Text>
    </Box>
  );
}
export default DetailInfoModal;
