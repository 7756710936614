import { InfoIcon } from '@chakra-ui/icons';
import { Td, Text, Tr, useColorModeValue, useToast, FormControl, Switch, IconButton, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { axiosPost } from 'utils/api';
import { API_ROUTES, MessageApi } from 'utils/constant';
import Detail from './Detail';
import { authorizationOption } from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
function Row(props) {
  const { day, name, category, isLast, refetch, status, id, createdBy, updatedAt, link } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { userInfo } = useUserState();
  const updateStatus = async () => {
    const confirmStatus = window.confirm('Bạn có chắc chắn thay đổi trạng thái?');
    if (!confirmStatus) {
      return;
    }
    const directLinkId = {
      directLinkId: id,
      };
    try {
      const response = await axiosPost(API_ROUTES.UpdateStatusDirect, directLinkId);
      if (response.data.code === 0) {
        toast({
          title: MessageApi?.[response?.data?.msg] || 'Thay đổi trạng thái liên kết thành công',
          duration: 9000,
        });
        refetch();
      }
    } catch (error) {
      toast({
        title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Thay đổi trạng thái liên kết thất bại',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleAuthor = (item) => {
    const isAuthor = authorizationOption(item, false, userInfo?.permissions, 'CHANGE_STATUS_DIRECT_LINK')
    return isAuthor === null
  };
  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name} - {createdBy?.username}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {category?.name}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {createdBy?.username}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
          <FormControl pl="6px">
            <Switch id="" isChecked={status} onChange={updateStatus} readOnly={handleAuthor(status)}/>
          </FormControl>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {day}
          </Text>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <Text fontSize="md" color={textColor} w="154px" fontWeight="bold">
            {updatedAt}
          </Text>
        </Td>
        <Td borderColor={borderColor} pl="0" pr="0" borderBottom={isLast ? 'none' : null}>
          <IconButton p={2} bg="transparent" onClick={onOpen}>
            <InfoIcon />
          </IconButton>
        </Td>
      </Tr>
      {isOpen && <Detail isOpen={isOpen} onOpen={onOpen} onClose={onClose} link={link} />}
    </>
  );
}

export default Row;
