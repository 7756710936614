import { Box, Button, Flex, FormControl, FormLabel, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import InputSearch from 'components/InputSearch/InputSearch';
import { useEffect, useState } from 'react';
import { API_ROUTES, MessageApi, initialFilter } from 'utils/constant';
import TableCampaignGroups from './components/Table';
import DetailModal from './components/DetailModal';
import DatePicker from 'components/DatePicker/DatePicker';
import { isEmpty } from 'lodash';
import Pagination from 'components/Pagination/Pagination';
import { axiosPost } from 'utils/api';
import FormModal from './components/DetailModal/FormModal';
const InitFilter = {
  ...initialFilter,
  searchKeyword: '',
  searchAdvertiser: '',
};
const CampaignsGroup = () => {
  const toast = useToast();
  const textColor = useColorModeValue('gray.700', 'white');
  // const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [filter, setFilter] = useState(InitFilter);
  // const [campaignGroups, setCampaignGroups] = useState([]);
  const [campaignGroupDetail, setCampaignGroupetail] = useState(null);
  const { isOpen: isOpenDetailModal, onOpen: onCloseDetailModal, onClose: onCloseUpdateStatusModal } = useDisclosure();
  const { isOpen: isOpenFormUpdateModal, onOpen: onOpenFormUpdateModal, onClose: onCloseFormUpdateModal } = useDisclosure();

  const handelDetail = value => {
    setCampaignGroupetail(value);
    onCloseDetailModal();
  };

  const handelUpdate = value => {
    setCampaignGroupetail(value);
    onOpenFormUpdateModal();
  };

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.getCampaignGroups,
      params: { ...InitFilter },
    }
    // { manual: true }
  );
  const { data: dataCampaignGroups, pagination } = data || {};

  const handleSuccess = () => {
    toast({
      title: 'Xóa nhóm chiến dịch thành công',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetch?.();
  };

  const handleError = error => {
    toast({
      title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg] || 'Xóa nhóm chiến dịch không thành công',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const deleteCampaignGroup = async campaignGroupDetail => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa nhóm chiến dịch này?');
    if (!confirmDelete) {
      return;
    }
    try {
      const response = await axiosPost(API_ROUTES.deleteCampaignGroup, { groupID: campaignGroupDetail?._id });
      if (response.data.code === 0) {
        handleSuccess();
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    refetch({
      params: { ...filter },
    })
      .then(res => {
        // setCampaignGroups(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }, []);

  const handleRefetchData = () => {
    refetch({
      params: { ...filter, pageIndex: 0 },
    })
      .then(res => {
        // setCampaignGroups(res?.data?.data);
      })
      .catch(error => {
        toast({
          title: MessageApi?.[error?.response?.data?.errors?.[0]?.msg] || error?.response?.data?.msg || 'Lấy danh sách không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const onFilter = () => {
    handleRefetchData();
  };

  const onReset = () => {
    setFilter(InitFilter);
    refetch();
  };

  const onChangeDate = type => date => {
    setFilter(prev => ({
      ...prev,
      ...(type === 'startDate' && { endDate: null }),
      [type]: date,
    }));
  };

  const onChangeSearch = event => {
    event.persist();
    const { name } = event?.target;
    setFilter(prev => ({
      ...prev,
      [name]: event?.target?.value,
    }));
  };

  const onClearSearch = () => {
    setFilter(prev => ({
      ...prev,
      searchKeyword: '',
    }));
  };

  return (
    <>
      <Card mt={{ base: '120px', md: '75px' }} overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          {/* <Text fontSize="xl" color={textColor} fontWeight="bold">
            Nhóm chiến dịch
          </Text> */}
          <Flex alignItems="end" flexWrap="wrap" gap={'12px'}>
            <Flex flexDirection="column" w={'300px'}>
              <Text fontSize="sm" color={textColor} fontWeight="bold" mb={'2'}>
                Tìm kiếm
              </Text>
              <InputSearch
                value={filter?.searchKeyword}
                placeholder="Tìm kiếm nhóm chiến dịch"
                onChange={onChangeSearch}
                onClearSearch={onClearSearch}
              />
            </Flex>
            <Flex flexDirection="column">
              <Text mt="2px" mb="10px" fontSize="sm" color={textColor} fontWeight="bold">
                Tìm kiếm người tạo
              </Text>
              <InputSearch
                width="300px"
                placeholder="Tìm kiếm người tạo"
                name="searchAdvertiser"
                value={filter?.searchAdvertiser}
                mr="0"
                onChange={onChangeSearch}
                onClearSearch={() =>
                  setFilter({
                    ...filter,
                    searchAdvertiser: '',
                  })
                }
              />
            </Flex>
            <Flex pt="22px" alignItems="center" flexWrap="wrap" gap={'12px'}>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Từ ngày
                </FormLabel>
                <DatePicker selectedDate={filter.startDate} onChange={date => onChangeDate('startDate')(date)} />
              </FormControl>
              <FormControl display="flex" flexDirection={'column'} width={{ base: 'full', sm: '300px' }} maxW="300px">
                <FormLabel m="0" fontSize="sm" color={textColor} fontWeight="bold">
                  Đến ngày
                </FormLabel>
                <DatePicker selectedDate={filter.endDate} minDate={filter.startDate} onChange={date => onChangeDate('endDate')(date)} />
              </FormControl>
              <Button maxH="40px" fontSize="12px" onClick={onReset} alignSelf={'end'}>
                Đặt lại
              </Button>
              <Button variant="primary" maxH="40px" fontSize="12px" onClick={onFilter} alignSelf={'end'}>
                Lọc
              </Button>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <TableCampaignGroups
            data={dataCampaignGroups}
            loading={loading}
            handelDetail={handelDetail}
            handelUpdate={handelUpdate}
            deleteCampaignGroup={deleteCampaignGroup}
          />
          {!isEmpty(dataCampaignGroups) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={pagination?.page}
                pageLength={pagination?.pageSize}
                totalRecords={pagination?.count}
                onPageChange={(page, pageLength) => {
                  const params = {
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  };
                  refetchData({
                    params: { ...params },
                  }).then(res => {
                    // setAdvertiser(res?.data?.data);
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  });
                  // setFilter(params);
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
      {isOpenDetailModal && campaignGroupDetail && (
        <DetailModal
          isOpen={isOpenDetailModal}
          onClose={() => {
            onCloseDetailModal();
            setCampaignGroupetail(null);
          }}
          campaignGroupDetail={campaignGroupDetail}
        />
      )}
       {isOpenFormUpdateModal && campaignGroupDetail && (
        <FormModal
          isOpen={isOpenFormUpdateModal}
          onClose={() => {
            onCloseFormUpdateModal();
            setCampaignGroupetail(null);
          }}
          refetch={refetch}
          userDetail={campaignGroupDetail}
        />
      )}
    </>
  );
};

export default CampaignsGroup;
